import { ProgramWeek } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface ProgramWeekDetailsPayload {
    id?: string;
    programId ?: string;
    weekId ?: string;
}

export type ProgramWeekListPayload = SearchPaginationQuery & ProgramWeekDetailsPayload;

export const details = (payload: ProgramWeekDetailsPayload) => api.get(`/programs/${payload.programId}/weeks/${payload.weekId}`);

export const list = (payload: ProgramWeekListPayload = {}) => {
    const programId = payload.programId;
    delete payload.programId;

    return api.get(`/programs/${programId}/weeks`, {params: cleanPayload(payload)});
};

export const create = (payload: ProgramWeek) => {
    return api.post(`/programs/${payload.programId}/weeks`, payload);
};

export const update = (payload: ProgramWeek) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/programs/weeks/${id}`, payload);
};

export const remove = (payload: ProgramWeek) => {
    return api.delete(`/programs/weeks/${payload.id}`);
};
