import { Program } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';

export interface ProgramDetailsPayload {
    id: string;
}

export interface ProgramUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type ProgramListPayload = SearchPaginationQuery;

export const details = (payload: ProgramDetailsPayload) => api.get(`/programs/${payload.id}`);

export const list = (payload: ProgramListPayload = {}) => {
    return api.get(`/programs`, {params: cleanPayload(payload)});
};

export const create = (payload: Program) => {
    return api.post(`/programs`, payload);
};

export const update = (payload: Program) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/programs/${id}`, payload);
};

export const remove = (payload: Program) => {
    return api.delete(`/programs/${payload.id}`);
};
