import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
        <path fill="currentColor" d="M7.80755278,7.16065509 C9.15607097,7.16065509 10.3579146,9.33921749 11.1253331,11.6384271 L11.2830258,12.1322116 L11.2830258,12.1322116 L11.4267905,12.6251895 C11.4495556,12.7070353 11.4717142,12.78866 11.4932532,12.8699698 L11.6149463,13.3533008 C11.690978,13.6720027 11.7566757,13.9826673 11.8112006,14.2792947 L11.8845034,14.712958 C11.9492169,15.1345936 11.9878476,15.5178959 11.9975643,15.8426157 L12,16 L0,16 C0,15.6368723 0.0417554903,15.1857075 0.120470726,14.6808034 L0.19688044,14.2356678 C0.239499127,14.0073196 0.288654666,13.7709009 0.34396955,13.5291114 L0.462699926,13.0391713 L0.462699926,13.0391713 L0.597184098,12.5397089 C1.30787091,10.0265908 2.5841579,7.33637537 4.04853749,7.16889768 L4.19244722,7.16065509 L7.80755278,7.16065509 Z M12.2202793,2.77613726 C12.2202793,1.78268157 13.6575286,1.74294334 13.7725085,2.65692258 L13.7797946,2.77613726 L13.7791045,4.22 L15.2238627,4.22020544 C16.1788629,4.22020544 16.2525512,5.54840262 15.4449276,5.75337132 L15.3345874,5.77353497 L15.2238627,5.77979456 L13.7791045,5.77910448 L13.7797946,7.22386274 C13.7797946,8.17886291 12.4516603,8.25255119 12.2467013,7.44492759 L12.2265386,7.3345874 L12.2202793,7.22386274 L12.22,5.77910448 L10.7761373,5.77979456 C9.82113709,5.77979456 9.74744881,4.45159738 10.5550724,4.24662868 L10.6654126,4.22646503 L10.7761373,4.22020544 L12.22,4.22 L12.2202793,2.77613726 Z M5.99988106,0 C7.47556346,0 8.67606304,1.21159266 8.67606304,2.70091094 C8.67606304,4.19022923 7.47556346,5.40182189 5.99988106,5.40182189 C4.52419865,5.40182189 3.32369908,4.19022923 3.32369908,2.70091094 C3.32369908,1.21159266 4.52419865,0 5.99988106,0 Z" />
    </svg>
);

const IconUserAdd: FC<IconComponentProps> = ({ ...props }) => (
    // @ts-ignore
    <Icon
        component={svg}
        className={`icon-user-add`}
        {...props}
    />
);

export default IconUserAdd;
