// Types of the backend API

import { RcFile } from 'antd/lib/upload';

type ObjectId = string;

export interface Pack {
    id: ObjectId;
    organization: Organization;
    name: string;
    description?: string;
    enabled?: boolean;
    dates: any;
    period: {
        fromDate: Date;
        toDate: Date;
    };
    maxLicenses: number;
    licensesCount: number;
    programs: Program[];
}

export interface UserGroup {
    id: ObjectId;
    name: string;
    licensePacks?: Pack[];
}

export interface Program {
    id: ObjectId;
    name: string;
    pack: Pack;
    description?: Translation | string;
    organization?: ObjectId | Organization;
    weeks?: ProgramWeek[];
    type: ProgramType;
    code?: string;
    language: SupportedLanguage;
    enabled ?: boolean;
    saveAsTemplate?: boolean;
    fromTemplate: ProgramTask['id'];
    isTemplate?: boolean;
    maxSuspension?: number;
    shouldDisplayJobOffers: boolean;
}

export type LibraryProgram = Program;

export interface ProgramTask {
    id: ObjectId;
    index: number;
    code?: string;
    name: Translation | string;
    description?: Translation | string;
    points: number;
    family?: ProgramTaskFamily;
    program?: Program;
    programId?: Program['id'];
    language: SupportedLanguage;
    subTasks: ProgramSubTask[];
    weeksDuration?: number;
    badge?: BadgeLevel;
    saveAsTemplate?: boolean;
    fromTemplate: ProgramTask['id'];
    isTemplate?: boolean;
    createdAt?: string;
    updatedAt?: string;
}
export enum DrawerActionType {
    EDIT ,
    DUPLICATE,
    ADD,
}

export type LibraryProgramTask = ProgramTask;

export interface ProgramWeek {
    id: ObjectId;
    index: number;
    points?: number;
    program?: Program;
    tasks: ProgramTask[];
    programId?: Program['id'];
}

export interface Organization {
    id: ObjectId;
    name: string;
    slug?: string;
    enabled?: boolean;
    group?: string;
    companyName?: string;
    serialNumber?: string;
    VATSerialNumber?: string;
    companySize?: CompanySize;
    businessType?: BusinessType;
    address?: Address;
    billingAddress?: Address;
    logo: string;
    banner: string;
    theme: OrganizationTheme;
}

export interface OrganizationTheme {
    primary: string;
    secondary: string;
    background: string;
    bannerUrl: string;
    logoUrl: string;
}

export interface Address {
    id?: ObjectId;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export interface User {
    id: ObjectId;
    firstName: string;
    lastName: string;
    email: string;
    organization?: Organization | string;
    isActive: boolean;
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    phone?: string;
    spokenLanguages?: string;
    degrees?: string;
    maritalStatus?: string;
    civility?: string;
    birthday?: Date;
    company?: Company;
    job?: Job;
    address: Address;
    role: Role;
    roleSlug: RoleSlug;
    isMain: boolean;
    comment?: string;
}

export interface Company {
    name?: string;
    group?: string;
    department?: string;
    service?: string;
}

export interface Job {
    status?: string;
    group?: string;
    occupation?: string;
    jobTitle?: string;
    position?: string;
    annualSalaryWithoutTax?: string;
    entryOnDutyDate?: string;
    manager?: string;
    hrRepresentative?: string;
}

export interface WebsitePage {
    id: ObjectId;
    linkText: string;
    slug: string;
    title: Translation | string;
    content: Translation | string;
}

export interface WebsiteFaq {
    id: ObjectId;
    question: string;
    answer: string;
}

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
}

export const LanguagesList: SupportedLanguage[] = [
    SupportedLanguage.fr,
    SupportedLanguage.en,
];

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface Role {
    id: string;
    name: string;
    slug: RoleSlug;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScopes]?: boolean;
    };
}

export interface QuestionSection {
    id: ObjectId;
    title: Translation | string;
    description?: Translation | string;
    questionCount?: number;
    questions: Question[];
}

export interface Question {
    id: ObjectId;
    section?: QuestionSection;
    title: Translation | string;
    description?: Translation | string;
    answer: Translation | string;
}

export interface RemoteFile {
    id: ObjectId;
    url: string;
    createdAt: Date;
    filename: string;
    originalName?: string;
    thumbnailUrl?: string;
}

export interface LibraryDocument {
    id: ObjectId;
    url: string;
    name: Translation | string;
    description?: Translation | string;
    language: SupportedLanguage;
    document: RcFile;
    file?: ObjectId | RemoteFile;
    enabled?: boolean;
    code?: string;
    type?: LibraryDocumentType;
    family?: ProgramTaskFamily;
    organization?: ObjectId | Organization;
    program?: ObjectId | Program;
    user?: ObjectId | User;
}

export interface ProgramSubTask {
    id?: ObjectId;
    type?: SubTaskType;
    subType?: SubTaskSubType;
    documentSubType?: DocumentSubTaskType;
    surveySubType?: SurveySubTaskType;
    tricamWithPartialResults?: boolean;
    language?: SupportedLanguage;
    link?: string;
    description?: Translation | string;
    points?: number;
    surveys?: ProgramTaskSurvey[];
    validation?: ProgramTaskValidation;
    libraryDocument?: ObjectId | LibraryDocument;
    formIndex: number;
    index?: number;
    isRequired?: boolean;
    title?: Translation | string;
}

export interface MessageTemplate {
    id: ObjectId;
    slug: string;
    text: Translation;
    variables?: string[];
}

export interface MessageTriggerScope {
    isProfileCompleted?: boolean;
}

export interface MessageTriggerEvent {
    slug: string;
    count?: number;
    scheduled?: number;
    notSince?: number;
}

export interface MessageTrigger {
    id: ObjectId;
    slug: string;
    event: MessageTriggerEvent;
    name: Translation;
    channels: NotificationChannel[];
    template?: MessageTemplate;
    isEnabled: boolean;
    scope?: MessageTriggerScope;
    meta?: MessageTriggerMeta;
}

export interface MessageTriggerMeta {
    isIncludedByDefault?: boolean;
}

export enum NotificationChannel {
    pull = 'pull',
    mobilePush = 'mobilePush',
    webhook = 'webhook',
}

export interface NotificationCampaign {
    name: Translation;
    scheduled: string;
    channel: NotificationChannel[];
    template: {
        slug: string;
        title: Translation;
        text: Translation;
        context?: {};
    };
    isEnabled?: boolean;
    filters: {
        [key: string]: string | number | boolean;
    };
    context?: {};
    meta?: {};
}

export enum DocumentSubTaskType {
    toRead = 'toRead',
    toDo = 'toDo',
}

export enum SurveySubTaskType {
    basic = 'basic',
    validation = 'validation',
    tricam = 'tricam',
    colors = 'colors',
    intelligence = 'intelligence',
}

export enum ProgramValidationType {
    manual = 'manual',
    automatic = 'automatic',
}

export interface Answer {
    label: Translation | string;
    values?: string[];
}

export interface ProgramTaskSurvey {
    title?: Translation | string;
    description?: Translation | string;
    questions: ProgramTaskSurveyQuestion[];
}

export interface ProgramTaskSurveyQuestion {
    label: Translation | string;
    type: SurveyAnswerType;
    answers: Answer[];
}

export enum SurveyAnswerType {
    text = 'text',
    list = 'list',
    boolean = 'boolean',
    multiValue = 'multiValue',
}

export enum SurveyAnswerIntelligence {
    verbal = 'verbal',
    mathematical = 'mathematical',
    musical = 'musical',
    visual = 'visual',
    physical = 'physical',
    interpersonal = 'interpersonal',
    intrapersonal = 'intrapersonal',
    naturalist = 'naturalist',
}

export enum SurveyAnswerTricamLetters {
    t = 't',
    r = 'r',
    i = 'i',
    c = 'c',
    a = 'a',
    m = 'm',
}

export enum SurveyAnswerColors {
    red = 'red',
    green = 'green',
    blue = 'blue',
    yellow = 'yellow',
}

export enum SubTaskType {
    text = 'text',
    document = 'document',
    link = 'link',
    action = 'action',
    upload = 'upload',
    survey = 'survey',
}

export enum SubTaskSubType {
    networking = 'networking',
    contact = 'contact',
    hiring = 'hiring',
}

export interface ProgramTaskValidation {
    type: ProgramValidationType;
    requiredCount: number;
    fields: {
        [field: string]: {
            required?: boolean;
            value?: string;
        };
    };
}

export enum BadgeLevel {
    // lvl1 = 'lvl1',
    lvl2 = 'lvl2',
    lvl3 = 'lvl3',
}

export enum BadgeType {
    cv = 'cv',
    linkedin = 'linkedin',
    professionalProject = 'professionalProject',
}

export enum PermissionRight {
    Read        = 'r',
    Write       = 'w',
    Disabled    = 'disabled',
}

export enum Permission {
    SuperAdmin = 'superAdmin',
    Admin = 'admin',
    Users = 'users',
}

export enum RoleScopes {
    User = 'user',
}

export enum RoleName {
    SuperAdmin = 'superAdmin',
    Admin = 'admin',
    Owner = 'owner',
    User = 'user',
}

export enum RoleSlug {
    superAdmin = 'superAdmin',
    owner = 'owner',
    admin = 'admin',
    user = 'user',
    manager = 'manager',
    headHR = 'headHR',
}

export enum ProgramType {
    basic = 'basic',
    extension = 'extension',
}

export enum DndType {
    week = 'week',
    task = 'task',
    section = 'section',
    question = 'question',
    subtask = 'subtask',
}

export enum ProgramTaskFamily {
    cv = 'cv',
    linkedin = 'linkedin',
    professionalProject = 'professionalProject',
    offer = 'offer',
    networking = 'networking',
    communication = 'communication',
    hiring = 'hiring',
    privateLife = 'privateLife',
    retirement = 'retirement',
    businessCreation = 'businessCreation',
    other = 'other',
}

export enum LibraryDocumentType {
    file = 'file',
    podcast = 'podcast',
    video = 'video',
}

export enum BusinessType {
    agricultureForestryFishing = 'agricultureForestryFishing',
    extractiveIndustries = 'extractiveIndustries',
    manufacturingIndustry = 'manufacturingIndustry',
    productionDistributionElectricityGas = 'productionDistributionElectricityGas',
    waterProductionDistribution = 'waterProductionDistribution',
    construction = 'construction',
    tradeRepairAutomobilesMotorcycles = 'tradeRepairAutomobilesMotorcycles',
    transportationWarehousing = 'transportationWarehousing',
    accommodationCatering = 'accommodationCatering',
    informationCommunication = 'informationCommunication',
    financialInsuranceActivities = 'financialInsuranceActivities',
    realEstateActivities = 'realEstateActivities',
    specializedScientificTechnicalActivities = 'specializedScientificTechnicalActivities',
    administrativeSupportServiceActivities = 'administrativeSupportServiceActivities',
    publicAdministration = 'publicAdministration',
    education = 'education',
    humanHealthSocialAction = 'humanHealthSocialAction',
    artsEntertainmentRecreationalActivities = 'artsEntertainmentRecreationalActivities',
    otherServiceActivities = 'otherServiceActivities',
    activitiesHouseholdsAsEmployers = 'activitiesHouseholdsAsEmployers',
    extraTerritorialActivities = 'extraTerritorialActivities',
}

export enum CompanySize {
    lessThan10 = 'lessThan10',
    from10To249 = 'from10To249',
    from250To999 = 'from250To999',
    from1000To5000 = 'from1000To5000',
    moreThan5000 = 'moreThan5000',
}

export enum NetworkingActionType {
    informationQuery = 'informationQuery',
    feedbackQuery = 'feedbackQuery',
    adviseQuery = 'adviseQuery',
    jobInterview = 'jobInterview',
    reminder = 'reminder',
}

export enum ContactType {
    internal = 'internal',
    external = 'external',
    manager = 'manager',
    hr = 'hr',
    networkAngel = 'networkAngel',
    headHunter = 'headHunter',
}
