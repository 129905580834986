import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';

import {
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/programWeeks';

import { RequestState, MainReducerState } from '../reducers';
import { ProgramWeek } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { DataAction } from '../helpers/EzeeAction';
import {
    details as programDetails,
} from './programs';

// State

export interface ProgramWeeksState {
    create: RequestState<ProgramWeek | undefined>;
    update: RequestState<ProgramWeek | undefined>;
    remove: RequestState<ProgramWeek | undefined>;
}

const initialState: ProgramWeeksState = {
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
        payload: undefined,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
        payload: undefined,
    },
};

// Actions/Reducers

export const create = new EzeeAsyncAction<
    ProgramWeeksState['create'],
    ProgramWeek,
    ProgramWeek
>('programWeeks/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    ProgramWeeksState['update'],
    ProgramWeek,
    ProgramWeek
>('programWeeks/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    ProgramWeeksState['remove'],
    ProgramWeek,
    ProgramWeek
>('programWeeks/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const programWeeksReducer = combineReducers<ProgramWeeksState>({
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
});

// Saga

export function* programWeeksSaga() {
    yield takeLatest(create.type.trigger, createSaga);
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, removeSaga);
}

function* createSaga(actionData: DataAction<any>) {
    try {
        const response = yield call(createApiCall, actionData.payload);
        yield put(programDetails.actions.addWeek(response));
        return yield put(create.actions.success(response));
    } catch (error) {
        yield put(create.actions.failure(error));
    }
}

function* removeSaga(actionData: DataAction<any>) {
    try {
        const response = yield call(removeApiCall, actionData.payload);
        yield put(programDetails.actions.removeWeek(actionData.payload));
        return yield put(remove.actions.success(response));
    } catch (error) {
        yield put(remove.actions.failure(error));
    }
}

// Store helpers

export const getProgramWeeksState = (state: MainReducerState) => state.programWeeks;
