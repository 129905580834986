import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Pack, Organization } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import {
    PacksState,
    list as packsList,
    remove as packsRemove,
} from '../../../store/actions/packs';

import { PlusOutlined } from '@ant-design/icons';
import PackDrawer from './PackDrawer';
import PackItem from './PackItem';
import { Spin, message } from 'antd';

interface PacksListProps {
    organization: Organization;
    packs: PacksState;
    getList: typeof packsList.trigger;
    resetRemove: typeof packsRemove.reset;
}

const PacksList: FC<PacksListProps> = ({
    organization,
    packs,
    getList,
    resetRemove,
}) => {

    const itemsPerPage: number = 20;
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useState({});

    useEffect(() => {
        setLastSearchParams({
            page: 0,
            pageSize: itemsPerPage,
            organization: organization.id,
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (packs.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }

        if (packs.remove.error) {
            resetRemove();
            message.error('Des utilisateurs sont actuellement associés à ce pack');
        }
    }, [packs.remove.success, packs.remove.error, resetRemove, getList, lastSearchParams]);

    // ---------------------------------------
    // Drawer management

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const edit = (pack: Pack) => {
        setSelectedId(pack.id);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete pack

    // ---------------------------------------
    // Render

    return (
        <Spin spinning={packs.list.loading}>
            <div className="custom-tab-content card-grid">
                <button
                    className="add-new"
                    onClick={add}
                >
                    <PlusOutlined />
                    Ajouter un pack
                </button>

                {packs.list.data.items.map((pack, i) => (
                    <PackItem
                        key={'pack' + pack.id}
                        pack={pack}
                        onEdit={edit}
                        onRenew={edit}
                    />
                ))}
            </div>

            <PackDrawer
                id={selectedId}
                organizationId={organization.id}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
        </Spin>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    packs: state.packs,
});

export default connect(
    mapStateToProps,
    {
        getList: packsList.trigger,
        resetRemove: packsRemove.reset,
    },
)(PacksList);
