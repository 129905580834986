import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
    Popconfirm,
} from 'antd';

import { LibraryDocument, LibraryDocumentType, SupportedLanguage, ProgramTaskFamily } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import Search from 'antd/lib/input/Search';
import LibraryDocumentDrawer from './LibraryDocumentDrawer';
import {
    LibraryDocumentsState,
    list as libraryDocumentsList,
    remove as libraryDocumentsRemove,
    update as libraryDocumentsUpdate,
} from '../../../store/actions/libraryDocuments';

import { FilterQuery } from '../../../store/api';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import useSessionStorage from '../../../hooks/sessionStorage';
import { useHistory } from 'react-router-dom';
import Seo from '../../../components/Seo';
import { translateLibraryDocumentType, translateLanguage, translateTaskFamily } from '../../../helpers/i18n';

const rowKey = (item: LibraryDocument) => `${item.id}`;

interface LibraryDocumentsListProps {
    libraryDocuments: LibraryDocumentsState;
    getList: typeof libraryDocumentsList.trigger;
    remove: typeof libraryDocumentsRemove.trigger;
    update: typeof libraryDocumentsUpdate.trigger;
    resetRemove: typeof libraryDocumentsRemove.reset;
}

const LibraryDocumentsList: FC<LibraryDocumentsListProps> = ({
    libraryDocuments,
    getList,
    remove,
    update,
    resetRemove,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('libraryDocuments_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {
        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (libraryDocuments.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }
    }, [libraryDocuments.remove.success, resetRemove, getList, lastSearchParams]);

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<LibraryDocument>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.type && tableFilters.type.length > 0) {
            queryFilters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        if (tableFilters.family && tableFilters.family.length > 0) {
            queryFilters.push({
                name: 'family',
                value: tableFilters.family[0],
            });
        }

        if (tableFilters.language && tableFilters.language.length > 0) {
            queryFilters.push({
                name: 'language',
                value: tableFilters.language[0],
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const edit = (item: LibraryDocument) => {
        setSelectedId(item.id);
        setIsDrawerVisible(true);
    };

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete libraryDocument

    const removeItem = (libraryDocument: LibraryDocument) => {
        remove({id: libraryDocument.id});
    };

    // ---------------------------------------
    // Filters

    /*const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: LibraryDocument['id']) => {
        setSearchParam('tags', id);
    };*/

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const columns: Array<ColumnProps<LibraryDocument>> = [
        {
            dataIndex: ['name', 'en'],
            title: 'Titre',
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
            render: (title, record) => (record.code ? record.code + ' - ' + title : title),
        },
        {
            dataIndex: 'type',
            title: 'Type',
            render: (type: LibraryDocumentType) => translateLibraryDocumentType(type),
            filterMultiple: false,
            filters: Object.keys(LibraryDocumentType).map((key: any) => ({
                text: translateLibraryDocumentType((LibraryDocumentType as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('type'),
        },
        {
            dataIndex: 'family',
            title: 'Famille',
            render: (type: ProgramTaskFamily) => translateTaskFamily(type),
            filterMultiple: false,
            filters: Object.keys(ProgramTaskFamily).map((key: any) => ({
                text: translateTaskFamily((ProgramTaskFamily as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('family'),
        },
        {
            dataIndex: 'language',
            title: 'Langue',
            render: (language: SupportedLanguage) => translateLanguage(language),
            filterMultiple: false,
            filters: Object.keys(SupportedLanguage).map((key: any) => ({
                text: translateLanguage((SupportedLanguage as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('language'),
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 120,
            render: (text, record) => (
                <>
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<EditFilled />}
                        onClick={edit.bind(null, record)}
                    />

                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    return (
            <>
                <Seo title="Bibliothèque - Documents" />
                <div className="page-header-container">
                    <div className="page-header">
                        <h1 className="page-title">
                            Bibliothèque - Documents <Badge count={libraryDocuments.list.data.totalCount} overflowCount={100000} />
                        </h1>

                        <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={add}
                        >
                            Ajouter un document
                        </Button>
                    </div>

                    <Search
                        className="page-search"
                        placeholder="Rechercher un titre de document"
                        defaultValue={isBack() ? lastSearchParams.search : undefined}
                        loading={libraryDocuments.list.loading}
                        onSearch={onSearch}
                        allowClear
                        size="large"
                    />
                </div>

                {libraryDocuments.list ? (
                    <Table<LibraryDocument>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={libraryDocuments.list.loading}
                        dataSource={libraryDocuments.list.data.items}
                        pagination={{
                            total: libraryDocuments.list.data.totalCount,
                            current: libraryDocuments.list.data.page + 1,
                            pageSize: libraryDocuments.list.data.pageSize,
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <LibraryDocumentDrawer
                    id={selectedId}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryDocuments: state.libraryDocuments,
});

export default connect(
    mapStateToProps,
    {
        getList: libraryDocumentsList.trigger,
        remove: libraryDocumentsRemove.trigger,
        resetRemove: libraryDocumentsRemove.reset,
        update: libraryDocumentsUpdate.trigger,
    },
)(LibraryDocumentsList);
