import React, { FC, useState, forwardRef, useEffect } from 'react';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import { Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { InboxOutlined } from '@ant-design/icons';

interface InputFileProps {
    onChange?: (value: any) => void;
    value ?: any;
    initialValue ?: string;
    setValue ?: any;
    format ?: string;
    isImage ?: boolean;
    maxSize?: number;
}

const InputFile: FC<InputFileProps> = forwardRef((props, ref: any) => {

    const { onChange, format, isImage, maxSize } = props;
    const [ , setValue ] = useState<any>();
    const [ initialValue, setInitialValue ] = useState<string>();

    const [ file, setFile ] = useState<RcFile>();
    const [ showFileUpload, setShowFileUpload ] = useState(true);

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
            setInitialValue(props.initialValue);
            setShowFileUpload(false);
            if (onChange) { onChange(props.initialValue); }
        }
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
            setInitialValue(props.value);
            if (onChange) { onChange(props.value); }
        }
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Prepare thumbnail upload file

    const prepareLogoUpload: DraggerProps['beforeUpload'] = (f) => {
        if (isImage && f.type !== 'image/jpeg' && f.type !== 'image/png') {
            message.error('Le format de fichier n\'est pas respecté, veuillez choisir une image au format JPEG ou PNG');
            return false;
        }

        if (maxSize && f.size / 1024 > maxSize) {
            message.error(`La taille de fichier dépasse la limite maximale autorisée (${maxSize} ko)`);
            return false;
        }

        setFile(f);
        setValue(f);
        if (onChange) { onChange(f); }
        return false;
    };

    const onFileRemove: DraggerProps['onRemove'] = () => {
        setFile(undefined);
        setShowFileUpload(true);
        setValue(undefined);
        if (onChange) { onChange(undefined); }
    };

    return (
        <div className="file-input">
            {(showFileUpload && !file) ? (
                <Dragger
                    beforeUpload={prepareLogoUpload}
                    multiple={false}
                    listType="picture"
                    showUploadList={false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Cliquez-ici ou déposez un fichier</p>
                    <p className="ant-upload-hint">
                        {format ? format : 'Fichier au format .pdf, jpg, png, taille maximum 4 MB.'}
                    </p>
                </Dragger>
            ) : (
                <Upload
                    onRemove={onFileRemove}
                    listType="picture"
                    // tslint:disable-next-line: jsx-no-lambda
                    isImageUrl={() => !!isImage}
                    fileList={
                        file ? [file] : (
                        initialValue ?
                            [
                                {
                                    uid: '-1',
                                    name: initialValue.split('/').slice(-1)[0] ,
                                    status: 'done',
                                    url: initialValue,
                                    thumbUrl: initialValue,
                                    size: 100,
                                    type: 'picture',
                                },
                            ] : []
                        )
                    }
                />
            )}
        </div>
    );

});

export default InputFile;
