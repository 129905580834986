export default Object.freeze({
    PAGE_SIZE: 50,
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Mon Coach Mobilité',
        DEFAULT_TITLE: 'Mon Coach Mobilité',
        DEFAULT_DESCRIPTION: 'Mon coach Mobilité est un cabinet de conseil RH spécialisé dans la mobilité. Que ce soit dans le cadre de plans sociaux (PSE - RCC - PVD), de mobilité interne ou d\'out/inplacements. Nous combinons un accompagnement digital permanent et un coaching humain périodique.',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
    QUILL_TOOLBAR: Object.freeze({
        toolbar: [
            [
                { header: [1, 2, 3] },
            ],
            [
                { font: [] },
                { size: [] },
                {
                    color: [
                        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                        '#6B5AED', '#7881B2',
                    ],
                },
            ],
            ['bold', 'italic', 'underline'],
            ['image', 'link'],
        ],
    }),
});
