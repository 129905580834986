import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Spin } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { Organization } from '../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import {
    OrganizationsState,
    details as organizationDetails,
    update as organizationUpdate,
} from '../../store/actions/organizations';
import ColorPicker from '../../components/ColorPicker';
import InputFile from '../../components/InputFile';

interface OrganizationSettingsDrawerProps extends RouteProps {
    id?: Organization['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    organizations: OrganizationsState;
    getDetails: typeof organizationDetails.trigger;
    detailsReset: typeof organizationDetails.reset;
    update: typeof organizationUpdate.trigger;
    updateReset: typeof organizationUpdate.reset;
}

const OrganizationThemeDrawer: FC<OrganizationSettingsDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    organizations,
    getDetails,
    detailsReset,
    update,
    updateReset,
}) => {

    const organization: Organization | undefined = organizations.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
        } else if (isVisible && !id) {
            detailsReset();
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organizations.details.data) {
            form.setFieldsValue(organizations.details.data);
        }
    }, [organizations.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organizations.update.success) {
            updateReset();
            if (onSuccess) {
                onSuccess();
            }
        }
    }, [organizations.update.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update({
                    ...values,
                    theme: {
                        ...values.theme,
                        ...(values.logo ?
                            {} :
                            { logoUrl: values.theme.logoUrl || null }
                        ),
                        ...(values.banner ?
                            {} :
                            { bannerUrl: values.theme.bannerUrl || null }
                        ),
                    },
                });
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
    };

    // ---------------------------------------
    // Render the page

    return (
        <Drawer
            className="organization-drawer"
            title={'Thème du client'}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={organizations.details.loading}>
                {organization && (
                    <Form
                        onFinish={onFormFinish}
                        layout="vertical"
                        hideRequiredMark
                        form={form}
                        initialValues={organization}
                    >
                        <Form.Item
                            label="Logo"
                            name={['logo']}
                        >
                            <InputFile
                                format="Fichier au format jpg ou png (max 150 ko)"
                                initialValue={organization?.theme?.logoUrl}
                                isImage={true}
                                maxSize={150}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Bannière"
                            name={['banner']}
                        >
                            <InputFile
                                format="Fichier au format jpg ou png (max 300 ko)"
                                initialValue={organization?.theme?.bannerUrl}
                                isImage={true}
                                maxSize={300}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Couleur principale (Boutons, menu...)"
                            name={['theme', 'primary']}
                        >
                            <ColorPicker defaultColor="#6B5AED"/>
                        </Form.Item>

                        <Form.Item
                            label="Couleur secondaire (Titres des blocs page action networking)"
                            name={['theme', 'secondary']}
                        >
                            <ColorPicker defaultColor="#7881B2"/>
                        </Form.Item>

                        <Form.Item
                            label="Couleur de fond (Baromètre, fond d'écrans de création...)"
                            name={['theme', 'background']}
                        >
                            <ColorPicker defaultColor="#E5EAFF"/>
                        </Form.Item>

                        <Form.Item className="cta-submit">
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                block
                                htmlType="submit"
                                loading={organizations.update.loading}
                            >
                                Enregistrer les modifications
                            </Button>
                        </Form.Item>

                    </Form>
                )}
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    organizations: state.organizations,
});

export default connect(
    mapStateToProps,
    {
        getDetails: organizationDetails.trigger,
        detailsReset: organizationDetails.reset,
        update: organizationUpdate.trigger,
        updateReset: organizationUpdate.reset,
    },
)(OrganizationThemeDrawer);
