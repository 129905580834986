import { hasOwnProp, debug } from './helpers';

export enum RoutePathName {
    users = 'users',
    organizations = 'organizations',
    organization = 'organization',
    organization_program = 'organization_program',
    website = 'website',
    website_pages = 'website_pages',
    website_translations = 'website_translations',
    website_faq = 'website_faq',
    library = 'library',
    library_programs = 'library_programs',
    library_program = 'library_program',
    library_actions = 'library_actions',
    library_documents = 'library_documents',
    library_notifications = 'library_notifications',
    home = 'home',
    login = 'login',
    resetPassword = 'resetPassword',
    forgottenPassword = 'forgottenPassword',
    notFound = 'notFound',
    privacy = 'privacy',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.users]: '/users',
    [RoutePathName.organizations]: '/organizations',
    [RoutePathName.organization]: '/organization/:id',
    [RoutePathName.organization_program]: '/organization/:organizationId/program/:programId',
    [RoutePathName.website]: '/website',
    [RoutePathName.website_pages]: '/website/pages',
    [RoutePathName.website_translations]: '/website/translations',
    [RoutePathName.website_faq]: '/website/faq',
    [RoutePathName.library]: '/library',
    [RoutePathName.library_programs]: '/library/programs',
    [RoutePathName.library_program]: '/library/program/:programId',
    [RoutePathName.library_actions]: '/library/tasks',
    [RoutePathName.library_documents]: '/library/documents',
    [RoutePathName.library_notifications]: '/library/notifications',
    [RoutePathName.home]: '/',
    [RoutePathName.login]: '/login',
    [RoutePathName.forgottenPassword]: '/forgotten-password',
    [RoutePathName.resetPassword]: '/reset-password',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.privacy]: '/confidentialite',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!hasOwnProp(routes, path)) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string,
) => {
    let route = getRawRoute(path);
    const rawParams = route.split('/').filter((str) => str.startsWith(':'));
    const providedParamNames = params ? Object.keys(params) : [];

    rawParams.forEach((rawParam) => {
        const isOptionnal = rawParam.endsWith('?');
        const rawParamStripped = isOptionnal ?
            rawParam.substring(1, rawParam.length - 1) :
            rawParam.substring(1);

        if (params && providedParamNames.includes(rawParamStripped)) {
            route = route.replace(rawParam, `${params[rawParamStripped]}`);
        } else if (isOptionnal) {
            debug.info(`[getRoute] Optionnal param "${rawParamStripped}" not provided in route "${route}", skipping param.`);
            route = route.replace(`/${rawParam}`, '');
        } else {
            throw new Error(`[getRoute] Param "${rawParamStripped}" not provided in route "${route}".`);
        }
    });

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
