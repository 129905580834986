import { WebsitePage } from './apiTypes';
import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';

export interface WebsitePageDetailsPayload {
    id ?: string;
    slug ?: string;
}

export type WebsitePageListPayload = SearchPaginationQuery;

export const details = (payload: WebsitePageDetailsPayload) => api.get(`/staticPages/${payload.slug}`);

export const list = (payload: WebsitePageListPayload = {}) => {
    return api.get(`/staticPages`, {params: cleanPayload(payload)});
};

export const create = (payload: WebsitePage) => {
    return api.post(`/staticPages`, payload);
};

export const update = (payload: WebsitePage) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/staticPages/${id}`, payload);
};

export const remove = (payload: WebsitePage) => {
    return api.delete(`/staticPages/${payload.id}`);
};
