import { ProgramTask } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface ProgramTaskDetailsPayload {
    id?: string;
    programId ?: string;
    weekId ?: string;
}

export type ProgramTaskListPayload = SearchPaginationQuery & ProgramTaskDetailsPayload;

export const details = (payload: ProgramTaskDetailsPayload) => api.get(`/programs/tasks/${payload.id}`);

export const list = (payload: ProgramTaskListPayload = {}) => {
    return api.get(`/programs/${payload.programId}/tasks`, {params: cleanPayload(payload)});
};

export const create = (payload: ProgramTask) => {
    return api.post(`/programs/${payload.programId}/tasks`, payload);
};

export const update = (payload: ProgramTask) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/programs/tasks/${id}`, payload);
};

export const remove = (payload: ProgramTask) => {
    return api.delete(`/programs/tasks/${payload.id}`);
};
