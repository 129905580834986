import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Popconfirm,
    Dropdown,
    Menu,
    message,
} from 'antd';

import { Program, Organization, ProgramType, SupportedLanguage } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import Search from 'antd/lib/input/Search';
import ProgramDrawer from './ProgramDrawer';
import {
    ProgramsState,
    list as programsList,
    remove as programsRemove,
    update as programsUpdate,
    create as programsCreate,
} from '../../../store/actions/programs';

import { FilterQuery } from '../../../store/api';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import useSessionStorage from '../../../hooks/sessionStorage';
import { useHistory, NavLink } from 'react-router-dom';
import { translateProgramType, translateLanguage } from '../../../helpers/i18n';
import { getRoute, RoutePathName } from '../../../routes';
import LibraryProgramModal from '../../library/programs/LibraryProgramModal';

const rowKey = (item: Program) => `${item.id}`;

interface ProgramsListProps {
    organization: Organization;
    programs: ProgramsState;
    getList: typeof programsList.trigger;
    remove: typeof programsRemove.trigger;
    update: typeof programsUpdate.trigger;
    resetRemove: typeof programsRemove.reset;
    create: typeof programsCreate.trigger;
    resetCreate: typeof programsCreate.reset;
}

const ProgramsList: FC<ProgramsListProps> = ({
    organization,
    programs,
    getList,
    remove,
    update,
    resetRemove,
    create,
    resetCreate,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ isLibraryProgramModalVisible, setIsLibraryProgramModalVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('programs_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {
        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
                organization: organization.id,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (programs.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }

        if (programs.remove.error) {
            resetRemove();
            message.error('Des utilisateurs sont actuellement associés à ce programme');
        }
    }, [programs.remove.success, programs.remove.error, resetRemove, getList, lastSearchParams]);

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<Program>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.active && tableFilters.active.length > 0) {
            queryFilters.push({
                name: 'active',
                value: tableFilters.active[0] === 'yes',
            });
        }

        if (tableFilters.type && tableFilters.type.length > 0) {
            queryFilters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        if (tableFilters.language && tableFilters.language.length > 0) {
            queryFilters.push({
                name: 'language',
                value: tableFilters.language[0],
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    /*const edit = (item: Program) => {
        setSelectedId(item.id);
        setIsDrawerVisible(true);
    };*/

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete program

    const removeItem = (program: Program) => {
        remove({id: program.id});
    };

    // ---------------------------------------
    // Filters

    /*const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: Program['id']) => {
        setSearchParam('tags', id);
    };*/

    // ---------------------------------------
    // Program library

    const useLibrary = () => {
        setIsLibraryProgramModalVisible(true);
    };

    const onLibraryProgramSelection = (selectedProgram: any) => {
        setIsLibraryProgramModalVisible(false);
        if (selectedProgram) {
            create({
                organization: organization.id,
                fromTemplate: selectedProgram.program,
            });
        }
    };

    useEffect(() => {
        if (programs.create.success && !!programs.create.payload?.fromTemplate) {
            resetCreate();
            message.success('Le programme a bien été dupliqué');
        }
    }, [programs.create.success, programs.create.payload, resetCreate]);

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const columns: Array<ColumnProps<Program>> = [
        {
            dataIndex: ['name', 'en'],
            title: 'Nom',
            render: (name, record) => (
                <NavLink to={getRoute(RoutePathName.organization_program, { organizationId: organization.id, programId: record.id })}>
                    {name}
                </NavLink>
            ),
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
        },
        {
            dataIndex: 'type',
            title: 'Type',
            render: (type: ProgramType) => translateProgramType(type),
            filterMultiple: false,
            filters: Object.keys(ProgramType).map((key: any) => ({
                text: translateProgramType((ProgramType as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('type'),
        },
        {
            dataIndex: 'weekCount',
            title: 'Semaines',
        },
        {
            dataIndex: 'language',
            title: 'Langue',
            render: (language: SupportedLanguage) => translateLanguage(language),
            filterMultiple: false,
            filters: Object.keys(SupportedLanguage).map((key: any) => ({
                text: translateLanguage((SupportedLanguage as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('language'),
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 140,
            render: (text, record) => (
                <>
                    <NavLink to={getRoute(RoutePathName.organization_program, { organizationId: organization.id, programId: record.id })}>
                        <Button
                            shape="circle"
                            type="ghost"
                            icon={<EditFilled />}
                        />
                    </NavLink>

                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    const actionsMenu = (
        <Menu>
            <Menu.Item onClick={add}>
                Créer un nouveau programme
            </Menu.Item>
            <Menu.Item onClick={useLibrary}>
                Utiliser un programme existant
            </Menu.Item>
        </Menu>
    );

    return (
            <>
                <div className="page-header">
                    <Search
                        className="page-search"
                        placeholder="Rechercher un nom de programme"
                        defaultValue={isBack() ? lastSearchParams.search : undefined}
                        loading={programs.list.loading}
                        onSearch={onSearch}
                        allowClear
                    />

                    <Dropdown
                        overlay={actionsMenu}
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                        >
                            Créer un programme
                        </Button>
                    </Dropdown>
                </div>

                {programs.list ? (
                    <Table<Program>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={programs.list.loading}
                        dataSource={programs.list.data.items}
                        pagination={{
                            total: programs.list.data.totalCount,
                            current: programs.list.data.page + 1,
                            pageSize: programs.list.data.pageSize,
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <ProgramDrawer
                    id={selectedId}
                    organizationId={organization.id}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />

                <LibraryProgramModal
                    isVisible={isLibraryProgramModalVisible}
                    onSuccess={onLibraryProgramSelection}
                    onClose={setIsLibraryProgramModalVisible.bind(null, false)}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    programs: state.programs,
});

export default connect(
    mapStateToProps,
    {
        getList: programsList.trigger,
        remove: programsRemove.trigger,
        resetRemove: programsRemove.reset,
        update: programsUpdate.trigger,
        create: programsCreate.trigger,
        resetCreate: programsCreate.reset,
    },
)(ProgramsList);
