import React, { FC, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Spin } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    LibraryDocumentsState,
    details as libraryDocumentDetails,
    update as libraryDocumentUpdate,
    create as libraryDocumentCreate,
} from '../../../store/actions/libraryDocuments';
import LibraryDocumentFilterSelect from './LibraryDocumentFilterSelect';

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface LibraryDocumentModalProps extends RouteProps {
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: (payload: any) => void;

    libraryDocuments: LibraryDocumentsState;
    getDetails: typeof libraryDocumentDetails.trigger;
    detailsReset: typeof libraryDocumentDetails.reset;
    update: typeof libraryDocumentUpdate.trigger;
    updateReset: typeof libraryDocumentUpdate.reset;
    create: typeof libraryDocumentCreate.trigger;
    createReset: typeof libraryDocumentCreate.reset;
}

const LibraryDocumentModal: FC<LibraryDocumentModalProps> = ({
    isVisible,
    onClose,
    onSuccess,

    libraryDocuments,
}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) {
            form.resetFields();
        }
    }, [isVisible, form]);

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (onSuccess) { onSuccess(values); }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    return (
        <Modal
            className="select-modal"
            title={'Ajouter des documents'}
            width={500}
            onCancel={onDrawerClose}
            centered
            visible={isVisible}
            destroyOnClose={true}
            okText={'Valider'}
            okButtonProps={{
                type: 'primary',
                shape: 'round',
            }}
            cancelText={'Annuler'}
            cancelButtonProps={{
                shape: 'round',
            }}
            onOk={form.submit}
        >
            <Spin spinning={libraryDocuments.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Form.Item
                        name="documents"
                        rules={[requiredRule]}
                    >
                        <LibraryDocumentFilterSelect
                            size="large"
                            multi={true}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryDocuments: state.libraryDocuments,
});

export default connect(
    mapStateToProps,
    {
        getDetails: libraryDocumentDetails.trigger,
        detailsReset: libraryDocumentDetails.reset,
        create: libraryDocumentCreate.trigger,
        createReset: libraryDocumentCreate.reset,
        update: libraryDocumentUpdate.trigger,
        updateReset: libraryDocumentUpdate.reset,
    },
)(LibraryDocumentModal);
