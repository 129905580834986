import React, { FC, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Spin } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    LibraryProgramsState,
    details as libraryProgramDetails,
    update as libraryProgramUpdate,
    create as libraryProgramCreate,
} from '../../../store/actions/libraryPrograms';
import LibraryProgramFilterSelect from './LibraryProgramFilterSelect';

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface LibraryProgramModalProps extends RouteProps {
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: (payload: any) => void;

    libraryPrograms: LibraryProgramsState;
    getDetails: typeof libraryProgramDetails.trigger;
    detailsReset: typeof libraryProgramDetails.reset;
    update: typeof libraryProgramUpdate.trigger;
    updateReset: typeof libraryProgramUpdate.reset;
    create: typeof libraryProgramCreate.trigger;
    createReset: typeof libraryProgramCreate.reset;
}

const LibraryProgramModal: FC<LibraryProgramModalProps> = ({
    isVisible,
    onClose,
    onSuccess,

    libraryPrograms,
}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) {
            form.resetFields();
        }
    }, [isVisible, form]);

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (onSuccess) { onSuccess(values); }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    return (
        <Modal
            className="select-modal"
            title={'Utiliser un programme existant'}
            width={500}
            onCancel={onDrawerClose}
            centered
            visible={isVisible}
            destroyOnClose={true}
            okText={'Valider'}
            okButtonProps={{
                type: 'primary',
                shape: 'round',
            }}
            cancelText={'Annuler'}
            cancelButtonProps={{
                shape: 'round',
            }}
            onOk={form.submit}
        >
            <Spin spinning={libraryPrograms.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Form.Item
                        name="program"
                        rules={[requiredRule]}
                    >
                        <LibraryProgramFilterSelect
                            size="large"
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryPrograms: state.libraryPrograms,
});

export default connect(
    mapStateToProps,
    {
        getDetails: libraryProgramDetails.trigger,
        detailsReset: libraryProgramDetails.reset,
        create: libraryProgramCreate.trigger,
        createReset: libraryProgramCreate.reset,
        update: libraryProgramUpdate.trigger,
        updateReset: libraryProgramUpdate.reset,
    },
)(LibraryProgramModal);
