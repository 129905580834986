import React, { FC, useEffect } from 'react';
import { Drawer, Form, Spin, notification } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { ProgramTask, Program, ProgramWeek } from '../../store/api/apiTypes';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    ProgramTasksState,
    details as programTaskDetails,
    update as programTaskUpdate,
    create as programTaskCreate,
} from '../../store/actions/programTasks';
import ProgramTaskForm from '../../forms/ProgramTaskForm';

interface ProgramTaskDrawerProps extends RouteProps {
    id?: ProgramTask['id'];
    programId?: Program['id'];
    weekId?: ProgramWeek['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    programTasks: ProgramTasksState;

    getDetails: typeof programTaskDetails.trigger;
    detailsReset: typeof programTaskDetails.reset;
    update: typeof programTaskUpdate.trigger;
    updateReset: typeof programTaskUpdate.reset;
    create: typeof programTaskCreate.trigger;
    createReset: typeof programTaskCreate.reset;
}

const ProgramTaskDrawer: FC<ProgramTaskDrawerProps> = ({
    id,
    programId,
    weekId,
    isVisible,
    onClose,
    onSuccess,

    programTasks,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const programTask: ProgramTask | undefined = programTasks.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            detailsReset();
            getDetails({ id });
        } else if (isVisible && !id) {
            detailsReset();
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programTasks.details.data) {
            form.setFieldsValue(programTasks.details.data);
        }
    }, [programTasks.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programTasks.update.success || programTasks.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            detailsReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (programTasks.update.error || programTasks.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [programTasks.update.success, programTasks.create.success, programTasks.update.error, programTasks.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormSubmit = (values: any) => {
        if (programId) { values.programId = programId; }
        if (weekId) { values.week = weekId; }

        if (id) {
            values.id = id;
            update(values);
        } else {
            create(values);
        }
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        detailsReset();
        form.resetFields();
    };

    return (
        <Drawer
            className="programTask-drawer"
            title={(!id) ? 'Créer une action' : 'Editer l\'action'}
            width={680}
            onClose={onDrawerClose}
            visible={isVisible}
            destroyOnClose={true}
        >
            <Spin spinning={programTasks.details.loading}>
                {isVisible && (
                    <ProgramTaskForm
                        loading={programTasks.create.loading || programTasks.update.loading}
                        initialValues={programTask}
                        onSubmit={onFormSubmit}
                        isFromLibrary={false}
                    />
                )}
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    programTasks: state.programTasks,
});

export default connect(
    mapStateToProps,
    {
        getDetails: programTaskDetails.trigger,
        detailsReset: programTaskDetails.reset,
        create: programTaskCreate.trigger,
        createReset: programTaskCreate.reset,
        update: programTaskUpdate.trigger,
        updateReset: programTaskUpdate.reset,
    },
)(ProgramTaskDrawer);
