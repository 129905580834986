import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Spin, notification, Tabs } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { QuestionSection, SupportedLanguage, LanguagesList } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    QuestionSectionsState,
    details as questionSectionDetails,
    update as questionSectionUpdate,
    create as questionSectionCreate,
} from '../../../store/actions/questionSections';
import Modal from 'antd/lib/modal/Modal';
import Flag from '../../../components/Flag';

const { TabPane } = Tabs;
const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface QuestionSectionDrawerProps extends RouteProps {
    id?: QuestionSection['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    questionSections: QuestionSectionsState;
    getDetails: typeof questionSectionDetails.trigger;
    detailsReset: typeof questionSectionDetails.reset;
    update: typeof questionSectionUpdate.trigger;
    updateReset: typeof questionSectionUpdate.reset;
    create: typeof questionSectionCreate.trigger;
    createReset: typeof questionSectionCreate.reset;
}

const QuestionSectionDrawer: FC<QuestionSectionDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    questionSections,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const questionSection: QuestionSection | undefined = questionSections.details.data;
    const [form] = Form.useForm();
    const [ tabLang, setTabLang ] = useState<SupportedLanguage>(SupportedLanguage.fr);

    useEffect(() => {
        setTabLang(SupportedLanguage.fr);
        detailsReset();
        form.resetFields();

        if (isVisible && id) {
            getDetails({id});
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (questionSections.details.data) {
            form.setFieldsValue(questionSections.details.data);
        }
    }, [questionSections.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (questionSections.update.success || questionSections.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (questionSections.update.error || questionSections.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [questionSections.update.success, questionSections.create.success, questionSections.update.error, questionSections.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const tabChange = (val: string) => {
        setTabLang((val as SupportedLanguage));
    };

    return (
        <Modal
            className="questionSection-modal"
            title={(!id) ? 'Créer une section' : 'Editer la section'}
            width={500}
            onCancel={onDrawerClose}
            centered
            visible={isVisible}
            destroyOnClose={true}
            footer={null}
        >
            <Spin spinning={questionSections.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                        { LanguagesList.map((language: SupportedLanguage) => (
                            <TabPane tab={<Flag language={language} />} key={'content-' + language}>
                                <Form.Item
                                    label="Titre"
                                    name={['title', language]}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <Input size="large" placeholder="Saisir un titre" />
                                </Form.Item>
                            </TabPane>
                        ))}
                    </Tabs>

                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                        loading={questionSections.create.loading || questionSections.update.loading}
                    >
                        {questionSection ? 'Enregistrer les modifications' : 'Créer la section'}
                    </Button>

                </Form>
            </Spin>
        </Modal>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    questionSections: state.questionSections,
});

export default connect(
    mapStateToProps,
    {
        getDetails: questionSectionDetails.trigger,
        detailsReset: questionSectionDetails.reset,
        create: questionSectionCreate.trigger,
        createReset: questionSectionCreate.reset,
        update: questionSectionUpdate.trigger,
        updateReset: questionSectionUpdate.reset,
    },
)(QuestionSectionDrawer);
