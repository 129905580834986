import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
        <g id="1-Clients" fill="currentColor">
            <g transform="translate(-16.000000, -144.000000)">
                <g id="nav_items" transform="translate(0.000000, 128.000000)">
                    <g id="Group" transform="translate(16.000000, 13.000000)">
                        <g id="icon" transform="translate(0.000000, 3.000000)">
                            <path d="M12.8668444,9.04046732 C14.5944889,9.04046732 16,14.0812319 16,16 L16,16 L7.032,16 C7.032,14.0812319 8.43751111,9.04046732 10.1651556,9.04046732 L10.1651556,9.04046732 Z M6.83466667,5.59719986 C7.6608,5.59719986 8.25732422,5.95629883 8.64868164,7.35620117 C8.15014648,8.31640625 6.83466667,8.82788086 5.9284668,12.9674758 L5.9284668,12.9674758 L7.10542736e-15,12.9674758 C7.10542736e-15,10.013059 2.40551111,5.59719986 4.13315556,5.59719986 L4.13315556,5.59719986 Z M11.5159111,3.40262722 C12.6187378,3.40262722 13.5159111,4.3565575 13.5159111,5.5291511 C13.5159111,6.7017447 12.6187378,7.65567497 11.5159111,7.65567497 C10.4130845,7.65567497 9.51591111,6.7017447 9.51591111,5.5291511 C9.51591111,4.3565575 10.4130845,3.40262722 11.5159111,3.40262722 Z M5.48391111,0 C6.58673776,0 7.48391111,0.953930273 7.48391111,2.12652387 C7.48391111,3.29911748 6.58673776,4.25304775 5.48391111,4.25304775 C4.38108446,4.25304775 3.48391111,3.29911748 3.48391111,2.12652387 C3.48391111,0.953930273 4.38122868,0 5.48391111,0 Z" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const IconUsers: FC<IconComponentProps> = ({ ...props }) => (
    // @ts-ignore
    <Icon
        component={svg}
        className={`icon-users`}
        {...props}
    />
);

export default IconUsers;
