import React, { FC, useEffect } from 'react';
import { Drawer, Form, Spin, notification } from 'antd';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import { ProgramTask, Program, ProgramWeek, DrawerActionType } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import {
    LibraryProgramTasksState,
    details as programTaskDetails,
    update as programTaskUpdate,
    create as programTaskCreate,
} from '../../../store/actions/libraryProgramTasks';

import ProgramTaskForm from '../../../forms/ProgramTaskForm';

interface LibraryProgramTaskDrawerProps extends RouteProps {
    id?: ProgramTask['id'];
    programId?: Program['id'];
    weekId?: ProgramWeek['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    drawerActionType: DrawerActionType;

    programTasks: LibraryProgramTasksState;
    getDetails: typeof programTaskDetails.trigger;
    detailsReset: typeof programTaskDetails.reset;
    update: typeof programTaskUpdate.trigger;
    updateReset: typeof programTaskUpdate.reset;
    create: typeof programTaskCreate.trigger;
    createReset: typeof programTaskCreate.reset;
}

const LibraryProgramTaskDrawer: FC<LibraryProgramTaskDrawerProps> = ({
    id,
    programId,
    weekId,
    isVisible,
    onClose,
    onSuccess,
    drawerActionType,
    programTasks,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const programTask: ProgramTask | undefined = programTasks.details.data;
    const [form] = Form.useForm();
    useEffect(() => {
        if (isVisible && id) {
            detailsReset();
            getDetails({ id });
        } else if (isVisible && !id) {
            detailsReset();
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programTasks.details.data) {
            form.setFieldsValue(programTasks.details.data);
        }
    }, [programTasks.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programTasks.update.success || programTasks.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (programTasks.update.error || programTasks.create.error) {

            const errorMessage = (programTasks.create?.error?.status === 409 || programTasks.update?.error?.status === 409) ?
                'Ce code est déjà utilisé' :
                'Une erreur est survenue lors de la sauvegarde';

            updateReset();
            createReset();
            notification.error({
                message: errorMessage,
                placement: 'bottomRight',
            });
        }
    }, [programTasks.update.success, programTasks.create.success, programTasks.update.error, programTasks.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormSubmit = (values: any) => {
        if (programId) { values.programId = programId; }
        if (weekId) { values.week = weekId; }

        if (id && drawerActionType === DrawerActionType.EDIT) {
            values.id = id;
            update(values);
        } else {
            create(values);
        }
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const getDrawerTitle = () => {

        switch (drawerActionType) {

            case DrawerActionType.ADD:
                return 'Créer une action';

            case DrawerActionType.EDIT:
                return 'Editer l\'action';

            case DrawerActionType.DUPLICATE:
                return 'Dupliquer l\'action';

            default:
                return 'Créer une action';
        }
    };

    return (
        <Drawer
            className="programTask-drawer"
            title={getDrawerTitle()}
            width={680}
            onClose={onDrawerClose}
            visible={isVisible}
            destroyOnClose={true}
        >
            <Spin spinning={programTasks.details.loading}>
                <ProgramTaskForm
                    loading={programTasks.create.loading || programTasks.update.loading}
                    initialValues={programTask}
                    onSubmit={onFormSubmit}
                    isFromLibrary={true}
                />
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    programTasks: state.libraryProgramTasks,
});

export default connect(
    mapStateToProps,
    {
        getDetails: programTaskDetails.trigger,
        detailsReset: programTaskDetails.reset,
        create: programTaskCreate.trigger,
        createReset: programTaskCreate.reset,
        update: programTaskUpdate.trigger,
        updateReset: programTaskUpdate.reset,
    },
)(LibraryProgramTaskDrawer);
