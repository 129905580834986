import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import {
    LibraryProgramsState,
    search as searchLibraryPrograms,
} from '../../../store/actions/libraryPrograms';
import { SelectProps } from 'antd/lib/select';
import { LibraryProgram } from '../../../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { LibraryProgramListPayload } from '../../../store/api/libraryPrograms';
import { i18n } from '../../../helpers/i18n';

interface LibraryProgramFilterSelectProps {
    onChange?: (value: any) => void;
    multi?: boolean;
    libraryPrograms: LibraryProgramsState;
    search: typeof searchLibraryPrograms.trigger;
    size?: SizeType;
    value ?: string[];
    setValue ?: any;
    initialValue ?: LibraryProgram[];
    filters ?: LibraryProgramListPayload;
}

const LibraryProgramFilterSelect: FC<LibraryProgramFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, libraryPrograms, search, multi, size, filters} = props;
    const [ value, setValue ] = useState<any>();
    const [ initialValue, setInitialValue ] = useState<LibraryProgram[]>([]);

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue.map((a) => a.id));
            setInitialValue(props.initialValue);
            if (onChange) { onChange(props.initialValue.map((a) => a.id || a)); }
        } else {
            setValue([]);
            setInitialValue([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFocus: SelectProps<LibraryProgram['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 15,
            ...filters,
        });
    };

    const onSearch: SelectProps<LibraryProgram['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 15,
            ...filters,
            throttling: 250,
        });
    };

    const onChangeSelect: SelectProps<LibraryProgram['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {onChange(newValue); }
    };

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            size={size}
            ref={ref}
            showSearch
            value={value}
            placeholder="Recherchez un programme"
            notFoundContent={libraryPrograms.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
        >
            {
                [
                    ...initialValue, // Display initial value
                    ...libraryPrograms.search.data.items.filter((a) => !initialValue.map((b) => b.id).includes(a.id)), // Search items, excluding initial value
                ].map((libraryProgram) => (
                <Select.Option value={libraryProgram.id} key={libraryProgram.id}>{i18n(libraryProgram.name)}</Select.Option>
            ))}
        </Select>
    );

});

LibraryProgramFilterSelect.defaultProps = {
    multi: false,
    size: 'small',
};

const mapStateToProps = (state: MainReducerState) => ({
    libraryPrograms: state.libraryPrograms,
});

export default connect(
    mapStateToProps,
    {
        search: searchLibraryPrograms.trigger,
    },
    null,
    { forwardRef: true },
)(LibraryProgramFilterSelect);
