import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
    Switch,
    Tag,
} from 'antd';

import { MessageTrigger, NotificationChannel } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import Search from 'antd/lib/input/Search';
import NotificationDrawer from './LibraryNotificationDrawer';
import {
    NotificationsState,
    list as notificationsList,
    remove as notificationsRemove,
    update as notificationsUpdate,
} from '../../../store/actions/notifications';

import { FilterQuery } from '../../../store/api';
import { EditFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useSessionStorage from '../../../hooks/sessionStorage';
import { useHistory } from 'react-router-dom';
import Seo from '../../../components/Seo';
import { translateNotificationChannel } from '../../../helpers/i18n';

const rowKey = (item: MessageTrigger) => `${item.id}`;

interface NotificationsListProps {
    notifications: NotificationsState;
    getList: typeof notificationsList.trigger;
    remove: typeof notificationsRemove.trigger;
    update: typeof notificationsUpdate.trigger;
    resetRemove: typeof notificationsRemove.reset;
}

const NotificationsList: FC<NotificationsListProps> = ({
    notifications,
    getList,
    remove,
    update,
    resetRemove,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ selectedNotification, setSelectedNotification ] = useState<MessageTrigger | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('notifications_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {
        setLastSearchParams({
            page: 0,
            pageSize: itemsPerPage,
            filters: [
                {name: 'program', value: 'none'}, // Get library notifications (not assigned to a program)
            ],
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (notifications.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }
    }, [notifications.remove.success, resetRemove, getList, lastSearchParams]);

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<MessageTrigger>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.type && tableFilters.type.length > 0) {
            queryFilters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        if (tableFilters.family && tableFilters.family.length > 0) {
            queryFilters.push({
                name: 'family',
                value: tableFilters.family[0],
            });
        }

        if (tableFilters.language && tableFilters.language.length > 0) {
            queryFilters.push({
                name: 'language',
                value: tableFilters.language[0],
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const edit = (item: MessageTrigger) => {
        setSelectedNotification(item);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setSelectedNotification(undefined);
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
        setSelectedNotification(undefined);
    };

    // ---------------------------------------
    // Disable / Enable / Delete notification

    const onActiveSwitchChange = (notification: MessageTrigger, active: boolean) => {
        const notificationUpdated: MessageTrigger = {
            ...notification,
            meta: {
                ...notification.meta,
                isIncludedByDefault: active,
            },
        };
        delete notificationUpdated.template;

        if (notification.meta) {
            notification.meta.isIncludedByDefault = active;
        } else {
            notification.meta = {
                isIncludedByDefault: active,
            };
        }

        update(notificationUpdated);
    };

    // ---------------------------------------
    // Filters

    /*const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: Notification['id']) => {
        setSearchParam('tags', id);
    };*/

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<MessageTrigger>> = [
        {
            dataIndex: ['name', 'en'],
            title: 'Titre',
            sorter: true,
        },
        {
            dataIndex: ['channels'],
            title: 'Types',
            render: (channels) => (
                channels.map((channel: NotificationChannel) => <Tag key={channel}>{translateNotificationChannel(channel)}</Tag>)
            ),
        },
        {
            title: 'Inclure par défaut',
            key: 'meta.isIncludedByDefault',
            render: (text, record) => (
                <Switch
                    checked={record?.meta?.isIncludedByDefault}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={onActiveSwitchChange.bind(null, record)}
                />
            ),
            width: 200,
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 120,
            render: (text, record) => (
                <>
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<EditFilled />}
                        onClick={edit.bind(null, record)}
                    />
                </>
            ),
        },

    ];

    return (
            <>
                <Seo title="Bibliothèque - Notifications" />
                <div className="page-header-container">
                    <div className="page-header">
                        <h1 className="page-title">
                            Bibliothèque - Notifications <Badge count={notifications.list.data.totalCount} overflowCount={100000} />
                        </h1>
                    </div>

                    <Search
                        className="page-search"
                        placeholder="Rechercher un nom de notification"
                        defaultValue={isBack() ? lastSearchParams.search : undefined}
                        loading={notifications.list.loading}
                        onSearch={onSearch}
                        allowClear
                        size="large"
                    />
                </div>

                {notifications.list ? (
                    <Table<MessageTrigger>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={notifications.list.loading}
                        dataSource={notifications.list.data.items}
                        pagination={{
                            total: notifications.list.data.totalCount,
                            current: notifications.list.data.page + 1,
                            pageSize: notifications.list.data.pageSize,
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <NotificationDrawer
                    notification={selectedNotification}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    notifications: state.notifications,
});

export default connect(
    mapStateToProps,
    {
        getList: notificationsList.trigger,
        remove: notificationsRemove.trigger,
        resetRemove: notificationsRemove.reset,
        update: notificationsUpdate.trigger,
    },
)(NotificationsList);
