import { combineReducers } from 'redux';

import { AuthState, authReducer } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { TestState, testReducer } from '../actions/test';
import { UsersState, usersReducer } from '../actions/users';
import { UserGroupsState, userGroupsReducer } from '../actions/userGroups';
import { OrganizationsState, organizationsReducer } from '../actions/organizations';
import { PacksState, packsReducer } from '../actions/packs';
import { ProgramsState, programsReducer } from '../actions/programs';
import { AdministratorsState, administratorsReducer } from '../actions/administrators';
import { WebsitePagesState, websitePagesReducer } from '../actions/websitePages';
import { ProgramWeeksState, programWeeksReducer } from '../actions/programWeeks';
import { ProgramTasksState, programTasksReducer } from '../actions/programTasks';
import { QuestionsState, questionsReducer } from '../actions/questions';
import { QuestionSectionsState, questionSectionsReducer } from '../actions/questionSections';
import { LibraryDocumentsState, libraryDocumentsReducer } from '../actions/libraryDocuments';
import { LibraryProgramsState, libraryProgramsReducer } from '../actions/libraryPrograms';
import { LibraryProgramTasksState, libraryProgramTasksReducer } from '../actions/libraryProgramTasks';
import { NotificationsState, notificationsReducer } from '../actions/notifications';

export interface MainReducerState {
    readonly auth: AuthState;
    readonly cookies: CookiesState;
    readonly test: TestState;
    readonly users: UsersState;
    readonly userGroups: UserGroupsState;
    readonly organizations: OrganizationsState;
    readonly packs: PacksState;
    readonly programs: ProgramsState;
    readonly administrators: AdministratorsState;
    readonly websitePages: WebsitePagesState;
    readonly programWeeks: ProgramWeeksState;
    readonly programTasks: ProgramTasksState;
    readonly questions: QuestionsState;
    readonly questionSections: QuestionSectionsState;
    readonly libraryDocuments: LibraryDocumentsState;
    readonly libraryPrograms: LibraryProgramsState;
    readonly libraryProgramTasks: LibraryProgramTasksState;
    readonly notifications: NotificationsState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    cookies: cookiesReducer,
    test: testReducer,
    users: usersReducer,
    userGroups: userGroupsReducer,
    organizations: organizationsReducer,
    packs: packsReducer,
    programs: programsReducer,
    administrators: administratorsReducer,
    websitePages: websitePagesReducer,
    programWeeks: programWeeksReducer,
    programTasks: programTasksReducer,
    questions: questionsReducer,
    questionSections: questionSectionsReducer,
    libraryDocuments: libraryDocumentsReducer,
    libraryPrograms: libraryProgramsReducer,
    libraryProgramTasks: libraryProgramTasksReducer,
    notifications: notificationsReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
    readonly payload?: any;
}
