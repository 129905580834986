import React, { FC } from 'react';
import { Skeleton } from 'antd';

import '../assets/styles/CardSkeleton.less';

const CardSkeleton: FC = () => (
    <div className="card-skeleton">
        <header />
        <div className="card-skeleton-body">
            <Skeleton
                paragraph={{ rows: 6 }}
                active
                loading
                title
            />
        </div>
    </div>
);

export default CardSkeleton;
