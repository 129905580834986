import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Popconfirm,
    Badge,
    message,
} from 'antd';

import { LibraryDocument, LibraryDocumentType, SupportedLanguage, Program, ProgramTaskFamily } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import {
    LibraryDocumentsState,
    list as libraryDocumentsList,
    remove as libraryDocumentsRemove,
    update as libraryDocumentsUpdate,
    create as libraryDocumentsCreate,
} from '../../store/actions/libraryDocuments';

import { FilterQuery } from '../../store/api';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import useSessionStorage from '../../hooks/sessionStorage';
import { useHistory } from 'react-router-dom';
import { translateLibraryDocumentType, translateLanguage, translateTaskFamily } from '../../helpers/i18n';
import LibraryDocumentModal from '../library/documents/LibraryDocumentModal';

const rowKey = (item: LibraryDocument) => `${item.id}`;

interface LibraryDocumentsListProps {
    program: Program;
    libraryDocuments: LibraryDocumentsState;
    getList: typeof libraryDocumentsList.trigger;
    remove: typeof libraryDocumentsRemove.trigger;
    update: typeof libraryDocumentsUpdate.trigger;
    resetRemove: typeof libraryDocumentsRemove.reset;
    create: typeof libraryDocumentsCreate.trigger;
    resetCreate: typeof libraryDocumentsCreate.reset;
}

const LibraryDocumentsList: FC<LibraryDocumentsListProps> = ({
    program,
    libraryDocuments,
    getList,
    remove,
    update,
    resetRemove,
    create,
    resetCreate,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ isDocumentsModalVisible, setIsDocumentsModalVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('libraryDocuments_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {
        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
                program: program.id,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (libraryDocuments.remove.success || libraryDocuments.create.success) {
            resetCreate();
            resetRemove();
            getList(lastSearchParams);
        }
    }, [libraryDocuments.remove.success, libraryDocuments.create.success, resetRemove, resetCreate, getList, lastSearchParams]);

    const onTableChange: TableProps<LibraryDocument>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.type && tableFilters.type.length > 0) {
            queryFilters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        if (tableFilters.family && tableFilters.family.length > 0) {
            queryFilters.push({
                name: 'family',
                value: tableFilters.family[0],
            });
        }

        if (tableFilters.language && tableFilters.language.length > 0) {
            queryFilters.push({
                name: 'language',
                value: tableFilters.language[0],
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Disable / Enable / Delete libraryDocument

    const removeItem = (libraryDocument: LibraryDocument) => {
        remove({id: libraryDocument.id});
    };

    // ---------------------------------------
    // LibraryDocument library

    const useLibrary = () => {
        setIsDocumentsModalVisible(true);
    };

    const onLibraryDocumentsSelection = (selectedLibraryDocuments: any) => {
        setIsDocumentsModalVisible(false);
        if (selectedLibraryDocuments) {
            selectedLibraryDocuments.documents.forEach((id: LibraryDocument['id']) => {
                create({
                    program: program.id,
                    fromTemplate: id,
                });
            });
        }
    };

    useEffect(() => {
        if (libraryDocuments.create.success && !!libraryDocuments.create.payload?.fromTemplate) {
            resetCreate();
            message.success('Les documents ont bien été ajoutés');
        }
    }, [libraryDocuments.create.success, libraryDocuments.create.payload, resetCreate]);

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const columns: Array<ColumnProps<LibraryDocument>> = [
        {
            dataIndex: ['name', 'en'],
            title: 'Titre',
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
        },
        {
            dataIndex: 'type',
            title: 'Type',
            render: (type: LibraryDocumentType) => translateLibraryDocumentType(type),
            filterMultiple: false,
            filters: Object.keys(LibraryDocumentType).map((key: any) => ({
                text: translateLibraryDocumentType((LibraryDocumentType as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('type'),
        },
        {
            dataIndex: 'family',
            title: 'Famille',
            render: (type: ProgramTaskFamily) => translateTaskFamily(type),
            filterMultiple: false,
            filters: Object.keys(ProgramTaskFamily).map((key: any) => ({
                text: translateTaskFamily((ProgramTaskFamily as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('family'),
        },
        {
            dataIndex: 'language',
            title: 'Langue',
            render: (language: SupportedLanguage) => translateLanguage(language),
            filterMultiple: false,
            filters: Object.keys(SupportedLanguage).map((key: any) => ({
                text: translateLanguage((SupportedLanguage as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('language'),
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 100,
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    return (
        <div id="program-weeks">
            <div className="page-header">
                <h1 className="page-title">
                    Documents <Badge count={libraryDocuments.list.data.totalCount} showZero={true} />
                </h1>
                <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    onClick={useLibrary}
                >
                    Ajouter des documents
                </Button>
            </div>

            {libraryDocuments.list ? (
                <Table<LibraryDocument>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={libraryDocuments.list.loading}
                    dataSource={libraryDocuments.list.data.items}
                    pagination={{
                        total: libraryDocuments.list.data.totalCount,
                        current: libraryDocuments.list.data.page + 1,
                        pageSize: libraryDocuments.list.data.pageSize,
                        hideOnSinglePage: true,
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}

            <LibraryDocumentModal
                isVisible={isDocumentsModalVisible}
                onSuccess={onLibraryDocumentsSelection}
                onClose={setIsDocumentsModalVisible.bind(null, false)}
            />
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryDocuments: state.libraryDocuments,
});

export default connect(
    mapStateToProps,
    {
        getList: libraryDocumentsList.trigger,
        remove: libraryDocumentsRemove.trigger,
        resetRemove: libraryDocumentsRemove.reset,
        update: libraryDocumentsUpdate.trigger,
        create: libraryDocumentsCreate.trigger,
        resetCreate: libraryDocumentsCreate.reset,
    },
)(LibraryDocumentsList);
