import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
    Popconfirm,
    Switch,
} from 'antd';

import { Organization, User } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import Search from 'antd/lib/input/Search';
import OrganizationDrawer from './OrganizationDrawer';
import {
    OrganizationsState,
    list as organizationsList,
    remove as organizationsRemove,
    update as organizationsUpdate,
} from '../../store/actions/organizations';

import {
    roles as administratorRoles,
} from '../../store/actions/administrators';

import { FilterQuery } from '../../store/api';
import { CheckOutlined, CloseOutlined, PlusOutlined, EyeFilled, DeleteFilled } from '@ant-design/icons';
import Seo from '../../components/Seo';
import useSessionStorage from '../../hooks/sessionStorage';
import { useHistory, NavLink } from 'react-router-dom';
import { RoutePathName, getRoute } from '../../routes';

const rowKey = (item: Organization) => `${item.id}`;

interface OrganizationsListProps {
    organizations: OrganizationsState;
    getList: typeof organizationsList.trigger;
    remove: typeof organizationsRemove.trigger;
    update: typeof organizationsUpdate.trigger;
    resetRemove: typeof organizationsRemove.reset;
    getRoles: typeof administratorRoles.trigger;
}

const OrganizationsList: FC<OrganizationsListProps> = ({
    organizations,
    getList,
    remove,
    update,
    resetRemove,
    getRoles,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('organizations_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {
        getRoles();

        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (organizations.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }
    }, [organizations.remove.success, resetRemove, getList, lastSearchParams]);

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<Organization>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.active && tableFilters.active.length > 0) {
            queryFilters.push({
                name: 'enabled',
                value: tableFilters.active[0] === 'yes',
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete organization

    const onActiveSwitchChange = (organization: Organization, active: boolean) => {
        organization.enabled = active;
        update({
            id: organization.id,
            enabled: active,
        });
    };

    const removeItem = (organization: Organization) => {
        remove({id: organization.id});
    };

    // ---------------------------------------
    // Filters

    /*const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: Organization['id']) => {
        setSearchParam('tags', id);
    };*/

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const columns: Array<ColumnProps<Organization>> = [
        {
            dataIndex: 'name',
            title: 'Nom société',
            render: (name, record) => (
                <NavLink to={getRoute(RoutePathName.organization, { id: record.id })}>
                    {name}
                </NavLink>
            ),
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
        },
        {
            dataIndex: 'group',
            title: 'Nom groupe',
            sorter: true,
            defaultSortOrder: columnSortOrder('group'),
        },
        {
            dataIndex: 'owner',
            title: 'Contact principal',
            render: (owner: User) => (
                <>{owner ? (owner.firstName + ' ' + owner.lastName) : '-'}</>
            ),
        },
        {
            dataIndex: 'licensePackCount',
            title: 'Packs',
        },
        {
            dataIndex: 'licenseCount',
            title: 'Sièges',
        },
        {
            title: 'Actif',
            key: 'active',
            render: (text, record) => (
                <Switch
                    checked={record.enabled}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={onActiveSwitchChange.bind(null, record)}
                />
            ),
            filterMultiple: false,
            filters: [
                {text: 'Oui', value: 'yes'},
                {text: 'Non', value: 'no'},
            ],
            width: 75,
            defaultFilteredValue: getFilterValue('active', true),
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 120,
            render: (text, record) => (
                <>
                    <NavLink to={getRoute(RoutePathName.organization, { id: record.id })}>
                        <Button
                            shape="circle"
                            type="ghost"
                            icon={<EyeFilled />}
                        />
                    </NavLink>

                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    return (
            <>
                <Seo title="Clients" />
                <div className="page-header-container">
                    <div className="page-header">
                        <h1 className="page-title">
                            Clients <Badge count={organizations.list.data.totalCount} overflowCount={100000} />
                        </h1>

                        <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={add}
                        >
                            Créer un client
                        </Button>
                    </div>

                    <Search
                        className="page-search"
                        placeholder="Rechercher un client : Groupe, société, raison sociale, contact"
                        defaultValue={isBack() ? lastSearchParams.search : undefined}
                        loading={organizations.list.loading}
                        onSearch={onSearch}
                        allowClear
                        size="large"
                    />
                </div>

                {organizations.list ? (
                    <Table<Organization>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={organizations.list.loading}
                        dataSource={organizations.list.data.items}
                        pagination={{
                            total: organizations.list.data.totalCount,
                            current: organizations.list.data.page + 1,
                            pageSize: organizations.list.data.pageSize,
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <OrganizationDrawer
                    id={selectedId}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    organizations: state.organizations,
});

export default connect(
    mapStateToProps,
    {
        getList: organizationsList.trigger,
        remove: organizationsRemove.trigger,
        resetRemove: organizationsRemove.reset,
        update: organizationsUpdate.trigger,
        getRoles: administratorRoles.trigger,
    },
)(OrganizationsList);
