import { Pack } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';

export interface PackDetailsPayload {
    id: string;
}

export interface PackUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type PackListPayload = SearchPaginationQuery;

export const details = (payload: PackDetailsPayload) => api.get(`/licensePacks/${payload.id}`);

export const list = (payload: PackListPayload = {}) => {
    return api.get(`/licensePacks`, {params: cleanPayload(payload)});
};

/*export const list = (payload: PackListPayload = {}) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                items: [...Array(8)].map((e, i) => ({
                    id: i,
                    name: 'Demo ' + i,
                })),
                totalCount: 5,
                page: 0,
                pageSize: 20,
                pageCount: 0,
            });
        }, Math.floor(Math.random() * 1000));
    });*/

export const create = (payload: Pack) => {
    return api.post(`/licensePacks`, payload);
};

export const update = (payload: Pack) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/licensePacks/${id}`, payload);
};

export const remove = (payload: Pack) => {
    return api.delete(`/licensePacks/${payload.id}`);
};
