import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
    Popconfirm,
    message,
} from 'antd';

import { SupportedLanguage, LibraryProgram, ProgramType, Program } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import Search from 'antd/lib/input/Search';
import {
    LibraryProgramsState,
    list as libraryProgramsList,
    remove as libraryProgramsRemove,
    update as libraryProgramsUpdate,
    create as libraryProgramsCreate,
} from '../../../store/actions/libraryPrograms';

import { FilterQuery } from '../../../store/api';
import { PlusOutlined, DeleteFilled, EditFilled, CopyFilled } from '@ant-design/icons';
import useSessionStorage from '../../../hooks/sessionStorage';
import { useHistory, NavLink } from 'react-router-dom';
import Seo from '../../../components/Seo';
import { translateLanguage, translateProgramType } from '../../../helpers/i18n';
import LibraryProgramDrawer from './LibraryProgramDrawer';
import { RoutePathName, getRoute } from '../../../routes';
import OrganizationModal from '../../organizations/OrganizationModal';

const rowKey = (item: LibraryProgram) => `${item.id}`;

interface LibraryProgramsListProps {
    libraryPrograms: LibraryProgramsState;
    getList: typeof libraryProgramsList.trigger;
    remove: typeof libraryProgramsRemove.trigger;
    update: typeof libraryProgramsUpdate.trigger;
    resetRemove: typeof libraryProgramsRemove.reset;
    create: typeof libraryProgramsCreate.trigger;
    resetCreate: typeof libraryProgramsCreate.reset;
}

const LibraryProgramsList: FC<LibraryProgramsListProps> = ({
    libraryPrograms,
    getList,
    remove,
    update,
    resetRemove,
    create,
    resetCreate,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ isOrganizationModalVisible, setIsOrganizationModalVisible ] = useState(false);
    const [ selectedProgram, setSelectedProgram ] = useState<Program>();
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('libraryPrograms_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {

        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (libraryPrograms.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }
    }, [libraryPrograms.remove.success, resetRemove, getList, lastSearchParams]);

    useEffect(() => {
        if (libraryPrograms.create.success && !!libraryPrograms.create.payload?.fromTemplate) {
            resetCreate();
            message.success('Le programme a bien été dupliqué chez le client');
        }
    }, [libraryPrograms.create.success, libraryPrograms.create.payload, resetCreate]);

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<LibraryProgram>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.type && tableFilters.type.length > 0) {
            queryFilters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        if (tableFilters.family && tableFilters.family.length > 0) {
            queryFilters.push({
                name: 'family',
                value: tableFilters.family[0],
            });
        }

        if (tableFilters.language && tableFilters.language.length > 0) {
            queryFilters.push({
                name: 'language',
                value: tableFilters.language[0],
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    /*const edit = (item: LibraryProgram) => {
        setSelectedId(item.id);
        setIsDrawerVisible(true);
    };*/

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Duplicate to an organization

    const openOrganizationModal = (libraryProgram: LibraryProgram) => {
        setSelectedProgram(libraryProgram);
        setIsOrganizationModalVisible(true);
    };

    const duplicate = (selectedOrganization: LibraryProgram) => {
        if (selectedProgram) {
            selectedOrganization.fromTemplate = selectedProgram.id;
            create(selectedOrganization);
        }
        setIsOrganizationModalVisible(false);
    };

    // ---------------------------------------
    // Remove libraryProgram

    const removeItem = (libraryProgram: LibraryProgram) => {
        remove({id: libraryProgram.id});
    };

    // ---------------------------------------
    // Filters

    /*const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: LibraryProgram['id']) => {
        setSearchParam('tags', id);
    };*/

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const columns: Array<ColumnProps<LibraryProgram>> = [
        {
            dataIndex: ['name', 'en'],
            title: 'Titre',
            render: (title, record) => (
                <NavLink to={getRoute(RoutePathName.library_program, { programId: record.id })}>
                    {(record.code ? record.code + ' - ' + title : title)}
                </NavLink>
            ),
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
        },
        {
            dataIndex: 'type',
            title: 'Type',
            render: (type: ProgramType) => translateProgramType(type),
            filterMultiple: false,
            filters: Object.keys(ProgramType).map((key: any) => ({
                text: translateProgramType((ProgramType as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('type'),
        },
        {
            dataIndex: 'weekCount',
            title: 'Semaines',
        },
        {
            dataIndex: 'language',
            title: 'Langue',
            render: (language: SupportedLanguage) => translateLanguage(language),
            filterMultiple: false,
            filters: Object.keys(SupportedLanguage).map((key: any) => ({
                text: translateLanguage((SupportedLanguage as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('language'),
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 180,
            render: (text, record) => (
                <>
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<CopyFilled />}
                        onClick={openOrganizationModal.bind(null, record)}
                    />

                    <NavLink to={getRoute(RoutePathName.library_program, { programId: record.id })}>
                        <Button
                            shape="circle"
                            type="ghost"
                            icon={<EditFilled />}
                        />
                    </NavLink>

                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    return (
            <>
                <Seo title="Bibliothèque - Programmes" />
                <div className="page-header-container">
                    <div className="page-header">
                        <h1 className="page-title">
                            Bibliothèque - Programmes <Badge count={libraryPrograms.list.data.totalCount} overflowCount={100000} />
                        </h1>

                        <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={add}
                        >
                            Créer un programme
                        </Button>
                    </div>

                    <Search
                        className="page-search"
                        placeholder="Rechercher un nom de programme"
                        defaultValue={isBack() ? lastSearchParams.search : undefined}
                        loading={libraryPrograms.list.loading}
                        onSearch={onSearch}
                        allowClear
                        size="large"
                    />
                </div>

                {libraryPrograms.list ? (
                    <Table<LibraryProgram>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={libraryPrograms.list.loading}
                        dataSource={libraryPrograms.list.data.items}
                        pagination={{
                            total: libraryPrograms.list.data.totalCount,
                            current: libraryPrograms.list.data.page + 1,
                            pageSize: libraryPrograms.list.data.pageSize,
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <LibraryProgramDrawer
                    id={selectedId}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />

                <OrganizationModal
                    isVisible={isOrganizationModalVisible}
                    onSuccess={duplicate}
                    onClose={setIsOrganizationModalVisible.bind(null, false)}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryPrograms: state.libraryPrograms,
});

export default connect(
    mapStateToProps,
    {
        getList: libraryProgramsList.trigger,
        remove: libraryProgramsRemove.trigger,
        resetRemove: libraryProgramsRemove.reset,
        update: libraryProgramsUpdate.trigger,
        create: libraryProgramsCreate.trigger,
        resetCreate: libraryProgramsCreate.reset,
    },
)(LibraryProgramsList);
