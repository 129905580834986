import { User } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';

export interface AdministratorDetailsPayload {
    id: string;
}

export interface AdministratorUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type AdministratorListPayload = SearchPaginationQuery;

export const details = (payload: AdministratorDetailsPayload) => api.get(`/users/${payload.id}`);

export const list = (payload: AdministratorListPayload = {}) => {
    return api.get(`/users`, {params: cleanPayload(payload)});
};

export const create = (payload: User) => {
    const params = (payload.roleSlug) ? {
        ...payload,
        role: payload.roleSlug,
    } : payload;

    return api.post(`/users`, params);
};

export const update = (payload: User) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/users/${id}`, payload);
};

export const remove = (payload: User) => {
    return api.delete(`/users/${payload.id}`);
};

export const sendWelcome = (payload: User) => {
    return api.post(`/users/${payload.id}/sendWelcome`);
};

export const roles = (payload: AdministratorListPayload = {}) => {
    return api.get(`/roles`, {
        params: cleanPayload(payload),
        cache: {
            maxAge: 24 * 60 * 60 * 1000, // Cache the result for 24h
            exclude: { query: false },
        },
    });
};
