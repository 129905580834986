import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Select, Divider, InputNumber, Switch, Typography } from 'antd';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import { Organization, ProgramType, SupportedLanguage, BadgeType } from '../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import ReactQuill from 'react-quill';

import { translateProgramType, translateLanguage, translateBadgeType } from '../helpers/i18n';
import { constants } from '../helpers';

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface ProgramFormProps {
    loading?: boolean;
    initialValues?: any;
    organizationId?: Organization['id'];
    onSubmit: (payload: any) => void;
}

const ProgramForm: FC<ProgramFormProps> = ({
    loading,
    initialValues,
    organizationId,
    onSubmit,
}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            setTimeout(() => {
                form.setFieldsValue({
                    badges: {
                        [BadgeType.cv]: true,
                        [BadgeType.linkedin]: true,
                        [BadgeType.professionalProject]: true,
                    },
                    ...initialValues,
                });
            }, 200);
        } else {
            form.resetFields();
        }
    }, [initialValues, form]);

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            onSubmit(values);
        });
    };

    return (
        <Form
            form={form}
            onFinish={onFormFinish}
            initialValues={{
                badges: {
                    [BadgeType.cv]: true,
                    [BadgeType.linkedin]: true,
                    [BadgeType.professionalProject]: true,
                },
                shouldDisplayJobOffers: true,
                ...initialValues,
            }}
            layout="vertical"
            hideRequiredMark
            scrollToFirstError
        >

            <Form.Item
                label="Type"
                name="type"
                rules={[requiredRule]}
            >
                <Select size="large" placeholder="Sélectionner un type">
                    <Select.Option value={ProgramType.basic}>{translateProgramType(ProgramType.basic)}</Select.Option>
                    <Select.Option value={ProgramType.extension}>{translateProgramType(ProgramType.extension)}</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Titre"
                name={['name', 'en']}
                rules={[requiredRule]}
            >
                <Input size="large" placeholder="Saisir un titre" />
            </Form.Item>

            <Form.Item
                label="Code"
                name={['code']}
                rules={[requiredRule]}
            >
                <Input size="large" placeholder="Saisir un code" />
            </Form.Item>

            <Form.Item
                label="Langue"
                name="language"
                rules={[requiredRule]}
            >
                <Select size="large" placeholder="Sélectionner une langue">
                    <Select.Option value={SupportedLanguage.fr}>{translateLanguage(SupportedLanguage.fr)}</Select.Option>
                    <Select.Option value={SupportedLanguage.en}>{translateLanguage(SupportedLanguage.en)}</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Description"
                name={['description', 'en']}
                rules={[requiredRule]}
                initialValue=""
            >
                <ReactQuill
                    placeholder="Saisir une description"
                    modules={constants.QUILL_TOOLBAR}
                    theme="snow"
                />
            </Form.Item>

            <Divider />
            <Form.Item
                label="Nombre de semaines autorisées pour la suspension"
                name={['maxSuspension']}
            >
                <InputNumber size="large" placeholder="Saisir un nombre de semaines" min={0} />
            </Form.Item>
            <Form.Item
                name={['suspensionAlert']}
                label="Activer l’envoi automatique de l’email de suspension vers le RH/Manager"
                className="switch-row"
                valuePropName="checked"
            >
                <Switch unCheckedChildren="X" />
            </Form.Item>
            <Divider />

            <Form.Item
                name={['shouldDisplayJobOffers']}
                label="Afficher la section « Annonces » dans le menu principal"
                className="switch-row"
                valuePropName="checked"
            >
                <Switch unCheckedChildren="X" />
            </Form.Item>

            <Typography.Title level={3}>Badges du programme</Typography.Title>
            <Typography.Paragraph>
                <p style={{ marginBottom: '2rem' }}>Choisir quels badges sont affichés sur la page d'accueil</p>
            </Typography.Paragraph>
            <Form.Item
                label={translateBadgeType(BadgeType.cv)}
                name={['badges', BadgeType.cv]}
                className="switch-row"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={translateBadgeType(BadgeType.linkedin)}
                name={['badges', BadgeType.linkedin]}
                className="switch-row"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={translateBadgeType(BadgeType.professionalProject)}
                name={['badges', BadgeType.professionalProject]}
                className="switch-row"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Divider />

            <br />
            <Form.Item className="cta-submit">
                <Button
                    type="primary"
                    size="large"
                    shape="round"
                    block
                    htmlType="submit"
                    loading={loading}
                >
                    {initialValues ? 'Enregistrer les modifications' : 'Créer le programme'}
                </Button>
            </Form.Item>

        </Form>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
    },
)(ProgramForm);
