import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Popconfirm,
    Badge,
    message,
    Tag,
} from 'antd';

import { MessageTrigger, Program, NotificationChannel } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import {
    NotificationsState,
    list as notificationsList,
    remove as notificationsRemove,
    update as notificationsUpdate,
    create as notificationsCreate,
} from '../../store/actions/notifications';

import { FilterQuery } from '../../store/api';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import useSessionStorage from '../../hooks/sessionStorage';
import { useHistory } from 'react-router-dom';
import NotificationModal from '../library/notifications/LibraryNotificationModal';
import ProgramNotificationDrawer from './ProgramNotificationDrawer';
import { translateNotificationChannel, i18n } from '../../helpers/i18n';

const rowKey = (item: MessageTrigger) => `${item.id}`;

interface ProgramNotificationsListProps {
    program: Program;
    notifications: NotificationsState;
    getList: typeof notificationsList.trigger;
    remove: typeof notificationsRemove.trigger;
    update: typeof notificationsUpdate.trigger;
    resetRemove: typeof notificationsRemove.reset;
    create: typeof notificationsCreate.trigger;
    resetCreate: typeof notificationsCreate.reset;
}

const ProgramNotificationsList: FC<ProgramNotificationsListProps> = ({
    program,
    notifications,
    getList,
    remove,
    update,
    resetRemove,
    create,
    resetCreate,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ isNotificationsModalVisible, setIsNotificationsModalVisible ] = useState(false);
    const [ selectedNotification, setSelectedNotification ] = useState<MessageTrigger | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('notifications_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {
        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
                program: program.id,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
                program: program.id,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (notifications.remove.success || notifications.create.success) {
            resetCreate();
            resetRemove();
            getList(lastSearchParams);
        }
    }, [notifications.remove.success, notifications.create.success, resetRemove, resetCreate, getList, lastSearchParams]);

    const onTableChange: TableProps<MessageTrigger>['onChange'] = (pagination, tableFilters, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.type && tableFilters.type.length > 0) {
            queryFilters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        const newSearchParams = {
          ...lastSearchParams,
          page: (pagination.current || 1) - 1,
          pageSize: pagination.pageSize || itemsPerPage,
          sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
          order: sortObj.order,
          filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const edit = (item: MessageTrigger) => {
        setSelectedNotification(item);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete notification

    const removeItem = (notification: MessageTrigger) => {
        remove({slug: notification.slug});
    };

    // ---------------------------------------
    // Notification library

    const useLibrary = () => {
        setIsNotificationsModalVisible(true);
    };

    const onNotificationsSelection = (selectedNotifications: any) => {
        setIsNotificationsModalVisible(false);
        if (selectedNotifications) {
            if (Array.isArray(selectedNotifications.notifications)) {
                selectedNotifications.notifications.forEach((slug: MessageTrigger['slug']) => {
                    create({
                        program: program.id,
                        fromTemplate: slug,
                    });
                });
            } else {
                create({
                    program: program.id,
                    fromTemplate: selectedNotifications.notifications,
                });
            }
        }
    };

    useEffect(() => {
        if (notifications.create.success && !!notifications.create.payload?.fromTemplate) {
            resetCreate();
            message.success('Les notifications ont bien été ajoutées');
        }
    }, [notifications.create.success, notifications.create.payload, resetCreate]);

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<MessageTrigger>> = [
        {
            dataIndex: 'name',
            title: 'Titre',
            sorter: true,
            render: (name) => i18n(name),
        },
        {
            dataIndex: 'channels',
            title: 'Types',
            render: (channels) => (
                channels.map((channel: NotificationChannel) => <Tag key={channel}>{translateNotificationChannel(channel)}</Tag>)
            ),
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 150,
            render: (text, record) => (
                <>
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<EditFilled />}
                        onClick={edit.bind(null, record)}
                    />
                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    return (
        <div id="program-weeks">
            <div className="page-header">
                <h1 className="page-title">
                    Notifications <Badge count={notifications.list.data.totalCount} showZero={true} />
                </h1>
                <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    onClick={useLibrary}
                >
                    Ajouter une notification
                </Button>
            </div>

            {notifications.list ? (
                <Table<MessageTrigger>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={notifications.list.loading}
                    dataSource={notifications.list.data.items}
                    pagination={{
                        total: notifications.list.data.totalCount,
                        current: notifications.list.data.page + 1,
                        pageSize: notifications.list.data.pageSize,
                        hideOnSinglePage: true,
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}

            <NotificationModal
                isVisible={isNotificationsModalVisible}
                onSuccess={onNotificationsSelection}
                onClose={setIsNotificationsModalVisible.bind(null, false)}
            />

            <ProgramNotificationDrawer
                notification={selectedNotification}
                language={program.language}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    notifications: state.notifications,
});

export default connect(
    mapStateToProps,
    {
        getList: notificationsList.trigger,
        remove: notificationsRemove.trigger,
        resetRemove: notificationsRemove.reset,
        update: notificationsUpdate.trigger,
        create: notificationsCreate.trigger,
        resetCreate: notificationsCreate.reset,
    },
)(ProgramNotificationsList);
