import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC, useState, forwardRef, useEffect } from 'react';
import { SketchPicker, ColorChangeHandler, ColorResult } from 'react-color';

import '../assets/styles/ColorPicker.less';

interface ColorPickerProps {
    onChange?: (value: any) => void;
    value ?: string;
    initialValue ?: string;
    setValue ?: any;
    defaultColor: string;
}

const ColorPicker: FC<ColorPickerProps> = forwardRef((props, ref: any) => {
    const { onChange, defaultColor } = props;
    const [ displayColorPicker, setDisplayColorPicker ] = useState<boolean>(false);
    const [ value, setValue ] = useState<string>(defaultColor);
    const [ initialValue, setInitialValue ] = useState<string>();

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
            setInitialValue(props.initialValue);
            if (onChange) { onChange(props.initialValue); }
        } else {
            setValue(defaultColor);
            setInitialValue(defaultColor);
        }
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
            setInitialValue(props.value);
            if (onChange) { onChange(props.value); }
        }
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const onColorChange: ColorChangeHandler = (newColor: ColorResult) => {
        setValue(newColor.hex);
        if (onChange) {onChange(newColor.hex); }
    };

    const onReset = () => {
        setValue(defaultColor);
        if (onChange) {onChange(defaultColor); }
    };

    return (
        <div className="colorpicker">
            <button type="button" onClick={toggle} className="btn-color">
                <span>
                    <span
                        style={{
                            backgroundColor: value,
                        }}
                    />
                </span>
                <label>{value}</label>
            </button>
            {initialValue !== defaultColor && value !== defaultColor && (
                <Button onClick={onReset} size="small" shape="circle">
                    <CloseOutlined />
                </Button>
            )}
            { displayColorPicker && (
                <div className="colorpicker-popover">
                    <div className="cover" onClick={toggle}/>
                    <SketchPicker
                        color={initialValue || value}
                        onChange={onColorChange}
                    />
                </div>
            )}
        </div>
    );

});

export default ColorPicker;
