import React, { FC, useState, forwardRef, useEffect, useRef } from 'react';
import { Select, Spin } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import {
    NotificationsState,
    search as searchNotifications,
} from '../../../store/actions/notifications';
import { SelectProps } from 'antd/lib/select';
import { MessageTrigger } from '../../../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { NotificationListPayload } from '../../../store/api/notifications';
import { i18n } from '../../../helpers/i18n';

interface NotificationFilterSelectProps {
    onChange?: (value: any) => void;
    multi?: boolean;
    notifications: NotificationsState;
    search: typeof searchNotifications.trigger;
    size?: SizeType;
    value ?: string[];
    setValue ?: any;
    initialValue ?: MessageTrigger[];
    filters ?: NotificationListPayload;
}

const NotificationFilterSelect: FC<NotificationFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, notifications, search, multi, size, filters} = props;
    const [ value, setValue ] = useState<any>();
    const [ initialValue, setInitialValue ] = useState<MessageTrigger[]>([]);
    const selectRef = useRef<any>();

    useEffect(() => {
        if (props.initialValue && props.initialValue.length > 0) {
            if (multi) {
                setValue(props.initialValue.map((a) => a.slug));
                setInitialValue(props.initialValue);
                if (onChange) { onChange(props.initialValue.map((a) => a.slug || a)); }
            } else {
                setValue(props.initialValue[0].slug);
                setInitialValue(props.initialValue);
                if (onChange) { onChange(props.initialValue[0].slug); }
            }
        } else {
            setValue([]);
            setInitialValue([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFocus: SelectProps<MessageTrigger['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 25,
            ...filters,
        });
    };

    const onSearch: SelectProps<MessageTrigger['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 25,
            ...filters,
            throttling: 250,
        });
    };

    const onChangeSelect: SelectProps<MessageTrigger['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (selectRef?.current) {
            selectRef.current.blur();
        }
        if (onChange) {onChange(newValue); }
    };

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            autoClearSearchValue={true}
            size={size}
            ref={selectRef}
            showSearch
            value={value}
            placeholder={(multi) ? 'Sélectionner dans la bibliothèque' : 'Sélectionner une notification'}
            notFoundContent={notifications.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
        >
            {
                [
                    ...initialValue, // Display initial value
                    ...notifications.search.data.items.filter((a) => !initialValue.map((b) => b.slug).includes(a.slug)), // Search items, excluding initial value
                ].map((notification) => (
                <Select.Option value={notification.slug} key={notification.slug}>{i18n(notification.name)}</Select.Option>
            ))}
        </Select>
    );

});

NotificationFilterSelect.defaultProps = {
    multi: false,
    size: 'small',
};

const mapStateToProps = (state: MainReducerState) => ({
    notifications: state.notifications,
});

export default connect(
    mapStateToProps,
    {
        search: searchNotifications.trigger,
    },
    null,
    { forwardRef: true },
)(NotificationFilterSelect);
