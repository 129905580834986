import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
        <g id="3-Site_web" fill="none">
            <g id="3-Site_web-Pages" transform="translate(-16.000000, -200.000000)" fill="currentColor">
                <g id="nav_items" transform="translate(0.000000, 128.000000)">
                    <g id="Item-3" transform="translate(0.000000, 56.000000)">
                        <g id="Group" transform="translate(16.000000, 13.000000)">
                            <g id="icon" transform="translate(0.000000, 3.000000)">
                                <path d="M14.7584975,0 L12.3652429,0 L12.3652429,2.25650097 C12.3652429,2.71832066 12.0207174,3.09381449 11.5972327,3.09381449 L4.40250414,3.09381449 C3.9790194,3.09381449 3.63475711,2.71832066 3.63475711,2.25650097 L3.63475711,0 L1.24097609,0 C0.556662656,0 0,0.499086031 0,1.11232974 L0,14.8879061 C0,15.5011498 0.556662656,16 1.24097609,16 L14.7584975,16 C15.4433373,16 16,15.5011498 16,14.8879061 L16,1.11232974 C16,0.499086031 15.4428109,0 14.7584975,0 Z M12.9111103,12.5 L3.08524519,12.5 C2.57049199,12.5 2.15384615,12.1645 2.15384615,11.75 C2.15384615,11.336 2.57049199,11 3.08524519,11 C5.07551182,11 6.40154512,11 7.0633451,11 C7.30406302,11 9.25352506,11 12.9117312,11 C13.4258635,11 13.8431303,11.336 13.8431303,11.75 C13.8425093,12.1645 13.4258635,12.5 12.9111103,12.5 Z M12.9117312,6.5 C13.4258635,6.5 13.8431303,6.836 13.8431303,7.25 C13.8425093,7.6645 13.4258635,8 12.9111103,8 L3.08524519,8 C2.57049199,8 2.15384615,7.6645 2.15384615,7.25 C2.15384615,6.836 2.57049199,6.5 3.08524519,6.5 L12.9117312,6.5 L12.9117312,6.5 Z M11.5756505,0 L11.5756505,2.23291468 L4.42434953,2.23291468 L4.42434953,0 L11.5756505,0 Z" id="programme"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const IconPrograms: FC<IconComponentProps> = ({ ...props }) => (
    // @ts-ignore
    <Icon
        component={svg}
        className={`icon-programs`}
        {...props}
    />
);

export default IconPrograms;
