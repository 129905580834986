import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';

import {
    ProgramTaskDetailsPayload,
    details as detailsApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/programTasks';
import { RequestState, MainReducerState } from '../reducers';
import { ProgramTask } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import {
    details as programDetails,
} from './programs';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface ProgramTasksState {
    details: RequestState<ProgramTask | undefined>;
    create: RequestState<ProgramTask | undefined>;
    update: RequestState<ProgramTask | undefined>;
    remove: RequestState<ProgramTask | undefined>;
}

const initialState: ProgramTasksState = {
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const details = new EzeeAsyncAction<
    ProgramTasksState['details'],
    ProgramTaskDetailsPayload,
    ProgramTask
>('programTasks/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    ProgramTasksState['create'],
    ProgramTask,
    ProgramTask
>('programTasks/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        ...state,
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    ProgramTasksState['update'],
    ProgramTask,
    ProgramTask
>('programTasks/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        ...state,
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    ProgramTasksState['remove'],
    ProgramTask,
    ProgramTask
>('programTasks/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const programTasksReducer = combineReducers<ProgramTasksState>({
    details: details.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
});

// Saga

export function* programTasksSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, removeSaga);
}

function* removeSaga(actionData: DataAction<any>) {
    try {
        const response = yield call(removeApiCall, actionData.payload);
        yield put(programDetails.actions.removeTask(actionData.payload));
        return yield put(remove.actions.success(response));
    } catch (error) {
        yield put(remove.actions.failure(error));
    }
}

// Store helpers

export const getProgramTasksState = (state: MainReducerState) => state.programTasks;
