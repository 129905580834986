import React, { FC, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { RouteChildrenProps } from 'react-router-dom';
import { Button, Descriptions, PageHeader, Switch, Dropdown, Menu, Divider, Popconfirm, Spin } from 'antd';
import { Organization } from '../../store/api/apiTypes';

import {
    OrganizationsState,
    details as organizationDetails,
    update as organizationUpdate,
    remove as organizationsRemove,
} from '../../store/actions/organizations';
import OrganizationDrawer from './OrganizationDrawer';
import { CheckOutlined, CloseOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import Seo from '../../components/Seo';
import Title from 'antd/lib/typography/Title';
import CardSkeleton from '../../components/CardSkeleton';
import OrganizationThemeDrawer from './OrganizationThemeDrawer';
import PacksList from './packs/PacksList';
import AdministratorsList from './administrators/AdministratorsList';
import ProgramsList from './programs/ProgramsList';
import { translateBusinessType, translateCompanySize } from '../../helpers/i18n';
import OrganizationManualNotificationDrawer from './OrganizationManualNotificationDrawer';

interface MatchParams {
    id: string;
}

interface OrganizationProps extends RouteChildrenProps<MatchParams> {
    organizations: OrganizationsState;
    getDetails: typeof organizationDetails.trigger;
    resetDetails: typeof organizationDetails.reset;
    update: typeof organizationUpdate.trigger;
    resetUpdate: typeof organizationUpdate.reset;
    remove: typeof organizationsRemove.trigger;
}

const OrganizationDetails: FC<OrganizationProps> = ({
    match,
    organizations,
    getDetails,
    resetDetails,
    update,
    resetUpdate,
    remove,
}) => {

    const organization: Organization | undefined = organizations.details.data;
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ isNotificationDrawerVisible, setIsNotificationDrawerVisible ] = useState(false);
    const [ isThemeDrawerVisible, setIsThemeDrawerVisible ] = useState(false);
    const [ moreMenuVisible, setMoreMenuVisible ] = useState(false);
    const [ currentTab, setCurrentTab ] = useState(1);

    useEffect(() => {
        setIsLoaded(false);
        resetDetails();
        getOrganizationDetails();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getOrganizationDetails = useCallback(() => {
        if (match && match.params.id) {
            getDetails({id: match.params.id});
        } else {
            resetDetails();
        }
    }, [match, resetDetails, getDetails]);

    useEffect(() => {
        if (organizations.details.success && organizations.details.data) {
            setIsLoaded(true);
        }
    }, [organizations.details.success, organizations.details.data, setIsLoaded]);

    useEffect(() => {
        if (organizations.update.success) {
            resetUpdate();
            getOrganizationDetails();
        }
    }, [organizations.update.success, resetUpdate, getOrganizationDetails]);

    const edit = () => {
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
        getOrganizationDetails();
    };

    const editTheme = () => {
        setIsThemeDrawerVisible(true);
        setMoreMenuVisible(false);
    };

    const showNotifDrawer = () => {
        setIsNotificationDrawerVisible(true);
        setMoreMenuVisible(false);
    };

    const onThemeDrawserClose = () => {
        setIsThemeDrawerVisible(false);
    };

    const back = () => {
        window.history.back();
    };

    // ---------------------------------------
    // Disable / Enable / Delete organization

    const onActiveSwitchChange = (active: boolean) => {
        if (organization) {
            organization.enabled = active;
            update({
                id: organization.id,
                enabled: active,
            });
        }
    };

    const removeItem = () => {
        remove({id: organization?.id});
    };

    // ---------------------------------------
    // Tabs

    const switchTab = (tab: number) => {
        setCurrentTab(tab);
    };

    // ---------------------------------------
    // More actions

    const moreMenuVisibleChange = (isVisible: boolean) => {
        setMoreMenuVisible(isVisible);
    };

    const actionsMenu = (
        <Menu>
            <Menu.Item onClick={showNotifDrawer}>
                Envoyer un message
            </Menu.Item>
            <Menu.Item onClick={editTheme}>
                Modifier le thème
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Confirmez-vous la suppression ?"
                    onConfirm={removeItem}
                    okText="Confirmer"
                    cancelText="Annuler"
                    placement="topRight"
                >
                    <div>Supprimer</div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Seo title={organization?.name} />
            {(organization && isLoaded) ? (
                <>
                    <div className="page-header-container">

                        <PageHeader
                            ghost={false}
                            onBack={back}
                            title={organization?.name}
                            extra={[
                                (
                                    <div className="switch-container" key="org-switch">
                                        <label>Client actif</label>
                                        <Switch
                                            checked={organization.enabled}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            onChange={onActiveSwitchChange}
                                        />
                                    </div>
                                ),
                                (
                                    <Button
                                        key="org-edit"
                                        shape="round"
                                        type="primary"
                                        onClick={edit}
                                    >
                                        Editer
                                    </Button>
                                ),
                                (
                                    <Dropdown
                                        overlay={actionsMenu}
                                        placement="bottomRight"
                                        trigger={['click']}
                                        onVisibleChange={moreMenuVisibleChange}
                                        visible={moreMenuVisible}
                                        key="org-more"
                                    >
                                        <Button
                                            className="btn-more-actions"
                                            icon={<EllipsisOutlined />}
                                            type="link"
                                            size="large"
                                        />
                                    </Dropdown>
                                ),
                            ]}
                        >
                            <Descriptions size="default" column={3}>
                                <Descriptions.Item label="Nom de la société">{organization.name}</Descriptions.Item>
                                <Descriptions.Item label="Nom du groupe">{organization.group}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions size="default" column={3}>
                                <Descriptions.Item label="Raison sociale">{organization.companyName}</Descriptions.Item>
                                <Descriptions.Item label="SIRET">{organization.serialNumber}</Descriptions.Item>
                                <Descriptions.Item label="N° TVA intra.">{organization.VATSerialNumber}</Descriptions.Item>
                                <Descriptions.Item label="Taille de l'entreprise">{translateCompanySize(organization.companySize)}</Descriptions.Item>
                                <Descriptions.Item label="Activité principale">{translateBusinessType(organization.businessType)}</Descriptions.Item>
                            </Descriptions>

                            <Divider />

                            <Title level={3}>Adresses</Title>
                            <Descriptions size="default" column={2}>
                                <Descriptions.Item label="Site">{organization.address?.street}</Descriptions.Item>
                                <Descriptions.Item label="Facturation">{organization.billingAddress?.street}</Descriptions.Item>
                            </Descriptions>

                            <Divider />

                            <div className="custom-tabs-container">
                                <Button type="link" onClick={switchTab.bind(null, 1)} className={(currentTab === 1) ? 'active' : ''}>Packs</Button>
                                <Button type="link" onClick={switchTab.bind(null, 2)} className={(currentTab === 2) ? 'active' : ''}>Programmes</Button>
                                <Button type="link" onClick={switchTab.bind(null, 3)} className={(currentTab === 3) ? 'active' : ''}>Administrateurs</Button>
                            </div>
                        </PageHeader>
                    </div>

                    {(currentTab === 1) && (
                        <PacksList organization={organization} />
                    )}

                    {(currentTab === 2) && (
                        <div className="custom-tab-content">
                            <ProgramsList organization={organization} />
                        </div>
                    )}

                    {(currentTab === 3) && (
                        <AdministratorsList organization={organization} />
                    )}

                    <OrganizationDrawer
                        id={organization.id}
                        isVisible={isDrawerVisible}
                        onClose={onDrawserClose}
                        onSuccess={onDrawserClose}
                    />

                    <OrganizationThemeDrawer
                        id={organization.id}
                        isVisible={isThemeDrawerVisible}
                        onClose={onThemeDrawserClose}
                        onSuccess={onThemeDrawserClose}
                    />

                    <OrganizationManualNotificationDrawer
                        id={organization.id}
                        isVisible={isNotificationDrawerVisible}
                        onClose={setIsNotificationDrawerVisible.bind(null, false)}
                    />
                </>
            ) : (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}>
                    <CardSkeleton />
                </Spin>
            )}
        </>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    organizations: state.organizations,
});

export default connect(
    mapStateToProps,
    {
        getDetails: organizationDetails.trigger,
        resetDetails: organizationDetails.reset,
        update: organizationUpdate.trigger,
        resetUpdate: organizationUpdate.reset,
        remove: organizationsRemove.trigger,
    },
)(OrganizationDetails);
