import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    LibraryProgramTaskDetailsPayload,
    LibraryProgramTaskListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/libraryProgramTasks';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, LibraryProgramTask } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface LibraryProgramTasksState {
    list: RequestState<ListResponse<LibraryProgramTask>>;
    search: RequestState<ListResponse<LibraryProgramTask>>;
    details: RequestState<LibraryProgramTask | undefined>;
    create: RequestState<LibraryProgramTask | undefined>;
    update: RequestState<LibraryProgramTask | undefined>;
    remove: RequestState<LibraryProgramTask | undefined>;
}

const initialState: LibraryProgramTasksState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    LibraryProgramTasksState['list'],
    LibraryProgramTaskListPayload,
    ListResponse<LibraryProgramTask>
>('libraryProgramTasks/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const search = new EzeeAsyncAction<
    LibraryProgramTasksState['search'],
    LibraryProgramTaskListPayload,
    ListResponse<LibraryProgramTask>
>('libraryProgramTasks/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    LibraryProgramTasksState['details'],
    LibraryProgramTaskDetailsPayload,
    LibraryProgramTask
>('libraryProgramTasks/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    LibraryProgramTasksState['create'],
    LibraryProgramTask,
    LibraryProgramTask
>('libraryProgramTasks/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        ...state,
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    LibraryProgramTasksState['update'],
    LibraryProgramTask,
    LibraryProgramTask
>('libraryProgramTasks/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        ...state,
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    LibraryProgramTasksState['remove'],
    LibraryProgramTask,
    LibraryProgramTask
>('libraryProgramTasks/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const libraryProgramTasksReducer = combineReducers<LibraryProgramTasksState>({
    details: details.reducer,
    list: list.reducer,
    search: search.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
});

// Saga

export function* libraryProgramTasksSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
}

// Store helpers

export const getLibraryProgramTasksState = (state: MainReducerState) => state.libraryProgramTasks;
