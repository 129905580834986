import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 12 16" version="1.1">
        <g id="1-Clients">
            <g id="1.3-Clients-Detail-Packs" transform="translate(-546.000000, -615.000000)" fill="currentColor">
                <g id="Group-2-Copy" transform="translate(528.000000, 473.000000)">
                    <g id="icon/date-copy" transform="translate(16.000000, 142.000000)">
                        <path d="M9.80755278,7.16065509 C11.156071,7.16065509 12.3579146,9.33921749 13.1253331,11.6384271 L13.2830258,12.1322116 L13.2830258,12.1322116 L13.4267905,12.6251895 C13.4495556,12.7070353 13.4717142,12.78866 13.4932532,12.8699698 L13.6149463,13.3533008 C13.690978,13.6720027 13.7566757,13.9826673 13.8112006,14.2792947 L13.8845034,14.712958 C13.9492169,15.1345936 13.9878476,15.5178959 13.9975643,15.8426157 L14,16 L2,16 C2,15.6368723 2.04175549,15.1857075 2.12047073,14.6808034 L2.19688044,14.2356678 C2.23949913,14.0073196 2.28865467,13.7709009 2.34396955,13.5291114 L2.46269993,13.0391713 L2.46269993,13.0391713 L2.5971841,12.5397089 C3.30787091,10.0265908 4.5841579,7.33637537 6.04853749,7.16889768 L6.19244722,7.16065509 L9.80755278,7.16065509 Z M7.99988106,0 C9.47556346,0 10.676063,1.21159266 10.676063,2.70091094 C10.676063,4.19022923 9.47556346,5.40182189 7.99988106,5.40182189 C6.52419865,5.40182189 5.32369908,4.19022923 5.32369908,2.70091094 C5.32369908,1.21159266 6.52419865,0 7.99988106,0 Z" id="add_contact"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const IconUser: FC<IconComponentProps> = ({ ...props }) => (
    // @ts-ignore
    <Icon
        component={svg}
        className={`icon-user`}
        {...props}
    />
);

export default IconUser;
