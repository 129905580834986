import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
        <g id="2-Bibliotheque" stroke="none">
            <g id="2.9-Bibliotheque-Notifications" transform="translate(-45.000000, -392.000000)" fill="currentColor">
                <g id="Group-4" transform="translate(0.000000, 232.000000)">
                    <g id="nav-/-nav_item-/-selected" transform="translate(0.000000, 144.000000)">
                        <g id="Group" transform="translate(45.000000, 13.000000)">
                            <g id="icon" transform="translate(0.000000, 3.000000)">
                                <path d="M15.3580802,0 C15.2820897,0 15.2040995,0.0139982502 15.1261092,0.0459942507 L3.59955006,4.67741532 L0.319960005,4.67741532 C0.143982002,4.67741532 0,4.82539683 0,5.00937383 L0,10.9886264 C0,11.1726034 0.143982002,11.3205849 0.319960005,11.3205849 L2.35170604,11.3205849 C2.27771529,11.5525559 2.23972003,11.7985252 2.23972003,12.0484939 C2.23972003,13.3663292 3.31558555,14.4381952 4.63942007,14.4381952 C5.74728159,14.4381952 6.68116485,13.6862892 6.95713036,12.6704162 L15.128109,15.9540057 C15.2060992,15.984002 15.2840895,16 15.36008,16 C15.6980377,16 16,15.7160355 16,15.336083 L16,0.66391701 C15.9980002,0.283964504 15.6980377,0 15.3580802,0 Z M4.63942007,13.0043745 C4.10948631,13.0043745 3.67954006,12.5764279 3.67954006,12.0484939 C3.67954006,11.8245219 3.75753031,11.6105487 3.89951256,11.4405699 L5.59730034,12.1224847 C5.55730534,12.6144232 5.14335708,13.0043745 4.63942007,13.0043745 L4.63942007,13.0043745 Z" id="Shape"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const IconSpeaker: FC<IconComponentProps> = ({ ...props }) => (
    // @ts-ignore
    <Icon
        component={svg}
        className={`icon-speaker`}
        {...props}
    />
);

export default IconSpeaker;
