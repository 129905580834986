import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { Collapse, Button, Badge, Menu, Tooltip, Popconfirm } from 'antd';
import { ProgramWeek, ProgramTask, DndType } from '../../store/api/apiTypes';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import '../../assets/styles/pages/Program.less';
import { DeleteFilled, PlusOutlined, MenuOutlined } from '@ant-design/icons';
import ProgramWeekTaskItem from './ProgramWeekTaskItem';
import { ProgramWeeksState } from '../../store/actions/programWeeks';
import { ProgramTasksState } from '../../store/actions/programTasks';

const { Panel } = Collapse;

interface ProgramWeeksProps {
    week: ProgramWeek;
    index: number;
    handle: any;

    onRemove: (week: ProgramWeek) => void;
    onTaskCreate: (week: ProgramWeek) => void;
    onTaskEdit: (week: ProgramWeek, task: ProgramTask) => void;
    onTaskSaveAs: (week: ProgramWeek, task: ProgramTask) => void;
    onUseTaskLibrary: (week: ProgramWeek) => void;

    programWeeks: ProgramWeeksState;
    programTasks: ProgramTasksState;
}

const ProgramWeekItem: FC<ProgramWeeksProps> = ({
    week,
    index,
    handle,

    onRemove,
    onTaskCreate,
    onTaskEdit,
    onTaskSaveAs,
    onUseTaskLibrary,

    programWeeks,
    programTasks,
}) => {

    const [isActionTooltipVisible, setIsActionTooltipVisible] = useState(false);

    // ---------------------------------------
    // Add new action

    const onActionTooltipVisibleChange = (visible: boolean) => {
        setIsActionTooltipVisible(visible);
    };

    const add = () => {
        onTaskCreate(week);
        setIsActionTooltipVisible(false);
    };

    const useLibrary = () => {
        onUseTaskLibrary(week);
        setIsActionTooltipVisible(false);
    };

    const edit = (task: ProgramTask) => {
        onTaskEdit(week, task);
    };

    const saveAs = (task: ProgramTask) => {
        onTaskSaveAs(week, task);
    };

    const clickStopPropagation = (e: any) => {
        e.stopPropagation();
    };

    const remove = (e: any) => {
        e.stopPropagation();
        onRemove(week);
    };

    // ---------------------------------------
    // Render

    const actionsMenu = (
        <Menu selectable={false}>
            <Menu.Item onClick={add}>
                Créer une nouvelle action
            </Menu.Item>
            <Menu.Item onClick={useLibrary}>
                Utiliser une action existante
            </Menu.Item>
        </Menu>
    );

    return week ? (
        <Collapse className="program-week" expandIconPosition="right">
            <Panel
                header={(
                    <span className="title">
                        {handle}
                        <span>{'Semaine ' + (index + 1)}</span>
                        <span className="points">{week?.points} pts</span>
                    </span>
                )}
                key="1"
                extra={(
                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={remove}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            loading={programWeeks.remove.loading && programWeeks.remove.payload.id === week.id}
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                            size="small"
                            onClick={clickStopPropagation}
                        />
                    </Popconfirm>
                )}
            >
                <div className="page-header">
                    <h2 className="page-title">
                        Actions <Badge count={week?.tasks.length} showZero={true} />
                    </h2>
                </div>

                <Droppable droppableId={week.id} type={DndType.task}>
                    {(subProvided: any) => (
                        <div className="program-task-table" ref={subProvided.innerRef} {...subProvided.droppableProps}>
                            {(week.tasks?.length > 0) ? (
                                <div className="program-task-table-head">
                                    <div className="cell-handle"/>
                                    <div className="cell-name">Nom</div>
                                    <div className="cell-family">Famille</div>
                                    <div className="cell-points">Points</div>
                                    <div className="cell-actions">Actions</div>
                                </div>
                            ) : (
                                <div className="empty-dropbox"/>
                            )}
                            {week?.tasks.map((task: ProgramTask, taskIndex: number) => (
                                <div className="program-task-table-row" key={'task' + task.id}>
                                    <Draggable draggableId={task.id} index={taskIndex}>
                                        {(subDragProvided: any, snapshot: any) => (
                                            <div className={'program-task-draggable ' + ((snapshot.isDragging) ? 'dragging' : '')} ref={subDragProvided.innerRef} {...subDragProvided.draggableProps}>
                                                <ProgramWeekTaskItem
                                                    task={task}
                                                    onEdit={edit}
                                                    onSaveAs={saveAs}
                                                    handle={(
                                                        <MenuOutlined className="handle" {...subDragProvided.dragHandleProps} />
                                                    )}
                                                />
                                                {subDragProvided.placeholder}
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}
                            {subProvided.placeholder}
                        </div>
                    )}
                </Droppable>

                <Tooltip
                    overlay={actionsMenu}
                    placement="bottom"
                    trigger={['click']}
                    visible={isActionTooltipVisible}
                    onVisibleChange={onActionTooltipVisibleChange}
                >
                    <Button
                        loading={programTasks.create.loading}
                        type="text"
                        className="add-new"
                    >
                        <PlusOutlined />
                        Ajouter une action
                    </Button>
                </Tooltip>
            </Panel>
        </Collapse>
    ) : <></>;

};

const mapStateToProps = (state: MainReducerState) => ({
    programWeeks: state.programWeeks,
    programTasks: state.programTasks,
});

export default connect(
    mapStateToProps,
    {
    },
)(ProgramWeekItem);
