import { Question } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface QuestionDetailsPayload {
    id: string;
}

export type QuestionListPayload = SearchPaginationQuery;

export const details = (payload: QuestionDetailsPayload) => api.get(`/questions/${payload.id}`);

export const list = (payload: QuestionListPayload = {}) => {
    return api.get(`/questions`, {params: cleanPayload(payload)});
};

export const create = (payload: Question) => {
    return api.post(`/questions`, payload);
};

export const update = (payload: Question) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/questions/${id}`, payload);
};

export const remove = (payload: Question) => {
    return api.delete(`/questions/${payload.id}`);
};
