import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { User, Organization, RoleSlug } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import {
    AdministratorsState,
    list as administratorsList,
    remove as administratorsRemove,
    roles as administratorRoles,
    sendWelcome as administratorsSendWelcome,
} from '../../../store/actions/administrators';

import { PlusOutlined } from '@ant-design/icons';
import AdministratorDrawer from './AdministratorDrawer';
import AdministratorItem from './AdministratorItem';
import { Spin, message } from 'antd';

interface AdministratorsListProps {
    organization: Organization;
    administrators: AdministratorsState;
    getList: typeof administratorsList.trigger;
    resetRemove: typeof administratorsRemove.reset;
    getRoles: typeof administratorRoles.trigger;
    resetSendWelcome: typeof administratorsSendWelcome.reset;
}

const AdministratorsList: FC<AdministratorsListProps> = ({
    organization,
    administrators,
    getList,
    resetRemove,
    getRoles,
    resetSendWelcome,
}) => {

    const itemsPerPage: number = 50;
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useState({});

    useEffect(() => {
        getRoles();

        setLastSearchParams({
            page: 0,
            pageSize: itemsPerPage,
            organization: organization.id,
            role: [
                RoleSlug.admin,
                RoleSlug.owner,
            ],
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (administrators.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }
    }, [administrators.remove.success, resetRemove, getList, lastSearchParams]);

    // ---------------------------------------
    // Send welcome succes

    useEffect(() => {
        if (administrators.sendWelcome.success) {
            resetSendWelcome();
            message.success('Les accès ont bien été envoyés à l\'administrateur');
        }
    }, [administrators.sendWelcome.success, resetSendWelcome]);

    // ---------------------------------------
    // Drawer management

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const edit = (administrator: User) => {
        setSelectedId(administrator.id);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete administrator

    // ---------------------------------------
    // Render

    return (
        <Spin spinning={administrators.list.loading}>
            <div className="custom-tab-content card-grid">
                <button
                    className="add-new"
                    onClick={add}
                >
                    <PlusOutlined />
                    Ajouter un administrateur
                </button>

                {administrators.list.data.items.map((administrator, i) => (
                    <AdministratorItem
                        key={'administrator' + administrator.id}
                        administrator={administrator}
                        organization={organization}
                        onEdit={edit}
                    />
                ))}
            </div>

            <AdministratorDrawer
                id={selectedId}
                organizationId={organization.id}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
        </Spin>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    administrators: state.administrators,
});

export default connect(
    mapStateToProps,
    {
        getList: administratorsList.trigger,
        resetRemove: administratorsRemove.reset,
        getRoles: administratorRoles.trigger,
        resetSendWelcome: administratorsSendWelcome.reset,
    },
)(AdministratorsList);
