import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
        <g id="2-Bibliotheque" fill="none">
            <g id="2.3-Documents-Liste" transform="translate(-45.000000, -344.000000)" fill="currentColor">
                <g id="Group-4" transform="translate(0.000000, 232.000000)">
                    <g id="nav-/-nav_item-/-selected-copy" transform="translate(0.000000, 96.000000)">
                        <g id="Group" transform="translate(45.000000, 13.000000)">
                            <g id="icon" transform="translate(0.000000, 3.000000)">
                                <path d="M4.65797562,10.6493843 C4.22723429,10.6493843 3.87764712,10.3014906 3.87764712,9.87167855 C3.87764712,9.44186649 4.22723429,9.09397278 4.65797562,9.09397278 L10.4863412,9.09397278 C10.9170825,9.09397278 11.2666697,9.44186649 11.2666697,9.87167855 C11.2666697,10.3014906 10.9170825,10.6493843 10.4863412,10.6493843 L4.65797562,10.6493843 Z M6.21988269,5.35061568 C6.65062402,5.35061568 7.0002112,5.69902787 7.0002112,6.12832145 C7.0002112,6.55813351 6.65062402,6.90602722 6.21988269,6.90602722 L4.65797562,6.90602722 C4.22723429,6.90602722 3.87764712,6.55813351 3.87764712,6.12832145 C3.87764712,5.69902787 4.22723429,5.35061568 4.65797562,5.35061568 L6.21988269,5.35061568 Z M10.6001267,1.25418017 C9.79014572,0.447958522 8.7138126,0.00259235256 7.56933079,0 L3.18055365,0 C1.41076859,0 0,1.43512638 0,3.19896306 L0,12.8010369 C0,14.5648736 1.41076859,16 3.18055365,16 L12.7902487,16 C14.5600338,16 16,14.5648736 16,12.8010369 L16,8.40285159 C15.9979191,7.27154893 15.534404,6.1661698 14.7306656,5.3713545 L10.6001267,1.25418017 Z" id="documents"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const IconDocument: FC<IconComponentProps> = ({ ...props }) => (
    // @ts-ignore
    <Icon
        component={svg}
        className={`icon-document`}
        {...props}
    />
);

export default IconDocument;
