import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
} from 'antd';

import { WebsitePage } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import WebsitePageDrawer from './WebsitePagesDrawer';
import {
    WebsitePagesState,
    list as websitePagesList,
} from '../../../store/actions/websitePages';

import { FilterQuery } from '../../../store/api';
import { EditFilled } from '@ant-design/icons';
import Seo from '../../../components/Seo';
import useSessionStorage from '../../../hooks/sessionStorage';
import { i18n } from '../../../helpers/i18n';

const rowKey = (item: WebsitePage) => `${item.id}`;

interface WebsitePagesListProps {
    websitePages: WebsitePagesState;
    getList: typeof websitePagesList.trigger;
}

const WebsitePagesList: FC<WebsitePagesListProps> = ({
    websitePages,
    getList,
}) => {

    const itemsPerPage: number = 20;
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('websitePages_lastSearch', {});

    useEffect(() => {
        setLastSearchParams({
            page: 0,
            pageSize: itemsPerPage,
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    const onTableChange: TableProps<WebsitePage>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const edit = (item: WebsitePage) => {
        setSelectedId(item.slug);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const columns: Array<ColumnProps<WebsitePage>> = [
        {
            dataIndex: 'title',
            title: 'Titre',
            sorter: true,
            defaultSortOrder: columnSortOrder('title'),
            render: (title) => {
                return i18n(title);
            },
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 120,
            render: (text, record) => (
                <Button
                    shape="circle"
                    type="ghost"
                    icon={<EditFilled />}
                    onClick={edit.bind(null, record)}
                />
            ),
        },

    ];

    return (
            <>
                <Seo title="Pages" />
                <div className="page-header-container">
                    <div className="page-header">
                        <h1 className="page-title">
                            Pages <Badge count={websitePages.list.data.items.length} overflowCount={100000} />
                        </h1>
                    </div>
                </div>

                {websitePages.list ? (
                    <Table<WebsitePage>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={websitePages.list.loading}
                        dataSource={websitePages.list.data.items}
                        pagination={{
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <WebsitePageDrawer
                    id={selectedId}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    websitePages: state.websitePages,
});

export default connect(
    mapStateToProps,
    {
        getList: websitePagesList.trigger,
    },
)(WebsitePagesList);
