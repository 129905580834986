import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { usersSaga } from '../actions/users';
import { userGroupsSaga } from '../actions/userGroups';
import { organizationsSaga } from '../actions/organizations';
import { packsSaga } from '../actions/packs';
import { programsSaga } from '../actions/programs';
import { administratorsSaga } from '../actions/administrators';
import { websitePagesSaga } from '../actions/websitePages';
import { programWeeksSaga } from '../actions/programWeeks';
import { programTasksSaga } from '../actions/programTasks';
import { questionsSaga } from '../actions/questions';
import { questionSectionsSaga } from '../actions/questionSections';
import { libraryDocumentsSaga } from '../actions/libraryDocuments';
import { libraryProgramsSaga } from '../actions/libraryPrograms';
import { libraryProgramTasksSaga } from '../actions/libraryProgramTasks';
import { notificationsSaga } from '../actions/notifications';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(usersSaga);
    yield fork(userGroupsSaga);
    yield fork(organizationsSaga);
    yield fork(packsSaga);
    yield fork(programsSaga);
    yield fork(administratorsSaga);
    yield fork(websitePagesSaga);
    yield fork(programWeeksSaga);
    yield fork(programTasksSaga);
    yield fork(questionsSaga);
    yield fork(questionSectionsSaga);
    yield fork(libraryDocumentsSaga);
    yield fork(libraryProgramsSaga);
    yield fork(libraryProgramTasksSaga);
    yield fork(notificationsSaga);
}
