import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../../store/reducers';
import Seo from '../../../components/Seo';
import {
    QuestionSectionsState,
    list as sectionsList,
    update as sectionUpdate,
    remove as sectionRemove,
    create as sectionCreate,
} from '../../../store/actions/questionSections';

import {
    QuestionsState,
    update as questionUpdate,
} from '../../../store/actions/questions';
import { Badge, Button, Modal } from 'antd';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, DragDropContextProps, Draggable } from 'react-beautiful-dnd';
import { DndType, QuestionSection, Question } from '../../../store/api/apiTypes';
import { reorder } from '../../../hooks';
import SectionItem from './SectionItem';
import SectionModal from './SectionModal';
import QuestionDrawer from './QuestionDrawer';

interface WebsiteFAQProps {
    questionSections: QuestionSectionsState;
    questions: QuestionsState;
    listSections: typeof sectionsList.trigger;
    updateSection: typeof sectionUpdate.trigger;
    createSection: typeof sectionCreate.trigger;
    removeSection: typeof sectionRemove.trigger;
    updateQuestion: typeof questionUpdate.trigger;
}

const WebsiteFAQ: FC<WebsiteFAQProps> = ({
    questionSections,
    questions,
    listSections,
    updateSection,
    createSection,
    removeSection,
    updateQuestion,
}) => {

    const [sections, setSections] = useState<QuestionSection[]>([]);
    const [ isSectionDrawerVisible, setIsSectionDrawerVisible ] = useState(false);
    const [ isQuestionDrawerVisible, setIsQuestionDrawerVisible ] = useState(false);
    const [ currentSectionId, setCurrentSectionId ] = useState<QuestionSection['id']>();
    const [ currentQuestionId, setCurrentQuestionId ] = useState<Question['id']>();

    useEffect(() => {
        listSections({
            pageSize: 100,
            sort: 'index',
        });
    }, [listSections]);

    const onChange = () => {
        listSections({
            pageSize: 100,
            sort: 'index',
        });
    };

    useEffect(() => {
        setSections(questionSections.list.data.items);
    }, [questionSections.list.data.items, setSections]);

    // ---------------------------------------
    // Add / Remove section

    const onSectionRemove = (section: QuestionSection) => {
        removeSection({id: section.id});
    };

    const onSectionEdit = (section: QuestionSection) => {
        setCurrentSectionId(section.id);
        setIsSectionDrawerVisible(true);
    };

    const addSection = () => {
        setCurrentSectionId(undefined);
        setIsSectionDrawerVisible(true);
    };

    // ---------------------------------------
    // Drag&Drop reorder

    const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {

        if (
            (!result.destination)
            || (
                result.destination.droppableId === result.source.droppableId
                && result.destination.index === result.source.index
            )
        ) {
          return;
        }

        if (result.type === DndType.section) {
            // Switch week position
            const newWeeksOrder = reorder(
                sections,
                result.source.index,
                result.destination.index,
            );

            setSections(newWeeksOrder);
            updateSection({
                id: result.draggableId,
                index: result.destination.index,
            });
        }

        if (result.type === DndType.question) {
            // Switch action position
            if (result.source.droppableId === result.destination.droppableId) {
                // If move in the same week
                const currentWeek = sections.find((section) => section.id === result.source.droppableId);
                if (currentWeek && currentWeek?.questions) {
                    const newTasksOrder = reorder(
                        currentWeek?.questions,
                        result.source.index,
                        result.destination.index,
                    );

                    currentWeek.questions = newTasksOrder;
                }
            } else {
                // If week changed
                const sourceWeek = sections.find((week) => week.id === result.source.droppableId);
                const destinationWeek = sections.find((week) => week.id === result.destination?.droppableId);

                if (sourceWeek && destinationWeek) {
                    const sourceClone = Array.from(sourceWeek.questions);
                    const destClone = Array.from(destinationWeek.questions);
                    const [removed] = sourceClone.splice(result.source.index, 1);
                    destClone.splice(result.destination.index, 0, removed);

                    sourceWeek.questions = sourceClone;
                    destinationWeek.questions = destClone;
                }
            }

            updateQuestion({
                id: result.draggableId,
                index: result.destination.index,
                section: result.destination.droppableId,
            });
            setSections([...sections]);
        }

    };

    // ---------------------------------------
    // Task drawer

    const onQuestionCreate = (section: QuestionSection) => {
        setCurrentQuestionId(undefined);
        setCurrentSectionId(section.id);
        setIsQuestionDrawerVisible(true);
    };

    const onQuestionEdit = (section: QuestionSection, question: Question) => {
        setCurrentQuestionId(question.id);
        setCurrentSectionId(section.id);
        setIsQuestionDrawerVisible(true);
    };

    const onSectionDrawserClose = () => {
        setIsSectionDrawerVisible(false);
    };

    const onSectionDrawserSuccess = () => {
        onSectionDrawserClose();
        setTimeout(onChange, 250);
    };

    const onQuestionDrawserClose = () => {
        setIsQuestionDrawerVisible(false);
    };

    const onQuestionDrawserSuccess = () => {
        onQuestionDrawserClose();
        setTimeout(onChange, 250);
    };

    // ---------------------------------------
    // Render

    return (
        <div id="program">
            <Seo title="Foire aux questions" />
            <div className="page-header-container">
                <div className="page-header">
                    <h1 className="page-title">
                        Foire aux questions
                    </h1>
                </div>
            </div>

            <br />

            {(sections) && (
                <div id="program-weeks">
                    <div className="page-header">
                        <h1 className="page-title">
                            Sections <Badge count={sections.length} showZero={true} />
                        </h1>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="weeks-list" type={DndType.section}>
                            {(provided: any) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {sections.map((section: QuestionSection, index: number) => (
                                        <div key={'week-' + section.id}>
                                            <Draggable draggableId={section.id} index={index}>
                                                {(dragProvided: any) => (
                                                    <div className="program-week-draggable" ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                                        <SectionItem
                                                            section={section}
                                                            index={index}
                                                            onEdit={onSectionEdit}
                                                            onRemove={onSectionRemove}
                                                            onQuestionCreate={onQuestionCreate}
                                                            onQuestionEdit={onQuestionEdit}
                                                            handle={(
                                                                <MenuOutlined className="handle" {...dragProvided.dragHandleProps} />
                                                            )}
                                                        />
                                                        {dragProvided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Button
                        loading={questionSections.create.loading}
                        type="text"
                        className="add-new"
                        onClick={addSection}
                    >
                        <PlusOutlined />
                        Ajouter une section
                    </Button>

                </div>
            )}

            <SectionModal
                id={currentSectionId}
                isVisible={isSectionDrawerVisible}
                onClose={onSectionDrawserClose}
                onSuccess={onSectionDrawserSuccess}
            />

            <QuestionDrawer
                id={currentQuestionId}
                sectionId={currentSectionId}
                isVisible={isQuestionDrawerVisible}
                onClose={onQuestionDrawserClose}
                onSuccess={onQuestionDrawserSuccess}
            />

            <Modal prefixCls="ant-modal">test</Modal>

        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    questions: state.questions,
    questionSections: state.questionSections,
});

export default connect(
    mapStateToProps,
    {
        listSections: sectionsList.trigger,
        updateSection: sectionUpdate.trigger,
        createSection: sectionCreate.trigger,
        removeSection: sectionRemove.trigger,
        updateQuestion: questionUpdate.trigger,
    },
)(WebsiteFAQ);
