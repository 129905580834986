import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Spin, notification as antNotification, Select, message, Divider, Switch, InputNumber, Typography, Tabs, Input } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { MessageTrigger, SupportedLanguage, LanguagesList, NotificationChannel } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteFilled } from '@ant-design/icons';

import {
    NotificationsState,
    details as notificationDetails,
    update as notificationUpdate,
    create as notificationCreate,
} from '../../../store/actions/notifications';

import { i18n, translateNotificationChannel } from '../../../helpers/i18n';
import InputSeconds from '../../../components/InputSeconds';
import Flag from '../../../components/Flag';

const { TabPane } = Tabs;
const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface NotificationDrawerProps extends RouteProps {
    notification?: MessageTrigger;
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    notifications: NotificationsState;
    getDetails: typeof notificationDetails.trigger;
    detailsReset: typeof notificationDetails.reset;
    update: typeof notificationUpdate.trigger;
    updateReset: typeof notificationUpdate.reset;
    create: typeof notificationCreate.trigger;
    createReset: typeof notificationCreate.reset;
}

const NotificationDrawer: FC<NotificationDrawerProps> = ({
    notification,
    isVisible,
    onClose,
    onSuccess,

    notifications,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const [form] = Form.useForm();
    const [ tabLang, setTabLang ] = useState<SupportedLanguage>(SupportedLanguage.fr);
    const onClickDeleteEventCount = () => {
        form.setFieldsValue({
            event: {
                count: undefined,
            },
        });
    };

    useEffect(() => {
        form.resetFields();
        if (notification) {
            form.setFieldsValue(notification);
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (notifications.update.success || notifications.create.success) {

            form.resetFields();
            updateReset();
            createReset();

            if (onSuccess) {
                onSuccess();
            }

            message.success('La notification a été ' + ((notifications.create.success) ? 'ajoutée' : 'modifiée') + ' avec succès');
        }

        if (notifications.update.error || notifications.create.error) {
            updateReset();
            createReset();
            antNotification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [notifications.update.success, notifications.create.success, notifications.update.error, notifications.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        const payload = {
            id: notification?.id,
            slug: notification?.slug,
            ...values,
            event: {
                ...notification?.event,
                ...values.event,
                count: values.event.count ?? undefined,
                scheduled: values.event.scheduled ?? undefined,
                notSince: values.event.notSince ?? undefined,
            },
            template: {
                ...notification?.template,
                ...values.template,
            },
        };
        update(payload);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const tabChange = (val: string) => {
        setTabLang((val as SupportedLanguage));
    };

    return (
        <Drawer
            className="notification-drawer"
            title={(!notification?.id) ? 'Ajouter une notification' : 'Editer la notification'}
            width={600}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={notifications.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    {notification?.name && (
                        <div>
                            <label>Titre de la notification</label>
                            <Typography.Title level={2}>{i18n(notification.name)}</Typography.Title>
                        </div>
                    )}

                    <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                        { LanguagesList.map((language: SupportedLanguage) => (
                            <TabPane tab={<Flag language={language} />} key={language}>
                                <Form.Item
                                    label="Contenu du message"
                                    name={['template', 'text', language]}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                    style={{marginBottom: 0}}
                                >
                                    <TextArea placeholder="Saisir le contenu du message" />
                                </Form.Item>
                            </TabPane>
                        ))}
                    </Tabs>

                    <Divider />

                    <Form.Item
                        label="Type"
                        name="channels"
                        rules={[requiredRule]}
                    >
                        <Select size="large" placeholder="Sélectionner un type" mode="multiple">
                            {Object.keys(NotificationChannel).map((key: any) => (
                                <Select.Option key={'type-' + key} value={key}>{translateNotificationChannel((NotificationChannel as any)[key])}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Numéro de l'occurence"
                    >
                        <Input.Group compact>
                            <Form.Item
                                name={['event', 'count']}
                                rules={[{
                                    min: 1,
                                    type: 'number',
                                    message: 'La valeur minimum est de 1',
                                }]}
                                style={{ width: 490, marginBottom: 0 }}
                            >
                                <InputNumber size="large" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                            </Form.Item>
                            <Button icon={<DeleteFilled />} onClick={onClickDeleteEventCount} size="large" />
                        </Input.Group>
                    </Form.Item>

                    <Form.Item
                        label="Délai de l'envoi"
                        name={['event', 'scheduled']}
                    >
                        <InputSeconds />
                    </Form.Item>

                    <Form.Item
                        label="Période de non-activité"
                        name={['event', 'notSince']}
                        rules={[{
                            min: 1,
                            type: 'number',
                            message: 'La valeur minimum est de 1',
                        }]}
                    >
                        <InputSeconds />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label="Inclure par défaut à la création d'un programme"
                        name={['meta', 'isIncludedByDefault']}
                        className="switch-row"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Divider />

                    <br />
                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={notifications.create.loading || notifications.update.loading}
                        >
                            {notification ? 'Mettre à jour' : 'Ajouter la notification'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    notifications: state.notifications,
});

export default connect(
    mapStateToProps,
    {
        getDetails: notificationDetails.trigger,
        detailsReset: notificationDetails.reset,
        create: notificationCreate.trigger,
        createReset: notificationCreate.reset,
        update: notificationUpdate.trigger,
        updateReset: notificationUpdate.reset,
    },
)(NotificationDrawer);
