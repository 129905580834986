import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { SelectProps } from 'antd/lib/select';
import { connect } from 'react-redux';

import {
    UsersState,
    search as searchUsers,
    getUsersSearchState,
} from '../../store/actions/users';
import { User } from '../../store/api/apiTypes';
import { UserListPayload } from '../../store/api/users';
import { MainReducerState } from '../../store/reducers';
import { getFullName } from '../../helpers';

interface UserFilterSelectProps {
    onChange?: (value: any) => void;
    multi?: boolean;
    users: UsersState['search'];
    search: typeof searchUsers.trigger;
    size?: SizeType;
    value ?: string[];
    setValue ?: any;
    initialValue ?: User[];
    filters ?: UserListPayload;
}

const UserFilterSelect: FC<UserFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, users, search, multi, size, filters} = props;
    const [ value, setValue ] = useState<any>();
    const [ initialValue, setInitialValue ] = useState<User[]>([]);

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue.map((a) => a.id));
            setInitialValue(props.initialValue);
            if (onChange) { onChange(props.initialValue.map((a) => a.id || a)); }
        } else {
            setValue([]);
            setInitialValue([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFocus: SelectProps<User['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 15,
            ...filters,
        });
    };

    const onSearch: SelectProps<User['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 15,
            ...filters,
            throttling: 250,
        });
    };

    const onChangeSelect: SelectProps<User['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {onChange(newValue); }
    };

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            size={size}
            ref={ref}
            showSearch
            value={value}
            placeholder="Recherchez un client"
            notFoundContent={users.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
        >
            {
                [
                    ...initialValue, // Display initial value
                    ...users.data.items.filter((a) => !initialValue.map((b) => b.id).includes(a.id)), // Search items, excluding initial value
                ].map((user) => (
                <Select.Option value={user.id} key={user.id}>{getFullName(user.firstName, user.lastName)}</Select.Option>
            ))}
        </Select>
    );

});

UserFilterSelect.defaultProps = {
    multi: false,
    size: 'small',
};

const mapStateToProps = (state: MainReducerState) => ({
    users: getUsersSearchState(state),
});

export default connect(
    mapStateToProps,
    {
        search: searchUsers.trigger,
    },
    null,
    { forwardRef: true },
)(UserFilterSelect);
