import {
    ProgramType, SupportedLanguage, ProgramTaskFamily, Translation, LibraryDocumentType, BadgeLevel,
    SubTaskType, ProgramValidationType, SurveyAnswerType, BusinessType, CompanySize, NetworkingActionType,
    ContactType, DocumentSubTaskType, SurveySubTaskType, NotificationChannel, BadgeType,
    SurveyAnswerColors, SurveyAnswerIntelligence, SubTaskSubType,
} from '../store/api/apiTypes';

export const i18n = (field: Translation | string | undefined) => {
    if (!field) { return '-'; }

    if (typeof field === 'string' || field instanceof String) {
        return field.toString();
    } else {
        return (field.fr) ? field.fr : ((field.en) ? field.en : '-');
    }
};

export const translateProgramType = (value: ProgramType) => {
    switch (value) {
        case ProgramType.basic: return 'Initial';
        case ProgramType.extension: return 'Prolongation';
        default: return '-';
    }
};

export const translateLanguage = (value: SupportedLanguage) => {
    switch (value) {
        case SupportedLanguage.fr: return 'Français';
        case SupportedLanguage.en: return 'Anglais';
        default: return '-';
    }
};

export const translateTaskFamily = (value?: ProgramTaskFamily) => {
    switch (value) {
        case ProgramTaskFamily.cv: return 'CV';
        case ProgramTaskFamily.linkedin: return 'LinkedIn';
        case ProgramTaskFamily.professionalProject: return 'Projet professionnel';
        case ProgramTaskFamily.offer: return 'Annonces';
        case ProgramTaskFamily.networking: return 'Networking';
        case ProgramTaskFamily.communication: return 'Communication';
        case ProgramTaskFamily.hiring: return 'Recrutement ';
        case ProgramTaskFamily.privateLife: return 'Vie privée';
        case ProgramTaskFamily.retirement: return 'Retraite';
        case ProgramTaskFamily.businessCreation: return 'Création d’entreprise';
        case ProgramTaskFamily.other: return 'Autres';
        default: return '-';
    }
};

export const translateLibraryDocumentType = (value?: LibraryDocumentType) => {
    switch (value) {
        case LibraryDocumentType.file: return 'Fiche';
        case LibraryDocumentType.podcast: return 'Podcast';
        case LibraryDocumentType.video: return 'Vidéo';
        default: return '-';
    }
};

export const translateBadgeLevel = (value?: BadgeLevel | string) => {
    switch (value) {
        // case BadgeLevel.lvl1: return 'Bronze';
        case BadgeLevel.lvl2: return 'Argent';
        case BadgeLevel.lvl3: return 'Or';
        default: return '-';
    }
};

export const translateBadgeType = (value?: BadgeType | string) => {
    switch (value) {
        case BadgeType.cv: return 'CV';
        case BadgeType.linkedin: return 'Linkedin';
        case BadgeType.professionalProject: return 'Projet professionnel';
        default: return '-';
    }
};

export const translateSubTaskType = (value?: SubTaskType) => {
    switch (value) {
        case SubTaskType.text: return 'Texte';
        case SubTaskType.document: return 'Document de la bibliothèque';
        case SubTaskType.link: return 'Lien externe';
        case SubTaskType.action: return 'Ecran de création';
        case SubTaskType.upload: return 'Uploader un élément';
        case SubTaskType.survey: return 'Questionnaire / Test';
        default: return '-';
    }
};

export const translateSubTaskSubType = (value?: SubTaskSubType) => {
    switch (value) {
        case SubTaskSubType.contact: return 'Créer un nouveau contact';
        case SubTaskSubType.hiring: return 'Créer une nouvelle annonce';
        case SubTaskSubType.networking: return 'Créer une action de networking';
        default: return '-';
    }
};

export const translateProgramValidationType = (value?: ProgramValidationType) => {
    switch (value) {
        case ProgramValidationType.manual: return 'Manuelle';
        case ProgramValidationType.automatic: return 'Automatique';
        default: return '-';
    }
};

export const translateSurveyAnswerType = (value?: SurveyAnswerType) => {
    switch (value) {
        case SurveyAnswerType.boolean: return 'Oui / Non';
        case SurveyAnswerType.list: return 'Liste de réponses';
        case SurveyAnswerType.text: return 'Texte';
        case SurveyAnswerType.multiValue: return 'Liste de réponses - choix multiple';
        default: return '-';
    }
};

export const translateBusinessType = (value?: BusinessType) => {
    switch (value) {
        case BusinessType.agricultureForestryFishing: return 'Agriculture, sylviculture et pêche';
        case BusinessType.extractiveIndustries: return 'Industries extractives';
        case BusinessType.manufacturingIndustry: return 'Industrie manufacturière';
        case BusinessType.productionDistributionElectricityGas: return 'Production et distribution d\'électricité, de gaz, de vapeur et d\'air conditionné';
        case BusinessType.waterProductionDistribution: return 'Production et distribution d\'eau ; assainissement, gestion des déchets et dépollution';
        case BusinessType.construction: return 'Construction';
        case BusinessType.tradeRepairAutomobilesMotorcycles: return 'Commerce ; réparation d\'automobiles et de motocycles';
        case BusinessType.transportationWarehousing: return 'Transports et entreposage';
        case BusinessType.accommodationCatering: return 'Hébergement et restauration';
        case BusinessType.informationCommunication: return 'Information et communication';
        case BusinessType.financialInsuranceActivities: return 'Activités financières et d\'assurance';
        case BusinessType.realEstateActivities: return 'Activités immobilières';
        case BusinessType.specializedScientificTechnicalActivities: return 'Activités spécialisées, scientifiques et techniques';
        case BusinessType.administrativeSupportServiceActivities: return 'Activités de services administratifs et de soutien';
        case BusinessType.publicAdministration: return 'Administration publique';
        case BusinessType.education: return 'Enseignement';
        case BusinessType.humanHealthSocialAction: return 'Santé humaine et action sociale';
        case BusinessType.artsEntertainmentRecreationalActivities: return 'Arts, spectacles et activités récréatives';
        case BusinessType.otherServiceActivities: return 'Autres activités de services';
        case BusinessType.activitiesHouseholdsAsEmployers: return 'Activités des ménages en tant qu\'employeurs ; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre';
        case BusinessType.extraTerritorialActivities: return 'Activités extra-territoriales';
        default: return '-';
    }
};

export const translateCompanySize = (value?: CompanySize) => {
    switch (value) {
        case CompanySize.lessThan10: return 'Moins de 10 salariés';
        case CompanySize.from10To249: return 'De 10 à  249 salariés';
        case CompanySize.from250To999: return 'De 250 à 999 salariés';
        case CompanySize.from1000To5000: return 'De 1.000 à 5.000 salariés';
        case CompanySize.moreThan5000: return 'Plus de 5.000 salariés';
        default: return '-';
    }
};

export const translateNetworkingActionType = (value?: NetworkingActionType) => {
    switch (value) {
        case NetworkingActionType.informationQuery: return 'Demande d\'information';
        case NetworkingActionType.feedbackQuery: return 'Demande de feedback';
        case NetworkingActionType.adviseQuery: return 'Demande de conseil';
        case NetworkingActionType.jobInterview: return 'Entretien de recrutement';
        case NetworkingActionType.reminder: return 'Rappel à mon bon souvenir';
        default: return '-';
    }
};

export const translateContactType = (value?: ContactType) => {
    switch (value) {
        case ContactType.internal: return 'Network interne';
        case ContactType.external: return 'Network externe';
        case ContactType.manager: return 'Mon manager';
        case ContactType.hr: return 'Mon RH';
        case ContactType.networkAngel: return 'Mon Network Angel';
        case ContactType.headHunter: return 'Les recruteurs';
        default: return '-';
    }
};

export const translateDocumentSubTaskType = (value?: DocumentSubTaskType) => {
    switch (value) {
        case DocumentSubTaskType.toDo: return 'À faire';
        case DocumentSubTaskType.toRead: return 'À lire';
        default: return '-';
    }
};

export const translateSurveySubTaskType = (value?: SurveySubTaskType) => {
    switch (value) {
        case SurveySubTaskType.basic: return 'Normal';
        case SurveySubTaskType.validation: return 'Validation';
        case SurveySubTaskType.tricam: return 'Test TRICAM';
        case SurveySubTaskType.colors: return 'Test couleurs';
        case SurveySubTaskType.intelligence: return 'Test 8 formes d\'intelligence';
        default: return '-';
    }
};

export const translateNotificationChannel = (value?: NotificationChannel) => {
    switch (value) {
        case NotificationChannel.pull: return 'In-app';
        case NotificationChannel.webhook: return 'E-mail';
        case NotificationChannel.mobilePush: return 'Push';
        default: return '-';
    }
};

export const translateSurveyAnswerColors = (value?: SurveyAnswerColors) => {
    switch (value) {
        case SurveyAnswerColors.red: return 'Rouge';
        case SurveyAnswerColors.green: return 'Vert';
        case SurveyAnswerColors.blue: return 'Bleu';
        case SurveyAnswerColors.yellow: return 'Jaune';
        default: return value;
    }
};

export const translateSurveyAnswerIntelligence = (value?: SurveyAnswerIntelligence) => {
    switch (value) {
        case SurveyAnswerIntelligence.verbal: return 'Verbale';
        case SurveyAnswerIntelligence.mathematical: return 'Mathématique';
        case SurveyAnswerIntelligence.musical: return 'Musicale';
        case SurveyAnswerIntelligence.visual: return 'Visuelle';
        case SurveyAnswerIntelligence.physical: return 'Corporelle';
        case SurveyAnswerIntelligence.interpersonal: return 'Interpersonnelle';
        case SurveyAnswerIntelligence.intrapersonal: return 'Intrapersonnelle';
        case SurveyAnswerIntelligence.naturalist: return 'Naturaliste';
        default: return value;
    }
};
