import { LibraryDocument } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

export interface LibraryDocumentDetailsPayload {
    id: string;
}

export interface LibraryDocumentUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type LibraryDocumentListPayload = SearchPaginationQuery;

export const details = (payload: LibraryDocumentDetailsPayload) => api.get(`/library/${payload.id}`);

export const list = (payload: LibraryDocumentListPayload = {}) => {
    return api.get(`/library`, {params: cleanPayload(payload)});
};

export const create = (payload: LibraryDocument) => {
    const formData = serialize(payload);

    return api.post(`/library`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const update = (payload: LibraryDocument) => {
    const id: string = payload.id;
    delete payload.id;

    const formData = serialize(payload);

    return api.put(`/library/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const remove = (payload: LibraryDocument) => {
    return api.delete(`/library/${payload.id}`);
};
