import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
    Popconfirm,
} from 'antd';

import { User, RoleSlug, Organization } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import Search from 'antd/lib/input/Search';
import UserDrawer from './UserDrawer';
import {
    UsersState,
    list as usersList,
    remove as usersRemove,
    update as usersUpdate,
    roles as usersRoles,
} from '../../store/actions/users';

import { FilterQuery } from '../../store/api';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import Seo from '../../components/Seo';
import useSessionStorage from '../../hooks/sessionStorage';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../store/actions/auth';

const rowKey = (item: User) => `${item.id}`;

interface UsersListProps {
    user?: User;
    users: UsersState;
    getList: typeof usersList.trigger;
    remove: typeof usersRemove.trigger;
    update: typeof usersUpdate.trigger;
    resetRemove: typeof usersRemove.reset;
    getRoles: typeof usersRoles.trigger;
}

const UsersList: FC<UsersListProps> = ({
    user,
    users,
    getList,
    remove,
    update,
    resetRemove,
    getRoles,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('clients_lastSearch', {});

    useEffect(() => {
        getRoles();

        if (history.action !== 'POP') {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
                role: RoleSlug.superAdmin,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.remove.success) {
            resetRemove();
            updateList();
        }
    }, [users.remove.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<User>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const filters: FilterQuery['filters'] = [];

        if (tableFilters.type && tableFilters.type.length > 0) {
            filters.push({
                name: 'type',
                value: tableFilters.type[0],
            });
        }

        updateList(
            (pagination.current || 1) - 1,
            (sorter.field) ? sorter.field : undefined,
            (sorter.order) ? sorter.order : undefined,
            '',
            filters,
        );
    };

    const updateList = (page: number = 0, sortBy: string = '', sortOrder: string = '', searchText: string = '', filters: FilterQuery['filters'] = []) => {

        setLastSearchParams({
            ...lastSearchParams,
            page,
            pageSize: itemsPerPage,
            sortBy,
            sort: sortBy,
            order: sortOrder,
            search: searchText,
            filters,
        });
    };

    // ---------------------------------------
    // Drawer management

    const edit = (id: string) => {
        setSelectedId(id);
        setIsDrawerVisible(true);
    };

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        updateList();
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete user

    const removeItem = (userToRemove: User) => {
        remove({id: userToRemove.id});
    };

    // ---------------------------------------
    // Filters

    /*
    const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: User['id']) => {
        setSearchParam('tags', id);
    };
    */

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<User>> = [
        {
            dataIndex: 'lastName',
            title: 'Nom',
            sorter: true,
        },
        {
            dataIndex: 'firstName',
            title: 'Prénom',
            sorter: true,
        },
        {
            dataIndex: 'email',
            title: 'Adresse e-mail',
            sorter: true,
        },
        {
            title:  'Actions',
            key:  'actions',
            fixed:  'right',
            width: 120,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EditFilled />}
                        shape="circle"
                        type="ghost"
                        onClick={edit.bind(null, record.id)}
                    />
                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </>
            ),
        },

    ];

    return (
            <>
                <Seo title="Clients" />
                <div className="page-header-container">
                    <div className="page-header">
                        <h1 className="page-title">
                            Utilisateurs <Badge count={users.list.data.totalCount} overflowCount={100000} />
                        </h1>

                        <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            onClick={add}
                        >
                            Ajouter un utilisateur
                        </Button>
                    </div>

                    <Search
                        className="page-search"
                        placeholder="Rechercher un utilisateur par nom ou email"
                        loading={users.list.loading}
                        onSearch={onSearch}
                        allowClear
                        size="large"
                    />
                </div>

                {users.list ? (
                    <Table<User>
                        className="page-table"
                        rowKey={rowKey}
                        columns={columns}
                        loading={users.list.loading}
                        dataSource={users.list.data.items}
                        pagination={{
                            total: users.list.data.totalCount,
                            current: users.list.data.page + 1,
                            pageSize: users.list.data.pageSize,
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                ) : undefined}

                <UserDrawer
                    id={selectedId}
                    organizationId={(user?.organization as Organization).id}
                    isVisible={isDrawerVisible}
                    roles={users.roles.data.items}
                    onClose={onDrawserClose}
                    onSuccess={onDrawerSuccess}
                />
            </>
        );

};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    users: state.users,
});

export default connect(
    mapStateToProps,
    {
        getList: usersList.trigger,
        remove: usersRemove.trigger,
        resetRemove: usersRemove.reset,
        update: usersUpdate.trigger,
        getRoles: usersRoles.trigger,
    },
)(UsersList);
