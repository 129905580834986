import { combineReducers } from 'redux';
import { takeLatest} from 'redux-saga/effects';

import {
    AdministratorDetailsPayload,
    AdministratorListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    sendWelcome as sendWelcomeApiCall,
    roles as rolesApiCall,
} from '../api/administrators';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, User, Role } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface AdministratorsState {
    list: RequestState<ListResponse<User>>;
    details: RequestState<User | undefined>;
    create: RequestState<User | undefined>;
    update: RequestState<User | undefined>;
    remove: RequestState<User | undefined>;
    sendWelcome: RequestState<User | undefined>;
    roles: RequestState<ListResponse<Role>>;
}

const initialState: AdministratorsState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
    sendWelcome: {
        data: undefined,
        loading: false,
        success: false,
    },
    roles: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    AdministratorsState['list'],
    AdministratorListPayload,
    ListResponse<User>
>('administrators/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    AdministratorsState['details'],
    AdministratorDetailsPayload,
    User
>('administrators/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    AdministratorsState['create'],
    User,
    User
>('administrators/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    AdministratorsState['update'],
    User,
    User
>('administrators/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    AdministratorsState['remove'],
    User,
    User
>('administrators/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

export const sendWelcome = new EzeeAsyncAction<
    AdministratorsState['sendWelcome'],
    User,
    User
>('administrators/sendWelcome', initialState.sendWelcome, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.sendWelcome,
    }),
});

export const roles = new EzeeAsyncAction<
    AdministratorsState['roles'],
    AdministratorListPayload,
    ListResponse<Role>
>('administrators/roles', initialState.roles, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.roles,
    }),
});

// Reducer

export const administratorsReducer = combineReducers<AdministratorsState>({
    details: details.reducer,
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
    sendWelcome: sendWelcome.reducer,
    roles: roles.reducer,
});

// Saga

export function* administratorsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(sendWelcome.type.trigger, simpleAsyncSaga(sendWelcomeApiCall, sendWelcome));
    yield takeLatest(roles.type.trigger, simpleAsyncSaga(rolesApiCall, roles));
}

// Store helpers

export const getAdministratorsState = (state: MainReducerState) => state.administrators;
