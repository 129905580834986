import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';

import {
    Table,
    Button,
    Badge,
    Popconfirm,
    Space,
} from 'antd';

import { ProgramTaskFamily, LibraryProgramTask, DrawerActionType } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import Search from 'antd/lib/input/Search';
import {
    LibraryProgramTasksState,
    list as libraryProgramTasksList,
    remove as libraryProgramTasksRemove,
    update as libraryProgramTasksUpdate,
} from '../../../store/actions/libraryProgramTasks';

import { FilterQuery } from '../../../store/api';
import { PlusOutlined, DeleteFilled, EditFilled, CopyFilled } from '@ant-design/icons';
import useSessionStorage from '../../../hooks/sessionStorage';
import { useHistory } from 'react-router-dom';
import Seo from '../../../components/Seo';
import { translateTaskFamily } from '../../../helpers/i18n';
import LibraryProgramTaskDrawer from './LibraryProgramTaskDrawer';
import moment from 'moment';

const rowKey = (item: LibraryProgramTask) => `${item.id}`;

interface LibraryProgramTaskTasksListProps {
    libraryProgramTasks: LibraryProgramTasksState;
    getList: typeof libraryProgramTasksList.trigger;
    remove: typeof libraryProgramTasksRemove.trigger;
    update: typeof libraryProgramTasksUpdate.trigger;
    resetRemove: typeof libraryProgramTasksRemove.reset;
}

const LibraryProgramTaskTasksList: FC<LibraryProgramTaskTasksListProps> = ({
    libraryProgramTasks,
    getList,
    remove,
    update,
    resetRemove,
}) => {

    const itemsPerPage: number = 20;
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [drawerActionType, setDrawerActionType] = useState<DrawerActionType>(DrawerActionType.ADD);
    const [lastSearchParams, setLastSearchParams] = useSessionStorage('libraryProgramTasks_lastSearch', {});

    const isBack = () => {
        return history.action === 'POP';
    };

    useEffect(() => {

        if (!isBack()) {
            setLastSearchParams({
                page: 0,
                pageSize: itemsPerPage,
            });
        } else {
            setLastSearchParams({
                ...lastSearchParams,
            });
        }

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({ ...lastSearchParams }); }
    }, [lastSearchParams, initialized, getList]);

    useEffect(() => {
        if (libraryProgramTasks.remove.success) {
            resetRemove();
            getList(lastSearchParams);
        }
    }, [libraryProgramTasks.remove.success, resetRemove, getList, lastSearchParams]);

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<LibraryProgramTask>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.family && tableFilters.family.length > 0) {
            queryFilters.push({
                name: 'family',
                value: tableFilters.family[0],
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const edit = (item: LibraryProgramTask) => {
        setSelectedId(item.id);
        setDrawerActionType(DrawerActionType.EDIT);
        setIsDrawerVisible(true);
    };

    const add = () => {
        setSelectedId(undefined);
        setDrawerActionType(DrawerActionType.ADD);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList(lastSearchParams);
        setIsDrawerVisible(false);
    };

    const duplicate = (libraryProgramTask: LibraryProgramTask) => {
        setSelectedId(libraryProgramTask.id);
        setDrawerActionType(DrawerActionType.DUPLICATE);
        setIsDrawerVisible(true);
    };

    // ---------------------------------------
    // Disable / Enable / Delete libraryProgramTask

    const removeItem = (libraryProgramTask: LibraryProgramTask) => {
        remove({ id: libraryProgramTask.id });
    };

    // ---------------------------------------
    // Filters

    /*const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTagFilterChange = (id: LibraryProgramTask['id']) => {
        setSearchParam('tags', id);
    };*/

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const columns: Array<ColumnProps<LibraryProgramTask>> = [
        {
            dataIndex: ['name', 'en'],
            title: 'Titre',
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
            render: (title, record) => (record.code ? record.code + ' - ' + title : title),
        },
        {
            dataIndex: 'family',
            title: 'Famille',
            render: (type: ProgramTaskFamily) => translateTaskFamily(type),
            filterMultiple: false,
            filters: Object.keys(ProgramTaskFamily).map((key: any) => ({
                text: translateTaskFamily((ProgramTaskFamily as any)[key]),
                value: key,
            })),
            defaultFilteredValue: getFilterValue('family'),
            width: 180,
        },
        {
            dataIndex: 'points',
            title: 'Points',
            width: 120,
        },
        {
            dataIndex: 'createdAt',
            title: 'Date de création',
            sorter: true,
            render: (date) => moment(date).format('DD/MM/YYYY hh:mm'),
            defaultSortOrder: columnSortOrder('createdAt'),
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            width: 120,
            render: (text, record) => (
                <Space direction="horizontal" >
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<CopyFilled />}
                        onClick={duplicate.bind(null, record)}
                    />

                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<EditFilled />}
                        onClick={edit.bind(null, record)}
                    />

                    <Popconfirm
                        title="Confirmez-vous la suppression ?"
                        onConfirm={removeItem.bind(null, record)}
                        okText="Confirmer"
                        cancelText="Annuler"
                        placement="topRight"
                    >
                        <Button
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                        />
                    </Popconfirm>
                </Space >
            ),
        },

    ];

    return (
        <>
            <Seo title="Bibliothèque - Programmes" />
            <div className="page-header-container">
                <div className="page-header">
                    <h1 className="page-title">
                        Bibliothèque - Actions <Badge count={libraryProgramTasks.list.data.totalCount} overflowCount={100000} />
                    </h1>

                    <Button
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={add}
                    >
                        Créer une action
                    </Button>
                </div>

                <Search
                    className="page-search"
                    placeholder="Rechercher un nom d'action"
                    defaultValue={isBack() ? lastSearchParams.search : undefined}
                    loading={libraryProgramTasks.list.loading}
                    onSearch={onSearch}
                    allowClear
                    size="large"
                />
            </div>

            {libraryProgramTasks.list ? (
                <Table<LibraryProgramTask>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={libraryProgramTasks.list.loading}
                    dataSource={libraryProgramTasks.list.data.items}
                    pagination={{
                        total: libraryProgramTasks.list.data.totalCount,
                        current: libraryProgramTasks.list.data.page + 1,
                        pageSize: libraryProgramTasks.list.data.pageSize,
                        hideOnSinglePage: true,
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}

            <LibraryProgramTaskDrawer
                id={selectedId}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
                drawerActionType={drawerActionType}

            />
        </>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryProgramTasks: state.libraryProgramTasks,
});

export default connect(
    mapStateToProps,
    {
        getList: libraryProgramTasksList.trigger,
        remove: libraryProgramTasksRemove.trigger,
        resetRemove: libraryProgramTasksRemove.reset,
        update: libraryProgramTasksUpdate.trigger,
    },
)(LibraryProgramTaskTasksList);
