import { Permission } from './../store/api/apiTypes';
import { User, PermissionRight } from '../store/api/apiTypes';

export const hasPermission = (user?: User, permission?: Permission, right: PermissionRight = PermissionRight.Read) => {
    if (!permission || Boolean(user?.mergedPermissions?.[Permission.SuperAdmin])) {
        return true;
    }
    switch (right) {
        case PermissionRight.Read:
            return Boolean(user?.mergedPermissions?.[permission]);
        case PermissionRight.Write:
            return user?.permissions?.[permission] === PermissionRight.Write;
    }
    return false;
};
