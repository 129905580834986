import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { Badge, Button } from 'antd';

import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { Program, ProgramWeek, DndType, ProgramTask } from '../../store/api/apiTypes';
import { DragDropContext, Droppable, Draggable, DragDropContextProps } from 'react-beautiful-dnd';
import ProgramWeekItem from './ProgramWeekItem';
import { reorder } from '../../hooks';
import ProgramTaskDrawer from './ProgramTaskDrawer';
import {
    ProgramWeeksState,
    create as programWeeksCreate,
    update as programWeeksUpdate,
    remove as programWeeksRemove,
} from '../../store/actions/programWeeks';
import {
    update as programTasksUpdate,
    create as programTasksCreate,
} from '../../store/actions/programTasks';

import '../../assets/styles/pages/Program.less';
import LibraryProgramTaskModal from '../library/tasks/LibraryProgramTaskModal';
import SaveAsModal from '../../components/SaveAsModal';
import { LibrarySaveAsPayload } from '../../store/api/libraryPrograms';

interface ProgramWeeksProps {
    program: Program;

    onChange: () => void;

    programWeeks: ProgramWeeksState;
    update: typeof programWeeksUpdate.trigger;
    create: typeof programWeeksCreate.trigger;
    remove: typeof programWeeksRemove.trigger;
    updateTask: typeof programTasksUpdate.trigger;
    createTask: typeof programTasksCreate.trigger;
}

const ProgramWeeksList: FC<ProgramWeeksProps> = ({
    program,

    onChange,

    programWeeks,
    update,
    create,
    remove,
    updateTask,
    createTask,
}) => {

    const [weeks, setWeeks] = useState<ProgramWeek[]>([]);
    const [ isTaskDrawerVisible, setIsTaskDrawerVisible ] = useState(false);
    const [ isLibraryTaskModalVisible, setIsLibraryTaskModalVisible ] = useState(false);
    const [ isSaveAsModalVisible, setIsSaveAsModalVisible ] = useState(false);
    const [ currentWeekId, setCurrentWeekId ] = useState<ProgramWeek['id']>();
    const [ currentTaskId, setCurrentTaskId ] = useState<ProgramTask['id']>();

    useEffect(() => {
        if (program.weeks) {
            setWeeks(program.weeks);
        }
    }, [program.weeks, setWeeks]);

    // ---------------------------------------
    // Add new week

    const add = () => {
        create({
            programId: program.id,
        });
    };

    // ---------------------------------------
    // Remove week

    const onWeekRemove = (week: ProgramWeek) => {
        remove({id: week.id});
    };

    // ---------------------------------------
    // Drag&Drop reorder

    const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {

        if (
            (!result.destination)
            || (
                result.destination.droppableId === result.source.droppableId
                && result.destination.index === result.source.index
            )
        ) {
          return;
        }

        if (result.type === DndType.week) {
            // Switch week position
            const newWeeksOrder = reorder(
                weeks,
                result.source.index,
                result.destination.index,
            );

            setWeeks(newWeeksOrder);
            update({
                id: result.draggableId,
                index: result.destination.index,
            });
        }

        if (result.type === DndType.task) {
            // Switch action position
            if (result.source.droppableId === result.destination.droppableId) {
                // If move in the same week
                const currentWeek = weeks.find((week) => week.id === result.source.droppableId);
                if (currentWeek && currentWeek?.tasks) {
                    const newTasksOrder = reorder(
                        currentWeek?.tasks,
                        result.source.index,
                        result.destination.index,
                    );

                    currentWeek.tasks = newTasksOrder;
                }
            } else {
                // If week changed
                const sourceWeek = weeks.find((week) => week.id === result.source.droppableId);
                const destinationWeek = weeks.find((week) => week.id === result.destination?.droppableId);

                if (sourceWeek && destinationWeek) {
                    const sourceClone = Array.from(sourceWeek.tasks);
                    const destClone = Array.from(destinationWeek.tasks);
                    const [removed] = sourceClone.splice(result.source.index, 1);
                    destClone.splice(result.destination.index, 0, removed);

                    sourceWeek.tasks = sourceClone;
                    destinationWeek.tasks = destClone;
                }
            }

            updateTask({
                id: result.draggableId,
                index: result.destination.index,
                week: result.destination.droppableId,
            });
            setWeeks([...weeks]);
        }

    };

    // ---------------------------------------
    // Task drawer

    const onTaskCreate = (week: ProgramWeek) => {
        setCurrentTaskId(undefined);
        setCurrentWeekId(week.id);
        setIsTaskDrawerVisible(true);
    };

    const onTaskEdit = (week: ProgramWeek, task: ProgramTask) => {
        setCurrentTaskId(task.id);
        setCurrentWeekId(week.id);
        setIsTaskDrawerVisible(true);
    };

    const onTaskDrawserClose = () => {
        setIsTaskDrawerVisible(false);
    };

    const onTaskDrawserSuccess = () => {
        onTaskDrawserClose();
        setTimeout(onChange, 250);
    };

    // ---------------------------------------
    // Task library

    const useTaskLibrary = (week: ProgramWeek) => {
        setCurrentWeekId(week.id);
        setIsLibraryTaskModalVisible(true);
    };

    const onLibraryTaskSelection = (payload: any) => {
        setIsLibraryTaskModalVisible(false);
        createTask({
            week: currentWeekId,
            programId: program.id,
            fromTemplate: payload.programTask,
        });
    };

    const onTaskSaveAs = (week: ProgramWeek, task: ProgramTask) => {
        setCurrentTaskId(task.id);
        setCurrentWeekId(week.id);
        setIsSaveAsModalVisible(true);
    };

    const saveAsTemplate = (saveAsValues: LibrarySaveAsPayload) => {
        setIsSaveAsModalVisible(false);
        updateTask({
            ...saveAsValues,
            saveAsTemplate: true,
            id: currentTaskId,
        });
    };

    // ---------------------------------------
    // Render

    return (
        <div id="program-weeks">
            <div className="page-header">
                <h1 className="page-title">
                    Semaines <Badge count={weeks.length} showZero={true} />
                </h1>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="weeks-list" type={DndType.week}>
                    {(provided: any) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {weeks.map((week: ProgramWeek, index: number) => (
                                <div key={'week-' + week.id}>
                                    <Draggable draggableId={week.id} index={index}>
                                        {(dragProvided: any) => (
                                            <div className="program-week-draggable" ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                                <ProgramWeekItem
                                                    week={week}
                                                    index={index}
                                                    onRemove={onWeekRemove}
                                                    onTaskCreate={onTaskCreate}
                                                    onTaskEdit={onTaskEdit}
                                                    onTaskSaveAs={onTaskSaveAs}
                                                    onUseTaskLibrary={useTaskLibrary}
                                                    handle={(
                                                        <MenuOutlined className="handle" {...dragProvided.dragHandleProps} />
                                                    )}
                                                />
                                                {dragProvided.placeholder}
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Button
                loading={programWeeks.create.loading}
                type="text"
                className="add-new"
                onClick={add}
            >
                <PlusOutlined />
                Ajouter un semaine
            </Button>

            <ProgramTaskDrawer
                id={currentTaskId}
                programId={program.id}
                weekId={currentWeekId}
                isVisible={isTaskDrawerVisible}
                onClose={onTaskDrawserClose}
                onSuccess={onTaskDrawserSuccess}
            />

            <LibraryProgramTaskModal
                isVisible={isLibraryTaskModalVisible}
                onSuccess={onLibraryTaskSelection}
                onClose={setIsLibraryTaskModalVisible.bind(null, false)}
            />

            <SaveAsModal
                isVisible={isSaveAsModalVisible}
                onClose={setIsSaveAsModalVisible.bind(null, false)}
                onSuccess={saveAsTemplate}
            />
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    programWeeks: state.programWeeks,
});

export default connect(
    mapStateToProps,
    {
        create: programWeeksCreate.trigger,
        update: programWeeksUpdate.trigger,
        remove: programWeeksRemove.trigger,

        updateTask: programTasksUpdate.trigger,
        createTask: programTasksCreate.trigger,
    },
)(ProgramWeeksList);
