import { RcFile } from 'antd/lib/upload';

import api from './_client';
import { User } from './apiTypes';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => api.post('/auth', payload);

export interface ForgottenPasswordPayload {
    username: User['email'];
}

export const forgottenPassword = (payload: ForgottenPasswordPayload) => api.post('/auth/reset', payload);

export interface ResetPasswordPayload {
    token: string;
    password: string;
}

export const resetPassword = (payload: ResetPasswordPayload) => api.post('/signedAction', payload);

export interface ResendInvitationPayload {
    token: string;
}

export const resendInvitation = (payload: ResendInvitationPayload) => api.post('/auth/reset', payload);

export interface UpdateMePayload extends Pick<User, 'spokenLanguages' | 'degrees' | 'phone' | 'maritalStatus'> {
    avatarFile: RcFile;
}

export const checkLoginStatus = () => api.get('/me');

export const logout = () => api.post('/auth/logout');
