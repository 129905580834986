import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { QuestionSection, ListResponse } from '../api/apiTypes';
import { RequestState } from '../reducers';

import {
    QuestionSectionDetailsPayload,
    QuestionSectionListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/questionSections';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface QuestionSectionsState {
    list: RequestState<ListResponse<QuestionSection>>;
    details: RequestState<QuestionSection | undefined>;
    create: RequestState<QuestionSection | undefined>;
    update: RequestState<QuestionSection | undefined>;
    remove: RequestState<QuestionSection | undefined>;
}

const initialState: QuestionSectionsState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    QuestionSectionsState['list'],
    QuestionSectionListPayload,
    ListResponse<QuestionSection>
>('questionSections/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
    removeSection: (state, payload) => {
        if (state.data.items) {
            state.data.items = [...state.data.items.filter((section) => section.id !== payload.id)];
        }
        return {...state};
    },
    removeQuestion: (state, payload) => {
        if (state.data.items) {
            state.data.items.forEach((section) => {
                if (section.questions) {
                    section.questions = [...section.questions.filter((task) => task.id !== payload.id)];
                }
            });
        }
        return {...state};
    },
});

export const details = new EzeeAsyncAction<
    QuestionSectionsState['details'],
    QuestionSectionDetailsPayload,
    QuestionSection
>('questionSections/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    QuestionSectionsState['create'],
    QuestionSection,
    QuestionSection
>('questionSections/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    QuestionSectionsState['update'],
    QuestionSection,
    QuestionSection
>('questionSections/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    QuestionSectionsState['remove'],
    QuestionSection,
    QuestionSection
>('questionSections/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        payload,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const questionSectionsReducer = combineReducers<QuestionSectionsState>({
    details: details.reducer,
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
});

// Saga

export function* questionSectionsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, removeSaga);
}

function* removeSaga(actionData: DataAction<any>) {
    try {
        const response = yield call(removeApiCall, actionData.payload);
        yield put(list.actions.removeSection(actionData.payload));
        return yield put(remove.actions.success(response));
    } catch (error) {
        yield put(remove.actions.failure(error));
    }
}

// Store helpers
