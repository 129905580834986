import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    LibraryDocumentDetailsPayload,
    LibraryDocumentListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/libraryDocuments';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, LibraryDocument } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface LibraryDocumentsState {
    list: RequestState<ListResponse<LibraryDocument>>;
    search: RequestState<ListResponse<LibraryDocument>>;
    details: RequestState<LibraryDocument | undefined>;
    create: RequestState<LibraryDocument | undefined>;
    update: RequestState<LibraryDocument | undefined>;
    remove: RequestState<LibraryDocument | undefined>;
}

const initialState: LibraryDocumentsState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    LibraryDocumentsState['list'],
    LibraryDocumentListPayload,
    ListResponse<LibraryDocument>
>('libraryDocuments/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const search = new EzeeAsyncAction<
    LibraryDocumentsState['search'],
    LibraryDocumentListPayload,
    ListResponse<LibraryDocument>
>('libraryDocuments/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    LibraryDocumentsState['details'],
    LibraryDocumentDetailsPayload,
    LibraryDocument
>('libraryDocuments/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    LibraryDocumentsState['create'],
    LibraryDocument,
    LibraryDocument
>('libraryDocuments/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    LibraryDocumentsState['update'],
    LibraryDocument,
    LibraryDocument
>('libraryDocuments/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    LibraryDocumentsState['remove'],
    LibraryDocument,
    LibraryDocument
>('libraryDocuments/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const libraryDocumentsReducer = combineReducers<LibraryDocumentsState>({
    details: details.reducer,
    list: list.reducer,
    search: search.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
});

// Saga

export function* libraryDocumentsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
}

// Store helpers

export const getLibraryDocumentsState = (state: MainReducerState) => state.libraryDocuments;
