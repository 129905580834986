import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { Button, Menu, Popconfirm, Tooltip } from 'antd';
import { ProgramTask } from '../../store/api/apiTypes';

import {
    remove as programTaskRemove,
    update as programTaskUpdate,
    ProgramTasksState,
} from '../../store/actions/programTasks';

import '../../assets/styles/pages/Program.less';
import { EditFilled, EllipsisOutlined } from '@ant-design/icons';
import { translateTaskFamily, i18n } from '../../helpers/i18n';

interface ProgramWeekTaskItemProps {
    task: ProgramTask;
    handle: any;

    onEdit: (task: ProgramTask) => void;
    onSaveAs: (task: ProgramTask) => void;

    programTasks: ProgramTasksState;
    remove: typeof programTaskRemove.trigger;
    update: typeof programTaskUpdate.trigger;
}

const ProgramWeekTaskItem: FC<ProgramWeekTaskItemProps> = ({
    task,
    handle,

    onEdit,
    onSaveAs,

    programTasks,
    remove,
    update,
}) => {

    const [isActionTooltipVisible, setIsActionTooltipVisible] = useState(false);

    // ---------------------------------------
    // Edit / delete task

    const edit = () => {
        onEdit(task);
    };

    const removeTask = () => {
        setIsActionTooltipVisible(false);
        remove(task);
    };

    const openSaveAsModal = () => {
        onSaveAs(task);
    };

    // ---------------------------------------
    // Render

    const onActionTooltipVisibleChange = (visible: boolean) => {
        setIsActionTooltipVisible(visible);
    };

    const actionsMenu = (
        <Menu selectable={false}>
            <Menu.Item onClick={openSaveAsModal}>
                Enregistrer comme modèle
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Confirmez-vous la suppression ?"
                    onConfirm={removeTask}
                    okText="Confirmer"
                    cancelText="Annuler"
                    placement="topRight"
                >
                    <div>Supprimer</div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="program-week-task-item">
            <div className="cell-handle">{handle}</div>
            <div className="cell-name">
                <span>{i18n(task.name)}</span>
            </div>
            <div className="cell-family">{translateTaskFamily(task.family)}</div>
            <div className="cell-points">{task.points} pts</div>
            <div className="cell-actions">
                <Button
                    shape="circle"
                    type="ghost"
                    icon={<EditFilled />}
                    onClick={edit}
                    size="small"
                />
                <Tooltip
                    overlay={actionsMenu}
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                    trigger={['click']}
                    visible={isActionTooltipVisible}
                    onVisibleChange={onActionTooltipVisibleChange}
                >
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<EllipsisOutlined />}
                        size="small"
                        loading={programTasks.remove.loading && programTasks.remove.payload.id === task.id}
                    />
                </Tooltip>
            </div>
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    programTasks: state.programTasks,
});

export default connect(
    mapStateToProps,
    {
        remove: programTaskRemove.trigger,
        update: programTaskUpdate.trigger,
    },
)(ProgramWeekTaskItem);
