import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Select, InputNumber, Divider, Switch } from 'antd';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import { ProgramTaskFamily, SupportedLanguage, BadgeLevel, ProgramSubTask } from '../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';

import { translateTaskFamily, translateLanguage, translateBadgeLevel } from '../helpers/i18n';
import ProgramSubTaskRepeater from './ProgramSubTaskRepeater';

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface ProgramTaskFormProps {
    loading?: boolean;
    initialValues?: any;
    isFromLibrary?: boolean;
    onSubmit: (payload: any) => void;
}

const ProgramTaskForm: FC<ProgramTaskFormProps> = ({
    loading,
    initialValues,
    isFromLibrary,
    onSubmit,
}) => {

    const [form] = Form.useForm();
    const [currentFamily, setCurrentFamily] = useState<ProgramTaskFamily>();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
            setCurrentFamily(initialValues.family);
        } else {
            form.resetFields();
        }
    }, [initialValues, form]);

    const onFormFinish: FormProps['onFinish'] = (values) => {
        if (values.subTasks) {

            // Clear empty subtasks
            values.subTasks = values.subTasks.filter((el: any) => el != null);

            // Set the same language to subtasks
            values.subTasks.forEach((subtask: ProgramSubTask) => {
                if (subtask) {
                    subtask.language = values.language;
                }
            });

            // Sort subtasks by index
            values.subTasks = values.subTasks.sort((a: any, b: any) => a.index - b.index);
        }

        onSubmit(values);
    };

    const onFamilyChange = (type: ProgramTaskFamily) => {
        setCurrentFamily(type);
    };

    const familyHasBadge = () => {
        return currentFamily && [
            ProgramTaskFamily.cv,
            ProgramTaskFamily.linkedin,
            ProgramTaskFamily.professionalProject,
            ProgramTaskFamily.retirement,
            ProgramTaskFamily.businessCreation,
        ].includes(currentFamily);
    };

    return (
        <Form
            form={form}
            onFinish={onFormFinish}
            layout="vertical"
            hideRequiredMark
            scrollToFirstError
        >

            <Form.Item
                label="Titre de l'action"
                name={['name', 'en']}
                rules={[requiredRule]}
            >
                <Input size="large" placeholder="Saisir un titre" />
            </Form.Item>

            <Form.Item
                label="Code"
                name={['code']}
                rules={[requiredRule]}
            >
                <Input size="large" placeholder="Saisir un code" />
            </Form.Item>

            <Form.Item
                label="Description"
                name={['description', 'en']}
                rules={[requiredRule]}
            >
                <Input size="large" placeholder="Saisir un titre" />
            </Form.Item>

            <Form.Item
                label="Langue"
                name="language"
                rules={[requiredRule]}
            >
                <Select size="large" placeholder="Sélectionner une langue">
                    {Object.keys(SupportedLanguage).map((key: any) => (
                        <Select.Option key={'language-' + key} value={key}>{translateLanguage((SupportedLanguage as any)[key])}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="Famille"
                name="family"
                rules={[requiredRule]}
            >
                <Select size="large" placeholder="Sélectionner une famille" onChange={onFamilyChange}>
                    {Object.keys(ProgramTaskFamily).map((key: any) => (
                        <Select.Option key={'family-' + key} value={key}>{translateTaskFamily((ProgramTaskFamily as any)[key])}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Divider />

            <ProgramSubTaskRepeater
                initialValue={initialValues?.subTasks}
                form={form}
                key={initialValues?.id || 'program-subtask-repeater'}
            />

            <Divider />

            <Form.Item
                label="Durée de vie de l'action (semaines)"
                name="weeksDuration"
            >
                <InputNumber
                    size="large"
                    placeholder="Indiquez une durée de vie"
                    min={0}
                />
            </Form.Item>

            {familyHasBadge() && (
                <Form.Item
                    label="Badge"
                    name="badge"
                >
                    <Select size="large" placeholder="Sélectionner un badge">
                        <Select.Option value="">Sélectionner un badge</Select.Option>
                        {Object.keys(BadgeLevel).map((key: any) => (
                            <Select.Option key={'badge-' + key} value={key}>{translateBadgeLevel((BadgeLevel as any)[key])}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            {!isFromLibrary && (
                <div className="switch-divider">
                    <Divider />
                    <Form.Item
                        label="Enregistrer l'action dans la bibliothèque"
                        name="saveAsTemplate"
                        className="switch-row"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Divider />
                </div>
            )}

            <br />
            <Form.Item className="cta-submit">
                <Button
                    type="primary"
                    size="large"
                    shape="round"
                    block
                    htmlType="submit"
                    loading={loading}
                >
                    {initialValues ? 'Enregistrer les modifications' : 'Créer l\'action'}
                </Button>
            </Form.Item>

        </Form>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
    },
)(ProgramTaskForm);
