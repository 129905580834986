import { Organization } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

export interface OrganizationDetailsPayload {
    id: string;
}

export interface OrganizationUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type OrganizationListPayload = SearchPaginationQuery;

export const details = (payload: OrganizationDetailsPayload) => api.get(`/organizations/${payload.id}`);

export const list = (payload: OrganizationListPayload = {}) => {
    return api.get(`/organizations`, {params: cleanPayload(payload)});
};

export const create = (payload: Organization) => {
    return api.post(`/organizations`, payload);
};

export const update = (payload: Organization) => {
    const id: string = payload.id;
    delete payload.id;
    const formData = serialize(payload);
    return api.put(`/organizations/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const remove = (payload: Organization) => {
    return api.delete(`/organizations/${payload.id}`);
};

export const upload = (payload: OrganizationUploadPayload) => {

    const formData = new FormData();
    formData.append(payload.type, payload.file);

    return api.put(`/organizations/${payload.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
