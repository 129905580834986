import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Input, Spin, notification, Select, message } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { LibraryDocument, LibraryDocumentType, SupportedLanguage, ProgramTaskFamily } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    LibraryDocumentsState,
    details as libraryDocumentDetails,
    update as libraryDocumentUpdate,
    create as libraryDocumentCreate,
} from '../../../store/actions/libraryDocuments';

import {
    create as administratorCreate,
} from '../../../store/actions/administrators';
import { translateLibraryDocumentType, translateLanguage, translateTaskFamily } from '../../../helpers/i18n';
import InputFile from '../../../components/InputFile';
import TextArea from 'antd/lib/input/TextArea';

interface LibraryDocumentDrawerProps extends RouteProps {
    id?: LibraryDocument['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    libraryDocuments: LibraryDocumentsState;
    getDetails: typeof libraryDocumentDetails.trigger;
    detailsReset: typeof libraryDocumentDetails.reset;
    update: typeof libraryDocumentUpdate.trigger;
    updateReset: typeof libraryDocumentUpdate.reset;
    create: typeof libraryDocumentCreate.trigger;
    createReset: typeof libraryDocumentCreate.reset;
}

const LibraryDocumentDrawer: FC<LibraryDocumentDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    libraryDocuments,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const libraryDocument: LibraryDocument | undefined = libraryDocuments.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        detailsReset();

        if (isVisible && id) {
            getDetails({id});
        } else if (isVisible && !id) {
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (libraryDocuments.details.data) {
            form.setFieldsValue(libraryDocuments.details.data);
        }
    }, [libraryDocuments.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (libraryDocuments.update.success || libraryDocuments.create.success) {

            form.resetFields();
            updateReset();
            createReset();

            if (onSuccess) {
                onSuccess();
            }

            message.success('Le document a été ' + ((libraryDocuments.create.success) ? 'ajouté' : 'modifié') + ' avec succès');
        }

        if (libraryDocuments.update.error || libraryDocuments.create.error) {

            const errorMessage = (libraryDocuments.create?.error?.status === 409 || libraryDocuments.update?.error?.status === 409) ?
                                    'Ce code est déjà utilisé' :
                                    'Une erreur est survenue lors de la sauvegarde';

            updateReset();
            createReset();

            notification.error({
                message: errorMessage,
                placement: 'bottomRight',
            });
        }
    }, [libraryDocuments.update.success, libraryDocuments.create.success, libraryDocuments.update.error, libraryDocuments.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="libraryDocument-drawer"
            title={(!id) ? 'Ajouter un document' : 'Editer le document'}
            width={600}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={libraryDocuments.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >

                    <Form.Item
                        label="Titre"
                        name={['name', 'en']}
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir le nom" />
                    </Form.Item>

                    <Form.Item
                        label="Code"
                        name={['code']}
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir un code" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name={['description', 'en']}
                    >
                        <TextArea placeholder="Saisir une description" />
                    </Form.Item>

                    <Form.Item
                        label="Langue"
                        name="language"
                        rules={[requiredRule]}
                    >
                        <Select size="large" placeholder="Sélectionner une langue">
                            {Object.keys(SupportedLanguage).map((key: any) => (
                                <Select.Option key={'language-' + key} value={key}>{translateLanguage((SupportedLanguage as any)[key])}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Famille"
                        name="family"
                        rules={[requiredRule]}
                    >
                        <Select size="large" placeholder="Sélectionner une famille">
                            {Object.keys(ProgramTaskFamily).map((key: any) => (
                                <Select.Option key={'family-' + key} value={key}>{translateTaskFamily((ProgramTaskFamily as any)[key])}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[requiredRule]}
                    >
                        <Select size="large" placeholder="Sélectionner un type">
                            {Object.keys(LibraryDocumentType).map((key: any) => (
                                <Select.Option key={'type-' + key} value={key}>{translateLibraryDocumentType((LibraryDocumentType as any)[key])}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Fichier"
                        name={['document']}
                        rules={[requiredRule]}
                    >
                        <InputFile
                            initialValue={libraryDocument?.url}
                            format=" "
                        />
                    </Form.Item>

                    <br />
                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={libraryDocuments.create.loading || libraryDocuments.update.loading}
                        >
                            {libraryDocument ? 'Enregistrer les modifications' : 'Ajouter le document'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    libraryDocuments: state.libraryDocuments,
});

export default connect(
    mapStateToProps,
    {
        getDetails: libraryDocumentDetails.trigger,
        detailsReset: libraryDocumentDetails.reset,
        create: libraryDocumentCreate.trigger,
        createReset: libraryDocumentCreate.reset,
        update: libraryDocumentUpdate.trigger,
        updateReset: libraryDocumentUpdate.reset,
        createAdmin: administratorCreate.trigger,
    },
)(LibraryDocumentDrawer);
