import { User } from './apiTypes';
import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';

export interface UserDetailsPayload {
    id: string;
}

export type UserListPayload = SearchPaginationQuery;

export const details = (payload: UserDetailsPayload) => api.get(`/users/${payload.id}`);

export const list = (payload: UserListPayload = {}) => {
    return api.get(`/users`, {params: cleanPayload(payload)});
};

export const create = (payload: User) => {
    return api.post(`/users`, payload);
};

export const update = (payload: User) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/users/${id}`, payload);
};

export const remove = (payload: User) => {
    return api.delete(`/users/${payload.id}`);
};

export const roles = (payload: UserListPayload = {}) => {
    return api.get(`/roles`, {params: cleanPayload(payload)});
};
