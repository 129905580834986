import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { Spin, ConfigProvider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CookiesProvider } from 'react-cookie';
import frFR from 'antd/es/locale/fr_FR';

// tslint:disable-next-line: no-submodule-imports
import 'react-quill/dist/quill.snow.css';
import './assets/styles/Quill.less';

import './assets/styles/AntOverride.less';

import { UniversalCookie } from './store/actions/cookies';
import configureStore from './store/configureStore';

import App from './App';

import moment from 'moment';
// tslint:disable-next-line: no-submodule-imports
import 'moment/locale/fr';
moment.locale('fr');

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([
    { xs: 0 },
    { sm: 480 },
    { md: 576 },
    { lg: 768 },
    { xl: 992 },
    { xxl: 1200 },
    { xxxl: 1600 },
]);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    return ReactDOM.render(
        (
            <Provider store={store}>
                <ConfigProvider locale={frFR}>
                    <CookiesProvider cookies={UniversalCookie}>
                        <BrowserRouter>
                            <BreakpointProvider>
                                <Component />
                            </BreakpointProvider>
                        </BrowserRouter>
                    </CookiesProvider>
                </ConfigProvider>
            </Provider>
        ),
        document.getElementById('root') as HTMLElement,
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
