import React, { FC, useState, forwardRef, useEffect } from 'react';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import { ProgramSubTask, DndType } from '../store/api/apiTypes';
import { Button } from 'antd';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import ProgramSubTaskItem from './ProgramSubTaskItem';
import { DragDropContext, Droppable, DragDropContextProps, Draggable } from 'react-beautiful-dnd';
import { reorder } from '../hooks';
import { FormInstance } from 'antd/lib/form';

interface ProgramSubTaskRepeaterProps {
    onChange?: (value: any) => void;
    value ?: ProgramSubTask[];
    initialValue ?: ProgramSubTask[];
    setValue ?: any;
    form: FormInstance;
}

const ProgramSubTaskRepeater: FC<ProgramSubTaskRepeaterProps> = forwardRef((props, ref: any) => {

    const { form } = props;
    const [ value, setValue ] = useState<ProgramSubTask[]>();
    const [ initialized, setInitialized ] = useState<boolean>(false);

    useEffect(() => {
        setValue(undefined);
        setInitialized(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.initialValue && !initialized) {

            props.initialValue.forEach((item, index) => {
                if (item) {
                    item.formIndex = index;
                }
            });

            setValue(props.initialValue);
            setInitialized(true);
            form.setFieldsValue({
                subTasks: props.initialValue,
            });
        }

    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // if (onChange) { onChange(value); }
        // console.log('value changed', value);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const createNewEmptySubTask = () => {
        return {
            formIndex: (value?.length || 0),
        };
    };

    const add = () => {
        let newValue: ProgramSubTask[] = (value) ? setIndexes([...value, ...[createNewEmptySubTask()]]) : [createNewEmptySubTask()];
        newValue = newValue.filter((el: any) => el != null);
        setValue(setIndexes(newValue));
    };

    // ---------------------------------------
    // Drag&Drop reorder

    const setIndexes = (subtasks: ProgramSubTask[]) => {
        if (subtasks) {
            return subtasks.map((subtask, index) => ({...subtask, index}));
        }
        return [];
    };

    const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
        if (
            (!result.destination)
            || (
                result.destination.droppableId === result.source.droppableId
                && result.destination.index === result.source.index
            )
        ) {
          return;
        }

        if (result.type === DndType.subtask && value) {
            let newSubtasksOrder = reorder(
                value,
                result.source.index,
                result.destination.index,
            );
            newSubtasksOrder = setIndexes(newSubtasksOrder);
            setValue(newSubtasksOrder);
            updateSubtasksIndexes(newSubtasksOrder);
        }
    };

    const updateSubtasksIndexes = (newSubtasks: ProgramSubTask[]) => {
        const task = form.getFieldsValue();
        if (task.subTasks) {
            newSubtasks.forEach((subtask: ProgramSubTask, index) => {
                task.subTasks[subtask.formIndex].index = index;
            });
            form.setFieldsValue(task);
        }
    };

    // ---------------------------------------
    // Remove

    const onSubtaskRemove = (subtask: ProgramSubTask, index: number) => {
        if (value) {
            const newValue = [...value];
            newValue.splice(index, 1);
            const newSubtasks = setIndexes(newValue);
            setValue(newSubtasks);
            updateSubtasksIndexes(newSubtasks);
        }
    };

    // ---------------------------------------
    // Render

    return (
        <div className={'subtasks-repeater ' + ((value && value.length >= 2) ? 'dragdrop' : 'no-dragdrop')}>
            <h3>Comportement(s)</h3>

            {(value && value.length > 0) && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="subtasks-list" type={DndType.subtask}>
                        {(provided: any) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {value && value.map((subtask, index) => (
                                    <div className="subtask-draggable-container" key={'subtask-drag-' + subtask.formIndex}>
                                        <Draggable draggableId={'subtask-drag-' + subtask.formIndex} index={index}>
                                            {(dragProvided: any) => (
                                                <div className="subtask-draggable" ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                                    <ProgramSubTaskItem
                                                        index={index}
                                                        subtask={subtask}
                                                        form={form}
                                                        handle={(
                                                            <MenuOutlined className="handle" {...dragProvided.dragHandleProps} />
                                                        )}
                                                        onRemove={onSubtaskRemove}
                                                    />
                                                    {dragProvided.placeholder}
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <Button
                type="text"
                className="add-new"
                onClick={add}
            >
                <PlusOutlined />
                Ajouter un comportement
            </Button>
        </div>
    );

});

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
    },
    null,
    { forwardRef: true },
)(ProgramSubTaskRepeater);
