import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import {
    Button,
    Card,
    Menu,
    Popconfirm,
    Dropdown,
} from 'antd';

import { Pack } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import {
    remove as packsRemove,
} from '../../../store/actions/packs';

import { EditFilled, EllipsisOutlined } from '@ant-design/icons';
import TruncatedText from '../../../components/TruncatedText';
import IconCalendar from '../../../components/icons/IconCalendar';
import IconUser from '../../../components/icons/IconUser';
import moment from 'moment';

interface PackItemProps {
    pack: Pack;
    onEdit: (pack: Pack) => void;
    onRenew: (pack: Pack) => void;
    remove: typeof packsRemove.trigger;
    resetRemove: typeof packsRemove.reset;
}

const PackItem: FC<PackItemProps> = ({
    pack,
    onEdit,
    onRenew,
    remove,
}) => {

    const [ moreMenuVisible, setMoreMenuVisible ] = useState(false);

    // ---------------------------------------
    // Edit / Delete / Renew pack

    const edit = () => {
        onEdit(pack);
    };

    const renew = () => {
        setMoreMenuVisible(false);
        onRenew(pack);
    };

    const removeItem = () => {
        remove({id: pack.id});
    };

    // ---------------------------------------
    // Render

    const moreMenuVisibleChange = (isVisible: boolean) => {
        setMoreMenuVisible(isVisible);
    };

    const actionsMenu = () => (
        <Menu>
            <Menu.Item onClick={renew}>
                Renouveler le pack
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Confirmez-vous la suppression ?"
                    onConfirm={removeItem}
                    okText="Confirmer"
                    cancelText="Annuler"
                    placement="topRight"
                >
                    <div>Supprimer</div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <Card
            key={'pack' + pack.id}
            actions={[
                <Button icon={<EditFilled />} type="text" size="small" key="edit" onClick={edit} />,
                (
                    <Dropdown
                        overlay={actionsMenu}
                        placement="bottomRight"
                        trigger={['click']}
                        onVisibleChange={moreMenuVisibleChange}
                        visible={moreMenuVisible}
                        key="org-more"
                    >
                        <Button icon={<EllipsisOutlined />} type="text" size="small" key="ellipsis" />
                    </Dropdown>
                ),
            ]}
        >
            <h2>{pack.name}</h2>
            <TruncatedText maxHeight={40} showExpand={false}>
                {pack.description}
            </TruncatedText>
            <div className="icon-row"><IconCalendar /> {moment(pack.period.fromDate).format('DD/MM/YYYY')} - {moment(pack.period.toDate).format('DD/MM/YYYY')}</div>
            <div className="icon-row"><IconUser /> {pack.maxLicenses} sièges ({pack.maxLicenses - pack.licensesCount} dispo.)</div>
        </Card>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
        remove: packsRemove.trigger,
        resetRemove: packsRemove.reset,
    },
)(PackItem);
