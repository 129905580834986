import React, { FC, useState, useLayoutEffect, useRef, ReactChild } from 'react';
import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import '../assets/styles/TruncatedText.less';

interface TruncatedTextProps {
    expandText?: string | ReactChild;
    expandedText?: string | ReactChild;
    maxHeight: number;
    withFade?: boolean;
    showExpand?: boolean;
}

const TruncatedText: FC<TruncatedTextProps> = ({
    expandText, expandedText, children, maxHeight, withFade, showExpand,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isElementTooShort, setIsElementTooShort] = useState(true);
    const [elementFullHeight, setElementFullHeight] = useState(0);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const onToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useLayoutEffect(() => {
        // Dirty hack to wait for images load
        // TODO - Find a better way to detect images load
        setTimeout(() => {
            if (wrapperRef.current) {
                setElementFullHeight(wrapperRef.current.scrollHeight);
                setIsElementTooShort(wrapperRef.current.scrollHeight < maxHeight);
            }
        }, 1000);
    }, [maxHeight]);

    return (
        <div className={`truncated-text${isExpanded ? ' is-expanded' : ''}`}>
            <div
                className="truncated-text-inner"
                ref={wrapperRef}
                style={{ maxHeight: isExpanded ? elementFullHeight : maxHeight }}
            >
                {children}
            </div>
            {(!isElementTooShort && showExpand) && (
                <>
                    {withFade && <div className="truncated-text-fade" />}
                    <Button type="link" onClick={onToggle}>
                        {isExpanded ?
                            (
                                <>
                                    {expandedText || 'Voir moins'}
                                    <UpOutlined />
                                </>
                            ) :
                            (
                                <>
                                    {expandText || 'Voir plus'}
                                    <DownOutlined />
                                </>
                            )
                        }
                    </Button>
                </>
            )}
        </div>
    );
};

export default TruncatedText;
