import React, { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LayoutFilled, CheckSquareFilled, QuestionCircleFilled } from '@ant-design/icons';
import { SiderProps } from 'antd/lib/layout';

import logo from '../assets/images/logo.jpg';
import { LayoutContext } from '../context/LayoutContext';
import { getRoute, RoutePathName } from '../routes';
import IconUsers from './icons/IconUsers';
import { Permission, User } from '../store/api/apiTypes';
import SubMenu from 'antd/lib/menu/SubMenu';
import { hasPermission } from '../helpers/security';
import { MainReducerState } from '../store/reducers';
import { getUser } from '../store/actions/auth';
import { connect } from 'react-redux';
import IconPrograms from './icons/IconPrograms';
import IconDocument from './icons/IconDocument';
import IconUserAdd from './icons/IconUserAdd';
import IconSpeaker from './icons/IconSpeaker';

interface MenuItem {
    key: RoutePathName;
    label: string;
    icon?: any;
    children?: MenuItem[];
    disabled?: boolean;
    permission?: Permission;
}

interface SidebarProps {
    user?: User;
}

const Sidebar: FC<SidebarProps> = ({user}) => {
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(LayoutContext);
    const location = useLocation();
    const onCollapse: SiderProps['onCollapse'] = (value) => {
        setIsSidebarCollapsed(value);
    };

    const menu: MenuItem[] = [
        {key: RoutePathName.organizations, label: 'Clients', icon: <IconUsers /> },
        {key: RoutePathName.library, label: 'Bibliothèque', icon: <IconPrograms />, children: [
            {key: RoutePathName.library_programs, label: 'Programmes', icon: <IconPrograms />},
            {key: RoutePathName.library_actions, label: 'Actions', icon: <CheckSquareFilled />},
            {key: RoutePathName.library_documents, label: 'Documents', icon: <IconDocument />},
            {key: RoutePathName.library_notifications, label: 'Notifications', icon: <IconSpeaker />},
        ]},
        {key: RoutePathName.website, label: 'Site web', icon: <LayoutFilled />, children: [
            {key: RoutePathName.website_pages, label: 'Pages', icon: <LayoutFilled />},
            {key: RoutePathName.website_faq, label: 'FAQ', icon: <QuestionCircleFilled />},
            // {key: RoutePathName.website_translations, label: 'Traductions', icon: <TranslationOutlined />},
        ]},
        {key: RoutePathName.users, label: 'Utilisateurs', icon: <IconUserAdd />, permission: Permission.SuperAdmin },
    ];

    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={null}
            breakpoint="lg"
            id="sidebar"
        >
            <div id="logo-wrapper">
                <Link to={getRoute(RoutePathName.organizations)}>
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
            >
                {menu.map((item) => {
                    if (!hasPermission(user, item.permission)) { return false; }

                    if (!item.children) {
                        return (
                            <Menu.Item key={getRoute(item.key)} disabled={item.disabled}>
                                <Link to={getRoute(item.key)}>
                                    {item.icon}
                                    <span>{item.label}</span>
                                </Link>
                            </Menu.Item>
                        );
                    } else {
                        return (
                            <SubMenu
                                key={getRoute(item.key)}
                                disabled={item.disabled}
                                title={(
                                    <span>
                                        {item.icon}
                                        <span>{item.label}</span>
                                    </span>
                                )}
                            >
                                {item.children.map((subItem) => {
                                    if (!hasPermission(user, subItem.permission)) { return false; }

                                    return (
                                        <Menu.Item key={getRoute(subItem.key)} disabled={subItem.disabled}>
                                            <Link to={getRoute(subItem.key)}>
                                                {subItem.icon}
                                                <span>{subItem.label}</span>
                                            </Link>
                                        </Menu.Item>
                                    );
                                })}
                            </SubMenu>
                        );
                    }
                })}
            </Menu>
        </Layout.Sider>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Sidebar);
