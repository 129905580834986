import React, { FC, forwardRef } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Store } from 'antd/lib/form/interface';

import { SurveyAnswerTricamLetters } from '../store/api/apiTypes';

interface SurveyTricamSelectProps extends SelectProps<SurveyAnswerTricamLetters> {
    disabledValues?: Store[];
}

const SurveyTricamSelect: FC<SurveyTricamSelectProps> = forwardRef(({ disabledValues, ...props }, ref: any) => (
    <Select
        ref={ref}
        filterOption={false}
        style={{ width: '100%' }}
        placeholder="Choisir la lettre"
        allowClear
        {...props}
    >
        {
            (Object.keys(SurveyAnswerTricamLetters) as Array<keyof typeof SurveyAnswerTricamLetters>)
                .filter((key) => !disabledValues?.includes(SurveyAnswerTricamLetters[key] as unknown as Store))
                .map((key) => (
                <Select.Option value={key} key={key}>
                    {SurveyAnswerTricamLetters[key].toUpperCase()}
                </Select.Option>
            ))
        }
    </Select>
));

SurveyTricamSelect.displayName = 'SurveyTricamSelect';

export default SurveyTricamSelect;
