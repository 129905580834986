import React, { FC } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../../store/reducers';
import { Collapse, Button, Badge } from 'antd';
import { QuestionSection, DndType, Question } from '../../../store/api/apiTypes';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { DeleteFilled, PlusOutlined, EditFilled, MenuOutlined } from '@ant-design/icons';
import { QuestionSectionsState } from '../../../store/actions/questionSections';
import { QuestionsState } from '../../../store/actions/questions';
import { i18n } from '../../../helpers/i18n';
import QuestionItem from './QuestionItem';

const { Panel } = Collapse;

interface QuestionSectionsProps {
    section: QuestionSection;
    index: number;
    handle: any;

    onEdit: (section: QuestionSection) => void;
    onRemove: (section: QuestionSection) => void;
    onQuestionCreate: (section: QuestionSection) => void;
    onQuestionEdit: (section: QuestionSection, question: Question) => void;

    questionSections: QuestionSectionsState;
    questions: QuestionsState;
}

const QuestionSectionItem: FC<QuestionSectionsProps> = ({
    section,
    index,
    handle,

    onEdit,
    onRemove,
    onQuestionCreate,
    onQuestionEdit,

    questionSections,
    questions,
}) => {

    // ---------------------------------------
    // Edit / Delete section

    const edit = (e: any) => {
        e.stopPropagation();
        onEdit(section);
    };

    const remove = (e: any) => {
        e.stopPropagation();
        onRemove(section);
    };

    // ---------------------------------------
    // Add / Edit action

    const editQuestion = (question: Question) => {
        onQuestionEdit(section, question);
    };

    const addQuestion = () => {
        onQuestionCreate(section);
    };

    // ---------------------------------------
    // Render

    return (
        <Collapse className="program-week" expandIconPosition="right">
            <Panel
                header={(
                    <span className="title">
                        {handle}
                        <span>{i18n(section.title)}</span>
                        <span className="points">{section.questions.length} question{(section.questions.length > 1) && 's'}</span>
                    </span>
                )}
                key="1"
                extra={(
                    <>
                        <Button
                            icon={<EditFilled />}
                            shape="circle"
                            type="ghost"
                            size="small"
                            onClick={edit}
                        />
                        &nbsp;&nbsp;
                        <Button
                            loading={questionSections.remove.loading && questionSections.remove.payload.id === section.id}
                            icon={<DeleteFilled />}
                            shape="circle"
                            type="ghost"
                            size="small"
                            onClick={remove}
                        />
                    </>
                )}
            >
                <div className="page-header">
                    <h3 className="page-title">
                        Questions <Badge count={section.questions.length} showZero={true} />
                    </h3>
                </div>

                <Droppable droppableId={section.id} type={DndType.question}>
                    {(subProvided: any) => (
                        <div className="program-task-table no-head" ref={subProvided.innerRef} {...subProvided.droppableProps}>
                            {section.questions.map((question: Question, qIndex: number) => (
                                <div className="program-task-table-row" key={'question' + question.id}>
                                    <Draggable draggableId={question.id} index={qIndex}>
                                        {(subDragProvided: any, snapshot: any) => (
                                            <div className={'program-task-draggable ' + ((snapshot.isDragging) ? 'dragging' : '')} ref={subDragProvided.innerRef} {...subDragProvided.draggableProps}>
                                                <QuestionItem
                                                    question={question}
                                                    onEdit={editQuestion}
                                                    handle={(
                                                        <MenuOutlined className="handle" {...subDragProvided.dragHandleProps} />
                                                    )}
                                                />
                                                {subDragProvided.placeholder}
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}
                            {subProvided.placeholder}
                        </div>
                    )}
                </Droppable>

                <Button
                    loading={questions.create.loading}
                    type="text"
                    className="add-new"
                    onClick={addQuestion}
                >
                    <PlusOutlined />
                    Ajouter une question
                </Button>
            </Panel>
        </Collapse>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    questionSections: state.questionSections,
    questions: state.questions,
});

export default connect(
    mapStateToProps,
    {
    },
)(QuestionSectionItem);
