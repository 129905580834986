import { LibraryProgramTask } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';

export interface LibraryProgramTaskDetailsPayload {
    id: string;
}

export interface LibraryProgramTaskUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type LibraryProgramTaskListPayload = SearchPaginationQuery;

export const details = (payload: LibraryProgramTaskDetailsPayload) => api.get(`/programs/tasks/${payload.id}`);

export const list = (payload: LibraryProgramTaskListPayload = {}) => {
    return api.get(`/library/tasks`, {params: cleanPayload(payload)});
};

export const create = (payload: LibraryProgramTask) => {
    payload.saveAsTemplate = true;
    return api.post(`/library/tasks`, payload);
};

export const update = (payload: LibraryProgramTask) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/programs/tasks/${id}`, payload);
};

export const remove = (payload: LibraryProgramTask) => {
    return api.delete(`/programs/tasks/${payload.id}`);
};
