import React, { FC, useEffect, useState, Fragment, ChangeEvent } from 'react';
import { Button, Drawer, Form, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

import { MainReducerState } from '../store/reducers';
import { ProgramTaskSurveyQuestion, SurveyAnswerType, ProgramTaskSurvey } from '../store/api/apiTypes';

import { translateSurveyAnswerType } from '../helpers/i18n';

interface BasicSurveyDrawerProps extends RouteProps {
    isVisible: boolean;
    initialValue?: ProgramTaskSurvey;
    onClose: () => void;
    onSuccess?: (values: ProgramTaskSurvey) => void;
}

const BasicSurveyDrawer: FC<BasicSurveyDrawerProps> = ({
    isVisible,
    initialValue,
    onClose,
    onSuccess,
}) => {

    const [value, setValue] = useState<ProgramTaskSurveyQuestion[]>();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) {
            if (initialValue) {
                form.setFieldsValue({
                    questions: initialValue?.questions,
                });
                setValue(initialValue?.questions);
            }
        } else {
            form.resetFields();
            form.setFieldsValue({
                questions: [{

                }],
            });
        }
    }, [isVisible, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        onSuccess?.(values as ProgramTaskSurvey);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onQuestionTypeChange = () => {
        setValue(form.getFieldsValue().questions);
    };

    const onAnswerValueChange = (fieldName: Array<string | number>, e: ChangeEvent<HTMLInputElement>) => {
        form.setFields([{
            name: fieldName,
            value: e.target.value,
        }]);
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="survey-drawer"
            title="Questionnaire"
            width={600}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Form
                form={form}
                onFinish={onFormFinish}
                layout="vertical"
                hideRequiredMark
            >
                <Form.List name="questions">
                    {(questionFields, { add, remove }) => {
                        return (
                        <>
                            {questionFields.map((questionField, index) => (
                                <Fragment key={questionField.key}>
                                    {index > 0 && <br />}
                                    <div className="questions-list">
                                        <div className="form-title-with-delete">
                                            <h3>Question n°{index + 1}</h3>
                                            {index > 0 && (
                                                <Button
                                                    className="remove-item"
                                                    type="ghost"
                                                    shape="circle"
                                                    icon={<DeleteFilled />}
                                                    // tslint:disable-next-line: jsx-no-lambda
                                                    onClick={() => { remove(index); }}
                                                />
                                            )}
                                        </div>

                                        <Form.Item
                                            label={'Question'}
                                            name={[questionField.name, 'label', 'en']}
                                            rules={[requiredRule]}
                                        >
                                            <Input placeholder="Saisir une question" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Type"
                                            name={[questionField.name, 'type']}
                                            rules={[requiredRule]}
                                        >
                                            <Select size="large" placeholder="Sélectionner un type" onChange={onQuestionTypeChange}>
                                                {(Object.keys(SurveyAnswerType) as Array<keyof typeof SurveyAnswerType>).filter((key) => key !== 'multiValue').map((key) => (
                                                    <Select.Option key={'SurveyAnswerType-' + key} value={key}>{translateSurveyAnswerType(SurveyAnswerType[key])}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        {(value && value[index] && value[index].type === SurveyAnswerType.list) && (
                                            <Form.List name={[questionField.name, 'answers']}>
                                                {
                                                    // tslint:disable-next-line: no-shadowed-variable
                                                    (answerFields, { add, remove }) => {
                                                    return (
                                                        <div className="answers-list">
                                                            {answerFields.map((answerField, indexAnswer) => (
                                                                <div key={answerField.key}>
                                                                    <div className="form-item-with-delete">
                                                                        <Form.Item
                                                                            label={'Réponse ' + (indexAnswer + 1)}
                                                                            name={[answerField.name, 'values', 0]}
                                                                            rules={[requiredRule]}
                                                                        >
                                                                            <Input
                                                                                placeholder="Saisir une réponse"
                                                                                size="large"
                                                                                onChange={onAnswerValueChange.bind(null, ['questions', questionField.name, 'answers', answerField.name, 'label', 'en'])}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item name={[answerField.name, 'label', 'en']} className="hidden-input">
                                                                            <Input />
                                                                        </Form.Item>

                                                                        <Button
                                                                            className="remove-item"
                                                                            type="ghost"
                                                                            shape="circle"
                                                                            icon={<DeleteFilled />}
                                                                            // tslint:disable-next-line: jsx-no-lambda
                                                                            onClick={() => { remove(indexAnswer); }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}

                                                            <Button
                                                                className="add-new inline"
                                                                // tslint:disable-next-line: jsx-no-lambda
                                                                onClick={() => { add(); }}
                                                            >
                                                                <PlusOutlined /> Ajouter une réponse
                                                            </Button>
                                                        </div>
                                                    );
                                                }}
                                            </Form.List>
                                        )}
                                    </div>
                                </Fragment>
                            ))}

                            <br/>

                            <Button
                                className="add-new"
                                // tslint:disable-next-line: jsx-no-lambda
                                onClick={() => { add(); }}
                            >
                                <PlusOutlined /> Ajouter une question
                            </Button>
                        </>
                    );
                }}
                </Form.List>

                <br />
                <Form.Item className="cta-submit">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                    >
                        Enregistrer le questionnaire
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
    },
)(BasicSurveyDrawer);
