import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Form, notification as antNotification, Select, message, Input, Typography, Card, Divider, InputNumber, DatePicker } from 'antd';
import { FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import TextArea from 'antd/lib/input/TextArea';
import moment, { Moment } from 'moment';
import { v4 as uuid } from 'uuid';

import {
    createCampaign as notificationCreateCampaign, getNotificationCampaignCreateState, NotificationsState,
} from '../../store/actions/notifications';
import { NotificationChannel, BadgeLevel, BadgeType, Organization, NotificationCampaign } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';

import { translateNotificationChannel, translateBadgeLevel, translateBadgeType } from '../../helpers/i18n';
import UserFilterSelect from './UserFilterSelect';
import ProgramFilterSelect from './programs/ProgramFilterSelect';
import UsersGroupFilterSelect from './UsersGroupFilterSelect';
import IconCalendar from '../../components/icons/IconCalendar';

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

function disabledDate(current: Moment) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'day').endOf('day');
}

function range(start: number, end: number) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

function disabledTime(current: Moment | null) {
    if (current?.isSame(moment(), 'day')) {
        return {
            disabledHours: () => range(0, current.hour()),
            disabledMinutes: () => range(0, current.minute()),
        };
    } else {
        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
        };
    }
}

interface OrganizationManualNotificationDrawerProps {
    id?: Organization['id'];
    isVisible: boolean;
    onClose: () => void;

    createCampaignState: NotificationsState['createCampaign'];
    createCampaign: typeof notificationCreateCampaign.trigger;
    createReset: typeof notificationCreateCampaign.reset;
}

const OrganizationManualNotificationDrawer: FC<OrganizationManualNotificationDrawerProps> = ({
    id,
    isVisible,
    onClose,

    createCampaignState,
    createCampaign,
    createReset,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (createCampaignState.success) {
            form.resetFields();
            createReset();

            message.success('L\'envoi du message a été pris en compte');
        }

        if (createCampaignState.error) {
            createReset();

            antNotification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [createCampaignState.success, createCampaignState.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        const { recipientType, userGroupFilterType, scoringOperand, scoringValue, template, badgeType, badgeLevel, ...restValues } = values;
        const payload: Partial<NotificationCampaign> = {
            ...restValues,
            filters: {
                ...restValues.filters,
                organization: id,
            },
            template: {
                slug: uuid(),
                ...template,
            },
            name: {
                fr: template.title.fr,
            },
            scheduled: values.scheduled.set({ second: 0, millisecond: 0 }).toISOString(),
        };

        if (userGroupFilterType === 'scoring' && scoringOperand && scoringValue) {
            payload.filters!.scoring = `${scoringOperand}${scoringValue}`;
        }

        if (userGroupFilterType === 'badge' && badgeType && badgeLevel) {
            payload.filters![`badge_${badgeType}`] = badgeLevel;
        }

        createCampaign(payload);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    return (
        <Drawer
            className="notification-drawer"
            title="Envoyer un message aux utilisateurs"
            width={680}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Form
                form={form}
                onFinish={onFormFinish}
                initialValues={{
                    scoringOperand: '<',
                    scoringValue: 50,
                }}
                layout="vertical"
                hideRequiredMark
            >
                <Typography.Title level={3}>Destinataire(s) du message</Typography.Title>
                <Card className="validation-card">
                    <Form.Item
                        label="Type de destinataire"
                        name="recipientType"
                        rules={[requiredRule]}
                        style={{ marginTop: 24 }}
                    >
                        <Select size="large" placeholder="Sélectionner un type">
                            <Select.Option value="user">Utilisateur spécifique</Select.Option>
                            <Select.Option value="userGroup">Groupe d'utilisateurs</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        // tslint:disable-next-line: jsx-no-lambda
                        shouldUpdate={(prevValues, currentValues) => prevValues.recipientType !== currentValues.recipientType}
                    >
                        {({ getFieldValue }) => getFieldValue('recipientType') === 'user' && (
                            <Form.Item
                                label="Utilisateur ciblé"
                                name={['filters', 'user']}
                                rules={[requiredRule]}
                            >
                                <UserFilterSelect
                                    size="large"
                                    filters={{
                                        filters: [
                                            {
                                                name: 'organization',
                                                value: id,
                                            },
                                        ],
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>

                    <Form.Item
                        noStyle
                        // tslint:disable-next-line: jsx-no-lambda
                        shouldUpdate={(prevValues, currentValues) => prevValues.recipientType !== currentValues.recipientType}
                    >
                        {({ getFieldValue }) => getFieldValue('recipientType') === 'userGroup' && (
                            <Form.Item
                                label="Ciblage des utilisateurs"
                                name="userGroupFilterType"
                                rules={[requiredRule]}
                            >
                                <Select size="large" placeholder="Sélectionner un ciblage">
                                    <Select.Option value="program">Suivant le même programme</Select.Option>
                                    <Select.Option value="group">De la même Business Unit</Select.Option>
                                    <Select.Option value="badge">Avec le même badge</Select.Option>
                                    <Select.Option value="scoring">Avec un scoring spécifique</Select.Option>
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>

                    <Form.Item
                        noStyle
                        // tslint:disable-next-line: jsx-no-lambda
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.recipientType !== currentValues.recipientType ||
                            prevValues.userGroupFilterType !== currentValues.userGroupFilterType
                        }
                    >
                        {({ getFieldValue }) => getFieldValue('recipientType') === 'userGroup' && (
                            <>
                                {getFieldValue('userGroupFilterType') === 'program' && (
                                    <Form.Item
                                        label="Programme"
                                        name={['filters', 'program']}
                                        rules={[requiredRule]}
                                    >
                                        <ProgramFilterSelect
                                            size="large"
                                            filters={{
                                                filters: [
                                                    {
                                                        name: 'organization',
                                                        value: id,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Form.Item>
                                )}
                                {getFieldValue('userGroupFilterType') === 'group' && (
                                    <Form.Item
                                        label="Business Unit"
                                        name={['filters', 'group']}
                                        rules={[requiredRule]}
                                    >
                                        <UsersGroupFilterSelect
                                            size="large"
                                            filters={{
                                                filters: [
                                                    {
                                                        name: 'organization',
                                                        value: id,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Form.Item>
                                )}
                                {getFieldValue('userGroupFilterType') === 'badge' && (
                                    <>
                                        <Form.Item
                                            label="Type de badge"
                                            name="badgeType"
                                            rules={[requiredRule]}
                                        >
                                            <Select size="large" placeholder="Sélectionner un badge">
                                                <Select.Option value="">Sélectionner un badge</Select.Option>
                                                {Object.keys(BadgeType).map((key: any) => (
                                                    <Select.Option key={'badge-' + key} value={key}>{translateBadgeType((BadgeType as any)[key])}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Niveau de badge"
                                            name="badgeLevel"
                                            rules={[requiredRule]}
                                        >
                                            <Select size="large" placeholder="Sélectionner un badge">
                                                <Select.Option value="">Sélectionner un badge</Select.Option>
                                                {Object.keys(BadgeLevel).map((key: any) => (
                                                    <Select.Option key={'badge-' + key} value={key}>{translateBadgeLevel((BadgeLevel as any)[key])}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </>
                                )}
                                {getFieldValue('userGroupFilterType') === 'scoring' && (
                                    <Form.Item label="Scoring">
                                        <Input.Group compact>
                                            <Form.Item
                                                name="scoringOperand"
                                                rules={[requiredRule]}
                                                style={{ width: '36%' }}
                                            >
                                                <Select size="large" style={{ width: '100%' }}>
                                                    <Select.Option value="<">Inférieur à</Select.Option>
                                                    <Select.Option value="<=">Inférieur ou égal à</Select.Option>
                                                    <Select.Option value="=">Égal à</Select.Option>
                                                    <Select.Option value=">">Supérieur à</Select.Option>
                                                    <Select.Option value=">=">Supérieur ou égal à</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="scoringValue"
                                                rules={[
                                                    requiredRule,
                                                    { type: 'number', min: 0, max: 100, message: 'veuillez entrer un nombre entre 0 et 100' },
                                                ]}
                                                style={{ width: '64%' }}
                                            >
                                                <InputNumber
                                                    size="large"
                                                    min={0}
                                                    max={100}
                                                    // tslint:disable-next-line: jsx-no-lambda
                                                    formatter={(value) => `${value}%`}
                                                    // tslint:disable-next-line: jsx-no-lambda
                                                    parser={(value) => value?.replace('%', '') || ''}
                                                />
                                            </Form.Item>
                                        </Input.Group>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.Item>
                </Card>

                <Divider />

                <Form.Item
                    label="Type(s) de notification"
                    name="channels"
                    rules={[requiredRule]}
                >
                    <Select size="large" placeholder="Sélectionner un type" mode="multiple">
                        {Object.keys(NotificationChannel).map((key: any) => (
                            <Select.Option key={'type-' + key} value={key}>{translateNotificationChannel((NotificationChannel as any)[key])}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Titre du message"
                    name={['template', 'title', 'fr']}
                    rules={[requiredRule]}
                >
                    <Input size="large" placeholder="Titre du message/objet d'email" />
                </Form.Item>

                <Form.Item
                    label="Contenu du message"
                    name={['template', 'text', 'fr']}
                    rules={[requiredRule]}
                >
                    <TextArea placeholder="Saisir le contenu du message" />
                </Form.Item>

                <Form.Item
                    label="Date et heure de l'envoi"
                    name="scheduled"
                    rules={[requiredRule]}
                >
                    <DatePicker
                        showTime={{ format: 'HH:mm' }}
                        format="DD/MM/YYYY HH:mm"
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        size="large"
                        style={{ width: '100%' }}
                        suffixIcon={<IconCalendar />}
                    />
                </Form.Item>

                <Form.Item className="cta-submit">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                        loading={createCampaignState.loading}
                    >
                        Valider le message
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    createCampaignState: getNotificationCampaignCreateState(state),
});

export default connect(
    mapStateToProps,
    {
        createCampaign: notificationCreateCampaign.trigger,
        createReset: notificationCreateCampaign.reset,
    },
)(OrganizationManualNotificationDrawer);
