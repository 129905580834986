import React, { FC, useEffect, useState, Fragment } from 'react';
import { Button, Drawer, Form, Input, Row, Col } from 'antd';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

import { ProgramTaskSurveyQuestion, ProgramTaskSurvey, SurveyAnswerType } from '../store/api/apiTypes';

import SurveyColorSelect from '../components/SurveyColorSelect';

interface ColorsSurveyDrawerProps extends RouteProps {
    isVisible: boolean;
    initialValue?: ProgramTaskSurvey;
    onClose: () => void;
    onSuccess?: (values: ProgramTaskSurvey) => void;
}

const ColorsSurveyDrawer: FC<ColorsSurveyDrawerProps> = ({
    isVisible,
    initialValue,
    onClose,
    onSuccess,
}) => {

    const [, setValue] = useState<ProgramTaskSurveyQuestion[]>();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) {
            if (initialValue) {
                form.setFieldsValue({
                    questions: [{
                        ...initialValue?.questions?.[0],
                        type: SurveyAnswerType.multiValue,
                }]});
                setValue(initialValue?.questions);
            }
        } else {
            form.resetFields();
            form.setFieldsValue({
                questions: [{
                    type: SurveyAnswerType.multiValue,
                    answers: [
                        {},
                    ],
                }],
            });
        }
    }, [isVisible, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        onSuccess?.(values as ProgramTaskSurvey);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="survey-drawer"
            title="Test Couleurs - Mots"
            width={600}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Form
                form={form}
                onFinish={onFormFinish}
                layout="vertical"
                hideRequiredMark
            >
                <Form.Item name={['questions', 0, 'type']} className="hidden-input">
                    <Input />
                </Form.Item>
                <Form.List name={['questions', 0, 'answers']}>
                    {(fields, { add, remove }) => {
                        return (
                        <>
                            {fields.map((field, index) => (
                                <Fragment key={field.key}>
                                    {index > 0 && <br />}
                                    <div className="questions-list">
                                        <div className="form-title-with-delete">
                                            <h3>Mot n°{index + 1}</h3>
                                            {index > 0 && (
                                                <Button
                                                    className="remove-item"
                                                    type="ghost"
                                                    shape="circle"
                                                    icon={<DeleteFilled />}
                                                    // tslint:disable-next-line: jsx-no-lambda
                                                    onClick={() => { remove(index); }}
                                                />
                                            )}
                                        </div>
                                        <Row gutter={24}>
                                            <Col xs={12}>
                                                <Form.Item
                                                    label="Mot"
                                                    name={[field.name, 'label', 'en']}
                                                    rules={[requiredRule]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input placeholder="Saisir un mot" size="large" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Item
                                                    label="Couleur"
                                                    name={[field.name, 'values', 0]}
                                                    rules={[requiredRule]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <SurveyColorSelect
                                                        placeholder="Choisir la couleur"
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Fragment>
                            ))}

                            <br/>

                            <Button
                                className="add-new"
                                // tslint:disable-next-line: jsx-no-lambda
                                onClick={() => { add(); }}
                            >
                                <PlusOutlined /> Ajouter un mot
                            </Button>
                        </>
                    );
                }}
                </Form.List>

                <br />
                <Form.Item className="cta-submit">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                    >
                        Enregistrer les mots
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    );

};

export default ColorsSurveyDrawer;
