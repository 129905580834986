import React, { FC, useState, forwardRef, useEffect } from 'react';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';

import { Input, InputNumber, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { InputNumberProps } from 'antd/lib/input-number';

interface InputSecondsProps {
    onChange?: (value: any) => void;
    value ?: number;
    initialValue ?: number;
    setValue ?: any;
}

export enum TimeType {
    seconds = 'seconds',
    minutes = 'minutes',
    hours = 'hours',
}

const InputSeconds: FC<InputSecondsProps> = forwardRef((props, ref: any) => {

    const {onChange} = props;
    const [ value, setValue ] = useState<number>();
    const [ timeValue, setTimeValue ] = useState<number>();
    const [ timeType, setTimeType ] = useState<TimeType>(TimeType.seconds);

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
            setTimeValue(props.initialValue);
            if (onChange) { onChange(props.initialValue); }
        } else {
            setValue(0);
        }
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
            setTimeValue(props.value);
            updateDisplayTimeValue(props.value, timeType);
            if (onChange) { onChange(props.value); }
        }
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTimeTypeChange: SelectProps<TimeType>['onChange'] = (type) => {
        setTimeType(type);
        updateDisplayTimeValue(value, type);
    };

    const onValueChange: InputNumberProps['onChange'] = (val) => {
        console.log(val);
        setTimeValue(val as number);
        onTimeValueChange(val as number);
    };

    const updateDisplayTimeValue = (val?: number, type?: TimeType) => {
        if (val) {
            switch (type) {
                case TimeType.seconds:
                    setTimeValue(val);
                    break;
                case TimeType.minutes:
                    setTimeValue(val / 60);
                    break;
                case TimeType.hours:
                    setTimeValue(val / 60 / 60);
                    break;
            }
        }
    };

    const onTimeValueChange = (val: number) => {
        if (onChange) {
            if (val) {
                switch (timeType) {
                    case TimeType.seconds:
                        setValue(val);
                        onChange(val);
                        break;
                    case TimeType.minutes:
                        setValue(val * 60);
                        onChange(val * 60);
                        break;
                    case TimeType.hours:
                        setValue(val * 60 * 60);
                        onChange(val * 60 * 60);
                        break;
                }
            } else {
                onChange(val);
            }
        }
    };

    return (
        <Input.Group compact>
            <InputNumber
                style={{ width: '75%' }}
                size="large"
                value={timeValue}
                onChange={onValueChange}
            />
            <Select
                value={timeType}
                onChange={onTimeTypeChange}
                style={{ width: '25%' }}
                size="large"
                disabled
            >
                <Select.Option value={TimeType.seconds}>seconde(s)</Select.Option>
                <Select.Option value={TimeType.minutes}>minutes(s)</Select.Option>
                <Select.Option value={TimeType.hours}>heure(s)</Select.Option>
            </Select>
        </Input.Group>
    );

});

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
    },
    null,
    { forwardRef: true },
)(InputSeconds);
