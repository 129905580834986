import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    OrganizationDetailsPayload,
    OrganizationListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    upload as uploadApiCall,
    OrganizationUploadPayload,
} from '../api/organizations';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, Organization } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface OrganizationsState {
    list: RequestState<ListResponse<Organization>>;
    search: RequestState<ListResponse<Organization>>;
    details: RequestState<Organization | undefined>;
    create: RequestState<Organization | undefined>;
    update: RequestState<Organization | undefined>;
    remove: RequestState<Organization | undefined>;
    upload: RequestState<Organization | undefined>;
}

const initialState: OrganizationsState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
    upload: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    OrganizationsState['list'],
    OrganizationListPayload,
    ListResponse<Organization>
>('organizations/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const search = new EzeeAsyncAction<
    OrganizationsState['search'],
    OrganizationListPayload,
    ListResponse<Organization>
>('organizations/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    OrganizationsState['details'],
    OrganizationDetailsPayload,
    Organization
>('organizations/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    OrganizationsState['create'],
    Organization,
    Organization
>('organizations/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    OrganizationsState['update'],
    Organization,
    Organization
>('organizations/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    OrganizationsState['remove'],
    Organization,
    Organization
>('organizations/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

export const upload = new EzeeAsyncAction<
    OrganizationsState['upload'],
    OrganizationUploadPayload,
    Organization
>('operations/upload', initialState.upload, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.upload,
    }),
});

// Reducer

export const organizationsReducer = combineReducers<OrganizationsState>({
    details: details.reducer,
    list: list.reducer,
    search: search.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
    upload: upload.reducer,
});

// Saga

export function* organizationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(upload.type.trigger, simpleAsyncSaga(uploadApiCall, upload));
}

// Store helpers

export const getOrganizationsState = (state: MainReducerState) => state.organizations;
