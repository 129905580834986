import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Input, Spin, message } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { User, Role, RoleSlug } from '../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    UsersState,
    details as userDetails,
    update as userUpdate,
    create as userCreate,
} from '../../store/actions/users';

interface UserDrawerProps extends RouteProps {
    id?: User['id'];
    organizationId?: string;
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    roles: Role[];

    users: UsersState;
    getDetails: typeof userDetails.trigger;
    detailsReset: typeof userDetails.reset;
    update: typeof userUpdate.trigger;
    updateReset: typeof userUpdate.reset;
    create: typeof userCreate.trigger;
    createReset: typeof userCreate.reset;
}

const UserDrawer: FC<UserDrawerProps> = ({
    id,
    organizationId,
    isVisible,
    onClose,
    onSuccess,
    roles,

    users,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const user: User | undefined = users.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
        } else {
            detailsReset();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.details.data) {
            form.setFieldsValue(users.details.data);
        }
    }, [users.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.update.success || users.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (users.update.error || users.create.error) {
            if (users.create.error?.data?.fields?.email || users.update.error?.data?.fields?.email) {
                message.error('L\'email indiqué est déjà utilisé');
            } else {
                message.error('Une erreur est survenue lors de la sauvegarde de cet utilisateur');
            }

            updateReset();
            createReset();
        }
    }, [users.update.success, users.create.success, users.update.error, users.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update(values);
            } else {
                create({
                    ...values,
                    organization: organizationId,
                    sendWelcome: true,
                    role: RoleSlug.superAdmin,
                });
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    return (
        <Drawer
            className="user-drawer"
            title={(!id) ? 'Créer un utilisateur' : 'Editer un utilisateur'}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={users.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Form.Item
                        label="Nom"
                        name="lastName"
                        rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        label="Prénom"
                        name="firstName"
                        rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                    >
                        <Input size="large" autoComplete="none" />
                    </Form.Item>

                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={users.create.loading || users.update.loading}
                        >
                            {user ? 'Enregistrer les modifications' : 'Créer l\'utilisateur'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    users: state.users,
});

export default connect(
    mapStateToProps,
    {
        getDetails: userDetails.trigger,
        detailsReset: userDetails.reset,
        create: userCreate.trigger,
        createReset: userCreate.reset,
        update: userUpdate.trigger,
        updateReset: userUpdate.reset,
    },
)(UserDrawer);
