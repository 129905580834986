import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Button, Alert, Typography, Spin } from 'antd';

import { AuthState, getAuthState, resetPassword, resendInvitation as resendInvitationAction } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';

import Seo from '../../components/Seo';
import PasswordInput from '../../components/PasswordInput';
import ButtonLink from '../../components/ButtonLink';
import { checkIfTokenExpired } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { useQueryParams } from '../../hooks';
import LoginLayout from '../../components/LoginLayout';
import { FormProps } from 'antd/lib/form';
import validatePasswordRules from '../../helpers/passwords';

interface ResetPasswordProps extends RouteComponentProps {
    resetResetPassword: typeof resetPassword.reset;
    resendInvitation: typeof resendInvitationAction.trigger;
    sendResetPassword: typeof resetPassword.trigger;
    authState: AuthState;
}

const ResetPassword: FC<ResetPasswordProps> = ({ authState, resetResetPassword, resendInvitation, sendResetPassword }) => {
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const queryParams = useQueryParams();
    const isFirstSetup = queryParams.get('firstSetup');
    const token = queryParams.get('token');
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        sendResetPassword({
            ...values,
            token,
        });
    };
    const onClickSendNewInvitation = () => {
        resendInvitation({ token });
    };
    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    let error = (authState.resetPasswordError || authState.resendInvitationError) ? 'Erreur' : null;

    if (authState.resetPasswordError) {
        if (isFirstSetup) {
            error = 'Nous sommes désolés, une erreur inattendue est survenue, veuillez réessayer plus tard.';
        } else if (authState.resetPasswordError?.status === 404) {
            error = 'Êtes-vous sûr d\'avoir saisi la bonne adresse e-mail ?';
        } else if (authState.resetPasswordError?.status === 400) {
            error = 'Veuillez entrer une adresse email valide';
        } else {
            error = 'Nous sommes désolés, une erreur inattendue est survenue, veuillez réessayer plus tard.';
        }
    }

    const formContent = authState.resetPasswordSuccess ? (
        <>
            <Typography.Paragraph>
                {isFirstSetup ?
                    'Votre mot de passe a été enregistré avec succès' :
                    'Votre mot de passe a été modifié avec succès'
                }
            </Typography.Paragraph>
            <ButtonLink
                to={
                    isFirstSetup ?
                        getRoute(RoutePathName.login) :
                        getRoute(RoutePathName.home)
                }
                type="primary"
            >
                {isFirstSetup ?
                    'Se connecter' :
                    'Continuer vers Mon coach Mobilité'
                }
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>
                Merci de saisir un nouveau mot de passe ci-dessous
            </Typography.Paragraph>
            <Form.Item
                label="Nouveau mot de passe"
                rules={[
                    requiredRule,
                    {
                        validator: (_, value) => {
                            if (!validatePasswordRules(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Veuillez entrer un mot de passe valide');
                        },
                    },
                ]}
                name="password"
                validateTrigger="onBlur"
            >
                <PasswordInput
                    placeholder="Saisissez votre nouveau mot de passe"
                    size="large"
                />
            </Form.Item>
            <Form.Item>
                {error ? (
                    <div className="login-error-message">
                        <Alert
                            type="error"
                            message={error}
                            showIcon
                        />
                    </div>
                ) : null}

                <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                    loading={authState.loading}
                    block
                >
                    {isFirstSetup ?
                        'Enregistrer votre mot de passe' :
                        'Réinitialiser votre mot de passe'
                    }
                </Button>
            </Form.Item>
        </>
    );

    useEffect(() => {
        if (token) {
            setIsTokenExpired(checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    useEffect(() => () => {
        resetResetPassword();
    }, [resetResetPassword]);

    return (
        <LoginLayout>
            <Seo title={'Réinitialisez votre mot de passe'} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
            >
                <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="logo" />

                {isTokenExpired === null ? // checking token expiration
                    (
                        <Spin />
                    ) : (
                        isTokenExpired ? (
                            <>
                                {authState.resendInvitationSuccess ? (
                                    <Typography.Paragraph>
                                        Vous allez recevoir une nouvelle invitation par email
                                    </Typography.Paragraph>
                                ) : (
                                    <>
                                        <div className="login-error-message" style={{ padding: '2rem 0' }}>
                                            <Alert
                                                type="error"
                                                message={error || 'L\'invitation a expiré.'}
                                            />
                                        </div>
                                        <Button
                                            onClick={onClickSendNewInvitation}
                                            type="primary"
                                            size="large"
                                        >
                                            Recevoir une nouvelle invitation par email
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Typography.Title level={1}>
                                    {isFirstSetup ?
                                        'Choisissez votre mot de passe' :
                                        'Réinitialisez votre mot de passe'
                                    }
                                </Typography.Title>
                                {formContent}
                            </>
                        )
                    )
                }
            </Form>
        </LoginLayout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        sendResetPassword: resetPassword.trigger,
        resetResetPassword: resetPassword.reset,
        resendInvitation: resendInvitationAction.trigger,
        resetResendInvitation: resendInvitationAction.reset,
    },
)(ResetPassword);
