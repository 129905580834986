import React, { FC } from 'react';

import { Redirect } from 'react-router-dom';
import { RoutePathName, getRoute } from '../../routes';

const Home: FC = () => {

    return <Redirect to={getRoute(RoutePathName.organizations)} />;

};

export default Home;
