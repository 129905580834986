import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Input, Spin, Row, Col, Select, Divider, Switch, message } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { User, Organization, RoleSlug } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    AdministratorsState,
    details as administratorDetails,
    update as administratorUpdate,
    create as administratorCreate,
} from '../../../store/actions/administrators';
import TextArea from 'antd/lib/input/TextArea';

interface AdministratorDrawerProps extends RouteProps {
    id?: User['id'];
    organizationId: Organization['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    administrators: AdministratorsState;
    getDetails: typeof administratorDetails.trigger;
    detailsReset: typeof administratorDetails.reset;
    update: typeof administratorUpdate.trigger;
    updateReset: typeof administratorUpdate.reset;
    create: typeof administratorCreate.trigger;
    createReset: typeof administratorCreate.reset;
}

const AdministratorDrawer: FC<AdministratorDrawerProps> = ({
    id,
    organizationId,
    isVisible,
    onClose,
    onSuccess,

    administrators,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const administrator: User | undefined = administrators.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
        } else if (isVisible && !id) {
            detailsReset();
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (administrators.details.data) {
            if (administrators.details.data.role) {
                administrators.details.data.isMain = administrators.details.data.role.slug === RoleSlug.owner;
            }

            form.setFieldsValue(administrators.details.data);
        }
    }, [administrators.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (administrators.update.success || administrators.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (administrators.update.error || administrators.create.error) {
            if (administrators.create.error?.data?.fields?.email || administrators.update.error?.data?.fields?.email) {
                message.error('L\'email indiqué est déjà utilisé');
            } else {
                message.error('Une erreur est survenue lors de la sauvegarde');
            }

            updateReset();
            createReset();
        }
    }, [administrators.update.success, administrators.create.success, administrators.update.error, administrators.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {

            values.organization = organizationId;
            values.role = (values.isMain) ? RoleSlug.owner : RoleSlug.admin;

            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="administrator-drawer"
            title={(!id) ? 'Créer un administrateur' : 'Editer l\'administrateur'}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={administrators.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >

                    <Form.Item
                        label="Titre"
                        name="civility"
                        rules={[requiredRule]}
                    >
                        <Select size="large" placeholder="Sélectionner un titre">
                            <Select.Option value="mrs">Mme</Select.Option>
                            <Select.Option value="mr">Mr</Select.Option>
                        </Select>
                    </Form.Item>

                    <Row gutter={24}>
                        <Col xs={12}>
                            <Form.Item
                                label="Nom"
                                name="lastName"
                                rules={[requiredRule]}
                            >
                                <Input size="large" placeholder="Saisir une nom" />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Prénom"
                                name="firstName"
                                rules={[requiredRule]}
                            >
                                <Input size="large" placeholder="Saisir un prénom" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Fonction"
                        name={['job', 'position']}
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir une fonction" />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col xs={12}>
                            <Form.Item
                                label="Téléphone"
                                name="phone"
                                rules={[requiredRule]}
                            >
                                <Input size="large" placeholder="Saisir un téléphone" />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    requiredRule,
                                    {type: 'email', message: 'Vérifiez le format de l\'email'},
                                ]}
                            >
                                <Input size="large" placeholder="Saisir un e-mail" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Commentaire"
                        name={['comment']}
                    >
                        <TextArea placeholder="Saisir un commentaire" />
                    </Form.Item>

                    <div className="switch-divider">
                        <Divider />
                        <Form.Item
                            label="Définir comme contact principal"
                            name="isMain"
                            className="switch-row"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <Divider />
                    </div>

                    <br />
                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={administrators.create.loading || administrators.update.loading}
                        >
                            {administrator ? 'Enregistrer les modifications' : 'Créer l\'administrateur'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    administrators: state.administrators,
});

export default connect(
    mapStateToProps,
    {
        getDetails: administratorDetails.trigger,
        detailsReset: administratorDetails.reset,
        create: administratorCreate.trigger,
        createReset: administratorCreate.reset,
        update: administratorUpdate.trigger,
        updateReset: administratorUpdate.reset,
    },
)(AdministratorDrawer);
