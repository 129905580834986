import React, { FC, forwardRef } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import { SurveyAnswerColors } from '../store/api/apiTypes';

import { translateSurveyAnswerColors } from '../helpers/i18n';

const SurveyColorSelect: FC<SelectProps<SurveyAnswerColors>> = forwardRef((props, ref: any) => (
    <Select
        ref={ref}
        filterOption={false}
        style={{ width: '100%' }}
        allowClear
        {...props}
    >
        {
            (Object.keys(SurveyAnswerColors) as Array<keyof typeof SurveyAnswerColors>).map((key) => (
                <Select.Option value={key} key={key}>
                    {translateSurveyAnswerColors(SurveyAnswerColors[key])}
                </Select.Option>
            ))
        }
    </Select>
));

export default SurveyColorSelect;
