import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { Button, Descriptions, PageHeader, Dropdown, Menu, Divider, Popconfirm, Spin, message } from 'antd';
import { Program, LibraryProgram } from '../../store/api/apiTypes';

import {
    details as programDetails,
    remove as programsRemove,
    update as programsUpdate,
} from '../../store/actions/programs';
import {
    details as libProgramDetails,
    remove as libProgramsRemove,
} from '../../store/actions/libraryPrograms';

import {
    ProgramTasksState,
    update as programTasksUpdate,
} from '../../store/actions/programTasks';

import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import Seo from '../../components/Seo';
import CardSkeleton from '../../components/CardSkeleton';
import TruncatedText from '../../components/TruncatedText';
import ProgramDrawer from '../organizations/programs/ProgramDrawer';
import ProgramWeeksList from './ProgramWeeksList';
import { translateLanguage, i18n, translateProgramType } from '../../helpers/i18n';
import { CRUDState } from '../../store/helpers/EzeeAction';
import ProgramDocumentsList from './ProgramDocumentsList';
import SaveAsModal from '../../components/SaveAsModal';
import { LibrarySaveAsPayload } from '../../store/api/libraryPrograms';
import ProgramNotificationsList from './ProgramNotificationsList';

interface ProgramPageProps {
    organizationId ?: string;
    programId?: string;
    programs: CRUDState<Program | LibraryProgram>;
    programTasks: ProgramTasksState;
    getDetails: typeof programDetails.trigger | typeof libProgramDetails.trigger;
    resetDetails: typeof programDetails.reset | typeof libProgramDetails.reset;
    remove: typeof programsRemove.trigger | typeof libProgramsRemove.trigger;

    updateTask: typeof programTasksUpdate.trigger;
    resetUpdateTask: typeof programTasksUpdate.reset;

    updateProgram: typeof programsUpdate.trigger;
    resetUpdateProgram: typeof programsUpdate.reset;
}

const ProgramPageDetails: FC<ProgramPageProps> = ({
    organizationId,
    programId,
    programs,
    programTasks,
    getDetails,
    resetDetails,
    remove,
    updateTask,
    resetUpdateTask,
    updateProgram,
    resetUpdateProgram,
}) => {

    const program: Program | undefined = programs.details.data;
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ moreMenuVisible, setMoreMenuVisible ] = useState(false);
    const [ isSaveAsModalVisible, setIsSaveAsModalVisible ] = useState(false);
    const [ currentTab, setCurrentTab ] = useState(1);

    useEffect(() => {
        setIsLoaded(false);
        resetDetails();
        getProgramDetails();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programs.details.success && programs.details.data) {
            setIsLoaded(true);
        }
    }, [programs.details.success, programs.details.data, setIsLoaded]);

    useEffect(() => {
        if (programTasks.update.success && programTasks.update.payload.saveAsTemplate) {
            resetUpdateTask();
            message.success('L\'action a bien été enregistrée comme modèle');
        }
    }, [programTasks.update.success, programTasks.update.payload, resetUpdateTask]);

    useEffect(() => {
        if (programs.update.success && programs.update.payload.saveAsTemplate) {
            resetUpdateProgram();
            message.success('Le programme a bien été enregistré comme modèle');
        }
    }, [programs.update.success, programs.update.payload, resetUpdateProgram]);

    const getProgramDetails = () => {
        if (programId) {
            getDetails({id: programId});
        } else {
            resetDetails();
        }
    };

    const edit = () => {
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
        getProgramDetails();
    };

    const back = () => {
        window.history.back();
    };

    // ---------------------------------------
    // Disable / Enable / Delete program

    const removeItem = () => {
        setMoreMenuVisible(false);
        remove({id: program?.id});
        window.history.back();
    };

    // ---------------------------------------
    // Save program as template

    const openSaveAsModal = () => {
        setIsSaveAsModalVisible(true);
        setMoreMenuVisible(false);
    };

    const saveAsTemplate = (saveAsValues: LibrarySaveAsPayload) => {
        setIsSaveAsModalVisible(false);
        updateProgram({
            ...saveAsValues,
            saveAsTemplate: true,
            id: program?.id,
        });
    };

    // ---------------------------------------
    // Weeks change

    const onWeeksChange = () => {
        getProgramDetails();
    };

    // ---------------------------------------
    // Tabs

    const switchTab = (tab: number) => {
        setCurrentTab(tab);
    };

    // ---------------------------------------
    // More actions

    const moreMenuVisibleChange = (isVisible: boolean) => {
        setMoreMenuVisible(isVisible);
    };

    const actionsMenu = (
        <Menu>
            <Menu.Item onClick={openSaveAsModal}>
                Enregistrer comme modèle
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Confirmez-vous la suppression ?"
                    onConfirm={removeItem}
                    okText="Confirmer"
                    cancelText="Annuler"
                    placement="topRight"
                >
                    <div>Supprimer</div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    // ---------------------------------------
    // Render

    return (
        <div id="program">
            {(program && isLoaded) ? (
                <>
                    <Seo title={i18n(program.name)} />
                    <div className="page-header-container">

                        <PageHeader
                            ghost={false}
                            onBack={back}
                            title={i18n(program.name)}
                            extra={[
                                (
                                    <Button
                                        key="org-edit"
                                        shape="round"
                                        type="primary"
                                        onClick={edit}
                                    >
                                        Editer
                                    </Button>
                                ),
                                (
                                    <Dropdown
                                        overlay={actionsMenu}
                                        placement="bottomRight"
                                        trigger={['click']}
                                        onVisibleChange={moreMenuVisibleChange}
                                        visible={moreMenuVisible}
                                        key="org-more"
                                    >
                                        <Button
                                            className="btn-more-actions"
                                            icon={<EllipsisOutlined />}
                                            type="link"
                                            size="large"
                                        />
                                    </Dropdown>
                                ),
                            ]}
                        >
                            <Descriptions className="description-no-margin" size="default" column={3}>
                                <Descriptions.Item label="Type">{translateProgramType(program.type)}</Descriptions.Item>
                                <Descriptions.Item label="Langue">{translateLanguage(program.language)}</Descriptions.Item>
                            </Descriptions>

                            <Divider />

                            <TruncatedText maxHeight={65} showExpand={true} withFade={true}>
                                <div dangerouslySetInnerHTML={{__html: i18n(program.description) || ''}}/>
                            </TruncatedText>

                            <Divider />

                            <div className="custom-tabs-container">
                                <Button type="link" onClick={switchTab.bind(null, 1)} className={(currentTab === 1) ? 'active' : ''}>Semaines</Button>
                                <Button type="link" onClick={switchTab.bind(null, 2)} className={(currentTab === 2) ? 'active' : ''}>Documents</Button>
                                <Button type="link" onClick={switchTab.bind(null, 3)} className={(currentTab === 3) ? 'active' : ''}>Notifications</Button>
                            </div>
                        </PageHeader>
                    </div>

                    {(currentTab === 1) && (
                        <div className="custom-tab-content">
                            <ProgramWeeksList
                                program={program}
                                onChange={onWeeksChange}
                            />
                        </div>
                    )}

                    {(currentTab === 2) && (
                        <div className="custom-tab-content">
                            <ProgramDocumentsList
                                program={program}
                            />
                        </div>
                    )}

                    {(currentTab === 3) && (
                        <div className="custom-tab-content">
                            <ProgramNotificationsList
                                program={program}
                            />
                        </div>
                    )}

                    <ProgramDrawer
                        id={program.id}
                        isVisible={isDrawerVisible}
                        onClose={onDrawserClose}
                        onSuccess={onDrawserClose}
                    />

                    <SaveAsModal
                        isVisible={isSaveAsModalVisible}
                        onClose={setIsSaveAsModalVisible.bind(null, false)}
                        onSuccess={saveAsTemplate}
                    />
                </>
            ) : (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}>
                    <CardSkeleton />
                </Spin>
            )}
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    programTasks: state.programTasks,
});

export default connect(
    mapStateToProps,
    {
        updateTask: programTasksUpdate.trigger,
        resetUpdateTask: programTasksUpdate.reset,
        updateProgram: programsUpdate.trigger,
        resetUpdateProgram: programsUpdate.reset,
    },
)(ProgramPageDetails);
