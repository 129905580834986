import React, { FC, useEffect } from 'react';
import { Drawer, Form, Spin, notification } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { Program, Organization } from '../../../store/api/apiTypes';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    ProgramsState,
    details as programDetails,
    update as programUpdate,
    create as programCreate,
} from '../../../store/actions/programs';
import ProgramForm from '../../../forms/ProgramForm';

interface ProgramDrawerProps {
    id?: Program['id'];
    organizationId?: Organization['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    programs: ProgramsState;
    getDetails: typeof programDetails.trigger;
    detailsReset: typeof programDetails.reset;
    update: typeof programUpdate.trigger;
    updateReset: typeof programUpdate.reset;
    create: typeof programCreate.trigger;
    createReset: typeof programCreate.reset;
}

const ProgramDrawer: FC<ProgramDrawerProps> = ({
    id,
    organizationId,
    isVisible,
    onClose,
    onSuccess,

    programs,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const program: Program | undefined = programs.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
        } else if (isVisible && !id) {
            detailsReset();
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programs.details.data) {
            form.setFieldsValue(programs.details.data);
        }
    }, [programs.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (programs.update.success || programs.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (programs.update.error || programs.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [programs.update.success, programs.create.success, programs.update.error, programs.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormSubmit = (values: any) => {
        if (organizationId) { values.organization = organizationId; }

        if (id) {
            values.id = id;
            update(values);
        } else {
            create(values);
        }
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
    };

    return (
        <Drawer
            className="program-drawer"
            title={(!id) ? 'Créer un programme' : 'Editer un programme'}
            width={680}
            onClose={onDrawerClose}
            visible={isVisible}
            destroyOnClose={true}
        >
            <Spin spinning={programs.details.loading}>
                <ProgramForm
                    organizationId={organizationId}
                    loading={programs.create.loading || programs.update.loading}
                    initialValues={program}
                    onSubmit={onFormSubmit}
                />
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    programs: state.programs,
});

export default connect(
    mapStateToProps,
    {
        getDetails: programDetails.trigger,
        detailsReset: programDetails.reset,
        create: programCreate.trigger,
        createReset: programCreate.reset,
        update: programUpdate.trigger,
        updateReset: programUpdate.reset,
    },
)(ProgramDrawer);
