import { Layout } from 'antd';
import React, { FC } from 'react';
import { Breakpoint } from 'react-socks';

import '../assets/styles/Login.less';

const LoginLayout: FC = ({ children }) => {
    return (
        <Layout id="login-layout">
            <div id="login-section">
                <div id="login-section-inner">
                    {children}
                </div>
            </div>
            <Breakpoint md up className="login-background" />
        </Layout>
    );
};

export default LoginLayout;
