import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Input, Spin, notification, InputNumber, DatePicker } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { Pack, Organization } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    PacksState,
    details as packDetails,
    update as packUpdate,
    create as packCreate,
} from '../../../store/actions/packs';
import IconCalendar from '../../../components/icons/IconCalendar';
import moment from 'moment';
import ProgramFilterSelect from '../programs/ProgramFilterSelect';

const { RangePicker } = DatePicker;

interface PackDrawerProps extends RouteProps {
    id?: Pack['id'];
    organizationId: Organization['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    packs: PacksState;
    getDetails: typeof packDetails.trigger;
    detailsReset: typeof packDetails.reset;
    update: typeof packUpdate.trigger;
    updateReset: typeof packUpdate.reset;
    create: typeof packCreate.trigger;
    createReset: typeof packCreate.reset;
}

const PackDrawer: FC<PackDrawerProps> = ({
    id,
    organizationId,
    isVisible,
    onClose,
    onSuccess,

    packs,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const pack: Pack | undefined = packs.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        detailsReset();

        if (isVisible && id) {
            getDetails({id});
        } else if (isVisible && !id) {
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (packs.details.data) {
            packs.details.data.dates = (packs.details.data.period) ? [moment(packs.details.data.period.fromDate), moment(packs.details.data.period.toDate)] : undefined;
            form.setFieldsValue(packs.details.data);
        }
    }, [packs.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (packs.update.success || packs.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (packs.update.error || packs.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [packs.update.success, packs.create.success, packs.update.error, packs.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {

            values.organization = organizationId;
            if (values.dates && values.dates.length === 2) {
                values.period = {
                    fromDate: moment(values.dates[0]).startOf('day'),
                    toDate: moment(values.dates[1]).endOf('day'),
                };
                delete values.dates;
            }

            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="pack-drawer"
            title={(!id) ? 'Créer un pack' : 'Editer un pack'}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={packs.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >

                    <Form.Item
                        label="Titre du pack"
                        name="name"
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir un titre" />
                    </Form.Item>

                    <Form.Item
                        label="Descriptif du pack"
                        name="description"
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir une description" />
                    </Form.Item>

                    <Form.Item
                        label="Période d'activation"
                        name="dates"
                        rules={[requiredRule]}
                    >
                        <RangePicker
                            suffixIcon={<IconCalendar />}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Nombre de sièges"
                        name="maxLicenses"
                        rules={[requiredRule]}
                    >
                        <InputNumber min={2} size="large" placeholder="Saisir le nombre de sièges" />
                    </Form.Item>

                    <Form.Item
                        label="Programmes rattachés"
                        name="programs"
                    >
                        <ProgramFilterSelect
                            size="large"
                            multi={true}
                            initialValue={pack?.programs}
                            filters={{
                                filters: [
                                    {
                                        name: 'organization',
                                        value: organizationId,
                                    },
                                ],
                            }}
                        />
                    </Form.Item>

                    <br />
                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={packs.create.loading || packs.update.loading}
                        >
                            {pack ? 'Enregistrer les modifications' : 'Créer le pack'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    packs: state.packs,
});

export default connect(
    mapStateToProps,
    {
        getDetails: packDetails.trigger,
        detailsReset: packDetails.reset,
        create: packCreate.trigger,
        createReset: packCreate.reset,
        update: packUpdate.trigger,
        updateReset: packUpdate.reset,
    },
)(PackDrawer);
