import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../store/reducers';
import { Button, Form, Select, Collapse, Switch, Divider, InputNumber, Input, Card } from 'antd';
import { PlusOutlined, DeleteFilled, CheckCircleFilled } from '@ant-design/icons';
import { FormInstance, Rule } from 'antd/lib/form';
import { ButtonProps } from 'antd/lib/button';
import ReactQuill from 'react-quill';

import {
    ProgramSubTask, SubTaskType, ProgramValidationType, LibraryDocument, SubTaskSubType,
    NetworkingActionType, ContactType, DocumentSubTaskType, SurveySubTaskType,
} from '../store/api/apiTypes';
import {
    translateSubTaskType, translateProgramValidationType, translateSubTaskSubType,
    translateNetworkingActionType, translateContactType, translateDocumentSubTaskType, translateSurveySubTaskType,
} from '../helpers/i18n';
import LibraryDocumentFilterSelect from '../pages/library/documents/LibraryDocumentFilterSelect';
import { constants } from '../helpers';
import ProgramSubTaskSurveys from './ProgramSubTaskSurveys';

const { Panel } = Collapse;

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface ProgramSubTaskItemProps {
    index: number;
    subtask: ProgramSubTask;
    handle: any;
    form: FormInstance;
    onRemove: (subtask: ProgramSubTask, index: number) => void;
}

const ProgramSubTaskItem: FC<ProgramSubTaskItemProps> = ({
    index,
    subtask,
    handle,
    form,
    onRemove,
}) => {

    const [currentType, setCurrentType] = useState<SubTaskType>();
    const [currentSubType, setCurrentSubType] = useState<SubTaskSubType>();
    const [hasPoints, setHasPoints] = useState<boolean>(false);
    const [hasValidation, setHasValidation] = useState<boolean>(false);
    const [validationType, setValidationType] = useState<ProgramValidationType>();
    const [hasValidationContactType, setHasValidationContactType] = useState<boolean>();
    const [hasValidationActionType, setHasValidationActionType] = useState<boolean>();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoaded) {
            setHasPoints(!!subtask.points);
            setHasValidation(!!subtask.validation);
            setValidationType(subtask.validation?.type);
            setCurrentSubType(subtask.subType);
            setCurrentType(subtask.type);
            setHasValidationActionType(!!(subtask?.validation?.fields && subtask?.validation?.fields?.actionType));
            setHasValidationContactType(!!(subtask?.validation?.fields && (subtask?.validation?.fields.contact_type || subtask?.validation?.fields?.type)));
            setIsLoaded(true);
        }
    }, [subtask]); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteSubTask: ButtonProps['onClick'] = (e) => {
        e.preventDefault();
        onRemove(subtask, index);
    };

    const onTypeChange = (type: SubTaskType) => {
        setCurrentType(type);
    };

    const onSubTypeChange = (type: SubTaskSubType) => {
        setCurrentSubType(type);
    };

    const onSurveySubTypeChange = (value: SurveySubTaskType) => {
        setFieldValue('surveys', undefined);

        if ([SurveySubTaskType.intelligence, SurveySubTaskType.colors, SurveySubTaskType.tricam].includes(value)) {
            setFieldValue('validation', {
                type: ProgramValidationType.automatic,
            });
            setValidationType(ProgramValidationType.automatic);
            setHasValidation(true);
        } else {
            removeValidation();
            setValidationType(undefined);
        }
    };

    const onValidationTypeChange = (type: ProgramValidationType) => {
        setValidationType(type);
    };

    const onCollapseChange = (key: any) => {
        setIsOpen((key && key.length > 0));
    };

    const setFieldValue = (fieldName: string, value: any) => {
        const subTasks = form.getFieldValue(['subTasks']);
        const currentTaskValues = form.getFieldValue(['subTasks', subtask.formIndex]);

        form.setFieldsValue({
            subTasks: {
                ...subTasks,
                [subtask.formIndex]: {
                    ...currentTaskValues,
                    [fieldName]: value,
                },
            },
        });
    };

    const removePoints = () => {
        setHasPoints(false);
        setFieldValue('points', undefined);
    };

    const removeValidation = () => {
        setHasValidation(false);
        setFieldValue('validation', undefined);
    };

    const getValidationTypes = () => {
        switch (currentType) {
            case SubTaskType.text:
            case SubTaskType.document:
                return [ProgramValidationType.manual];
            case SubTaskType.upload:
                return [ProgramValidationType.automatic];
            default:
                return [ProgramValidationType.manual, ProgramValidationType.automatic];
        }
    };

    const getSurveysRule = (surveySubType: SurveySubTaskType) => {
        switch (surveySubType) {
            case SurveySubTaskType.tricam:
                return { type: 'array', len: 4, message: 'Veuillez remplir les 4 questionnaires' } as unknown as Rule;

            default:
                return { type: 'array', len: 1, message: 'Veuillez remplir le questionnaire' } as unknown as Rule;
        }
    };

    // ---------------------------------------
    // Render

    return (
        <Collapse className="sub-task-item" expandIconPosition="right" activeKey={isOpen ? '1' : undefined} onChange={onCollapseChange}>

            <Panel
                header={(
                    <span className="title">
                        {handle}
                        <span>Comportement n°{index + 1}</span>
                    </span>
                )}
                extra={(
                    <Button
                        type="ghost"
                        shape="circle"
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={deleteSubTask}
                    />
                )}
                key="1"
            >
                <Form.Item name={['subTasks', subtask.formIndex, 'index']} className="hidden-input">
                    <Input />
                </Form.Item>

                <Form.Item name={['subTasks', subtask.formIndex, 'id']} className="hidden-input">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Type"
                    name={['subTasks', subtask.formIndex, 'type']}
                    rules={[requiredRule]}
                >
                    <Select size="large" placeholder="Sélectionner un type" onChange={onTypeChange}>
                        <Select.Option value="">Sélectionner un type</Select.Option>
                        {(Object.keys(SubTaskType) as Array<keyof typeof SubTaskType>).map((key) => (
                            <Select.Option key={'subtype-' + key} value={key}>{translateSubTaskType(SubTaskType[key])}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {(currentType === SubTaskType.text) && (
                    <Form.Item
                        label="Description"
                        name={['subTasks', subtask.formIndex, 'description', 'en']}
                        rules={[requiredRule]}
                        initialValue=""
                    >
                        <ReactQuill
                            placeholder=""
                            modules={constants.QUILL_TOOLBAR}
                            theme="snow"
                        />
                    </Form.Item>
                )}

                {(currentType === SubTaskType.document) && (
                    <>
                        <Form.Item
                            label="Action"
                            name={['subTasks', subtask.formIndex, 'documentSubType']}
                            rules={[requiredRule]}
                        >
                            <Select size="large" placeholder="Sélectionner un type">
                                <Select.Option value="">Sélectionner un type</Select.Option>
                                {(Object.keys(DocumentSubTaskType) as Array<keyof typeof DocumentSubTaskType>).map((key) => (
                                    <Select.Option key={'subtype-document-' + key} value={key}>{translateDocumentSubTaskType(DocumentSubTaskType[key])}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Document"
                            name={['subTasks', subtask.formIndex, 'libraryDocument']}
                            rules={[requiredRule]}
                        >
                            <LibraryDocumentFilterSelect
                                size="large"
                                initialValue={subtask.libraryDocument ? [(subtask.libraryDocument as LibraryDocument)] : []}
                            />
                        </Form.Item>
                    </>
                )}

                {(currentType === SubTaskType.link) && (
                    <Form.Item
                        label="Lien"
                        name={['subTasks', subtask.formIndex, 'link']}
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir une url" />
                    </Form.Item>
                )}

                {(currentType === SubTaskType.action) && (
                    <Form.Item
                        label="Type de création"
                        name={['subTasks', subtask.formIndex, 'subType']}
                    >
                        <Select size="large" placeholder="Sélectionner une action" onChange={onSubTypeChange}>
                            {(Object.keys(SubTaskSubType) as Array<keyof typeof SubTaskSubType>).map((key) => (
                                <Select.Option key={'subtypesub-' + key} value={key}>{translateSubTaskSubType(SubTaskSubType[key])}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                {(currentType === SubTaskType.survey) && (
                    <>
                        <Form.Item
                            label="Sous-type"
                            name={['subTasks', subtask.formIndex, 'surveySubType']}
                            rules={[requiredRule]}
                        >
                            <Select size="large" placeholder="Sélectionner un type" onChange={onSurveySubTypeChange}>
                                <Select.Option value="">Sélectionner un type</Select.Option>
                                {(Object.keys(SurveySubTaskType) as Array<keyof typeof SurveySubTaskType>).map((key) => (
                                    <Select.Option key={'subtype-survey-' + key} value={key}>
                                        {translateSurveySubTaskType(SurveySubTaskType[key])}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            // tslint:disable-next-line: jsx-no-lambda
                            shouldUpdate={(prevValues, curValues) =>
                                prevValues.subTasks[subtask.formIndex].surveySubType !== curValues.subTasks[subtask.formIndex].surveySubType
                            }
                            noStyle
                        >
                            {() => form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType']) === SurveySubTaskType.tricam ? (
                                <Form.Item
                                    label="TRICAM à résultats partiels"
                                    help="affichage résultats : 2 dominantes au lieu de 3, pas de liste de métiers"
                                    name={['subTasks', subtask.formIndex, 'tricamWithPartialResults']}
                                    className="switch-row"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            ) : null}
                        </Form.Item>

                        <Form.Item
                            // tslint:disable-next-line: jsx-no-lambda
                            shouldUpdate={(prevValues, curValues) =>
                                prevValues.subTasks[subtask.formIndex].surveySubType !== curValues.subTasks[subtask.formIndex].surveySubType
                            }
                            noStyle
                        >
                            {() => form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType']) ? (
                                <Form.Item
                                    name={['subTasks', subtask.formIndex, 'surveys']}
                                    rules={[requiredRule, getSurveysRule(form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType']))]}
                                >
                                    <ProgramSubTaskSurveys surveySubType={form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType'])} />
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </>
                )}

                {hasValidation && (
                    <>
                        <div className="form-item-with-delete">
                            <Form.Item
                                // tslint:disable-next-line: jsx-no-lambda
                                shouldUpdate={(prevValues, curValues) =>
                                    prevValues.subTasks?.[subtask.formIndex]?.surveySubType !== curValues.subTasks?.[subtask.formIndex]?.surveySubType
                                }
                                noStyle
                            >
                                {() => (
                                    <Form.Item
                                        label="Mode de validation"
                                        name={['subTasks', subtask.formIndex, 'validation', 'type']}
                                        rules={[requiredRule]}
                                    >
                                        <Select size="large" placeholder="Sélectionner une validation" onChange={onValidationTypeChange}>
                                            {getValidationTypes().map((key) => (
                                                <Select.Option
                                                    key={'validation-' + key}
                                                    value={key}
                                                    disabled={
                                                        // disable manual validation for survey-tests
                                                        [SurveySubTaskType.tricam, SurveySubTaskType.colors, SurveySubTaskType.intelligence]
                                                            .includes(form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType'])) &&
                                                            key === ProgramValidationType.manual
                                                    }
                                                >
                                                    {translateProgramValidationType(ProgramValidationType[key])}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                            {![SurveySubTaskType.tricam, SurveySubTaskType.colors, SurveySubTaskType.intelligence]
                                .includes(form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType'])) && (
                                    <Button
                                        icon={<DeleteFilled/>}
                                        type="text"
                                        size="large"
                                        onClick={removeValidation}
                                    />
                                )}
                        </div>

                        {(
                            validationType === ProgramValidationType.automatic && currentType
                            && [SubTaskType.survey, SubTaskType.action].includes(currentType) &&
                            ![SurveySubTaskType.tricam, SurveySubTaskType.colors, SurveySubTaskType.intelligence]
                                .includes(form.getFieldValue(['subTasks', subtask.formIndex, 'surveySubType']))
                        ) && (
                            <Card title="Paramètre de validation" className="validation-card">
                                {(currentType === SubTaskType.survey) && (
                                    <Form.Item
                                        label="Nombre de réponse(s) minimum"
                                        name={['subTasks', subtask.formIndex, 'validation', 'requiredCount']}
                                        rules={[requiredRule]}
                                    >
                                        <InputNumber size="large" min={0} placeholder="Nombre" />
                                    </Form.Item>
                                )}

                                {(currentType === SubTaskType.action) && (
                                    <>
                                        {(currentSubType === SubTaskSubType.networking) && (
                                            <>
                                                <div className="check-row">
                                                    <CheckCircleFilled /> Action de networking créé (données obligatoires)
                                                </div>

                                                {hasValidationActionType && (
                                                    <div className="form-item-with-delete">
                                                        <Form.Item
                                                            label="Type d'action"
                                                            name={['subTasks', subtask.formIndex, 'validation', 'fields', 'actionType', 'value']}
                                                            rules={[requiredRule]}
                                                        >
                                                            <Select size="large" placeholder="Sélectionner une type">
                                                                {(Object.keys(NetworkingActionType) as Array<keyof typeof NetworkingActionType>).map((key) => (
                                                                    <Select.Option key={'actionType-' + key} value={key}>{translateNetworkingActionType(NetworkingActionType[key])}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <Button icon={<DeleteFilled/>} type="text" size="large" onClick={setHasValidationActionType.bind(null, false)} />
                                                    </div>
                                                )}

                                                {hasValidationContactType && (
                                                    <div className="form-item-with-delete">
                                                        <Form.Item
                                                            label="Type de contact"
                                                            name={['subTasks', subtask.formIndex, 'validation', 'fields', 'contact_type', 'value']}
                                                            rules={[requiredRule]}
                                                        >
                                                            <Select size="large" placeholder="Sélectionner une type">
                                                                {(Object.keys(ContactType) as Array<keyof typeof ContactType>).map((key) => (
                                                                    <Select.Option key={'contactType-' + key} value={key}>{translateContactType(ContactType[key])}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <Button icon={<DeleteFilled/>} type="text" size="large" onClick={setHasValidationContactType.bind(null, false)} />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {(currentSubType === SubTaskSubType.contact) && (
                                            <>
                                                <div className="check-row">
                                                    <CheckCircleFilled /> Contact créé (données obligatoires)
                                                </div>

                                                {hasValidationContactType && (
                                                    <Form.Item
                                                        label="Type de contact"
                                                        name={['subTasks', subtask.formIndex, 'validation', 'fields', 'type', 'value']}
                                                        rules={[requiredRule]}
                                                    >
                                                        <Select size="large" placeholder="Sélectionner une type">
                                                            {((Object.keys(ContactType) as Array<keyof typeof ContactType>) as Array<keyof typeof ContactType>).map((key) => (
                                                                <Select.Option key={'contactType-' + key} value={key}>{translateContactType(ContactType[key])}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </>
                                        )}

                                        {(
                                            currentSubType
                                            && [SubTaskSubType.networking].includes(currentSubType as SubTaskSubType)
                                            && !hasValidationActionType
                                        ) && (
                                            <Button
                                                type="text"
                                                className="add-new inline"
                                                onClick={setHasValidationActionType.bind(null, true)}
                                            >
                                                <PlusOutlined />
                                                Spéficier type d'action
                                            </Button>
                                        )}

                                        {(
                                            currentSubType
                                            && [SubTaskSubType.networking, SubTaskSubType.contact].includes(currentSubType as SubTaskSubType)
                                            && !hasValidationContactType
                                        ) && (
                                            <Button
                                                type="text"
                                                className="add-new inline"
                                                onClick={setHasValidationContactType.bind(null, true)}
                                            >
                                                <PlusOutlined />
                                                Spéficier type de contact
                                            </Button>
                                        )}

                                        {(currentSubType === SubTaskSubType.hiring) && (
                                            <>
                                                <div className="check-row">
                                                    <CheckCircleFilled /> Annonce créé (données obligatoires)
                                                </div>

                                                <Form.Item
                                                    label="Date de réponse renseignée"
                                                    name={['subTasks', subtask.formIndex, 'validation', 'fields', 'responseDate', 'required']}
                                                    className="switch-row"
                                                    valuePropName="checked"
                                                >
                                                    <Switch />
                                                </Form.Item>
                                            </>
                                        )}
                                    </>
                                )}
                            </Card>
                        )}
                    </>
                )}

                {hasPoints && (
                    <div className="form-item-with-delete">
                        <Form.Item
                            label="Nombre de points"
                            name={['subTasks', subtask.formIndex, 'points']}
                            rules={[requiredRule]}
                            initialValue=""
                        >
                            <InputNumber size="large" min={0} placeholder="Indiquez un nombre de points" />
                        </Form.Item>
                        <Button icon={<DeleteFilled/>} type="text" size="large" onClick={removePoints} />
                    </div>
                )}

                <div>
                    {!hasValidation && (
                        <Button
                            type="text"
                            className="add-new inline"
                            onClick={setHasValidation.bind(null, true)}
                        >
                            <PlusOutlined />
                            Ajouter une validation
                        </Button>
                    )}
                    {(!hasPoints && hasValidation) && (
                        <Button
                            type="text"
                            className="add-new inline"
                            onClick={setHasPoints.bind(null, true)}
                        >
                            <PlusOutlined />
                            Ajouter un nombre de points
                        </Button>
                    )}
                </div>

                {hasValidation && (
                    <>
                        <Divider />
                        <Form.Item
                            label="Requis pour la réalisation de l'action"
                            name={['subTasks', subtask.formIndex, 'isRequired']}
                            className="switch-row"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </>
                )}

            </Panel>
        </Collapse>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
    },
)(ProgramSubTaskItem);
