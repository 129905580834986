import React, { FC, useState, forwardRef, useEffect, useRef } from 'react';
import { Select, Spin } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import {
    LibraryDocumentsState,
    search as searchLibraryDocuments,
} from '../../../store/actions/libraryDocuments';
import { SelectProps } from 'antd/lib/select';
import { LibraryDocument } from '../../../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { LibraryDocumentListPayload } from '../../../store/api/libraryDocuments';
import { i18n } from '../../../helpers/i18n';

interface LibraryDocumentFilterSelectProps {
    onChange?: (value: any) => void;
    multi?: boolean;
    libraryDocuments: LibraryDocumentsState;
    search: typeof searchLibraryDocuments.trigger;
    size?: SizeType;
    value ?: string[];
    setValue ?: any;
    initialValue ?: LibraryDocument[];
    filters ?: LibraryDocumentListPayload;
}

const LibraryDocumentFilterSelect: FC<LibraryDocumentFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, libraryDocuments, search, multi, size, filters} = props;
    const [ value, setValue ] = useState<any>();
    const [ initialValue, setInitialValue ] = useState<LibraryDocument[]>([]);
    const selectRef = useRef<any>();

    useEffect(() => {
        if (props.initialValue && props.initialValue.length > 0) {
            if (multi) {
                setValue(props.initialValue.map((a) => a.id));
                setInitialValue(props.initialValue);
                if (onChange) { onChange(props.initialValue.map((a) => a.id || a)); }
            } else {
                setValue(props.initialValue[0].id);
                setInitialValue(props.initialValue);
                if (onChange) { onChange(props.initialValue[0].id); }
            }
        } else {
            setValue([]);
            setInitialValue([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFocus: SelectProps<LibraryDocument['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 25,
            ...filters,
        });
    };

    const onSearch: SelectProps<LibraryDocument['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 25,
            ...filters,
            throttling: 250,
        });
    };

    const onChangeSelect: SelectProps<LibraryDocument['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (selectRef?.current) {
            selectRef.current.blur();
        }
        if (onChange) {onChange(newValue); }
    };

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            autoClearSearchValue={true}
            size={size}
            ref={selectRef}
            showSearch
            value={value}
            placeholder={(multi) ? 'Recherchez des documents' : 'Sélectionner un document'}
            notFoundContent={libraryDocuments.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
        >
            {
                [
                    ...initialValue, // Display initial value
                    ...libraryDocuments.search.data.items.filter((a) => !initialValue.map((b) => b.id).includes(a.id)), // Search items, excluding initial value
                ].map((libraryDocument) => (
                <Select.Option value={libraryDocument.id} key={libraryDocument.id}>{i18n(libraryDocument.name)}</Select.Option>
            ))}
        </Select>
    );

});

LibraryDocumentFilterSelect.defaultProps = {
    multi: false,
    size: 'small',
};

const mapStateToProps = (state: MainReducerState) => ({
    libraryDocuments: state.libraryDocuments,
});

export default connect(
    mapStateToProps,
    {
        search: searchLibraryDocuments.trigger,
    },
    null,
    { forwardRef: true },
)(LibraryDocumentFilterSelect);
