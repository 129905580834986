import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import {
    Button,
    Card,
    Menu,
    Popconfirm,
    Dropdown,
    Tag,
} from 'antd';

import { User, RoleSlug, Organization } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import {
    remove as administratorsRemove,
    sendWelcome as administratorsSendWelcome,
} from '../../../store/actions/administrators';

import { EditFilled, EllipsisOutlined, PhoneFilled, MailFilled } from '@ant-design/icons';
import TruncatedText from '../../../components/TruncatedText';

interface AdministratorItemProps {
    administrator: User;
    organization: Organization;
    onEdit: (administrator: User) => void;
    remove: typeof administratorsRemove.trigger;
    resetRemove: typeof administratorsRemove.reset;
    sendWelcome: typeof administratorsSendWelcome.trigger;
}

const AdministratorItem: FC<AdministratorItemProps> = ({
    administrator,
    organization,
    onEdit,
    remove,
    sendWelcome,
}) => {

    const [ moreMenuVisible, setMoreMenuVisible ] = useState(false);

    // ---------------------------------------
    // Edit / Delete / Renew administrator

    const edit = () => {
        onEdit(administrator);
    };

    const logAs = () => {
        setMoreMenuVisible(false);
        window.location.href = process.env.REACT_APP_API_BASE_URL + '/auth/logAs/' + administrator.id;
    };

    const sendLoginEmail = () => {
        setMoreMenuVisible(false);
        sendWelcome({id: administrator.id});
    };

    const removeItem = () => {
        remove({id: administrator.id});
    };

    // ---------------------------------------
    // Render

    const moreMenuVisibleChange = (isVisible: boolean) => {
        setMoreMenuVisible(isVisible);
    };

    const actionsMenu = () => (
        <Menu>
            {organization.enabled && (
                <Menu.Item onClick={logAs}>
                    Se connecter en tant que
                </Menu.Item>
            )}
            {organization.enabled && (
                <Menu.Item onClick={sendLoginEmail}>
                    Envoyer les accès
                </Menu.Item>
            )}
            <Menu.Item>
                <Popconfirm
                    title="Confirmez-vous la suppression ?"
                    onConfirm={removeItem}
                    okText="Confirmer"
                    cancelText="Annuler"
                    placement="topRight"
                >
                    <div>Supprimer</div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <Card
            key={'administrator' + administrator.id}
            actions={[
                <Button icon={<EditFilled />} type="text" size="small" key="edit" onClick={edit} />,
                (
                    <Dropdown
                        overlay={actionsMenu}
                        placement="bottomRight"
                        trigger={['click']}
                        onVisibleChange={moreMenuVisibleChange}
                        visible={moreMenuVisible}
                        key="org-more"
                    >
                        <Button icon={<EllipsisOutlined />} type="text" size="small" key="ellipsis" />
                    </Dropdown>
                ),
            ]}
        >
            <h2>
                {administrator.firstName + ' ' + administrator.lastName}
                {(administrator.role.slug === RoleSlug.owner) && (
                    <Tag>Principal</Tag>
                )}
            </h2>
            <TruncatedText maxHeight={40} showExpand={false}>{administrator.job?.position}</TruncatedText>
            <div className="icon-row"><PhoneFilled /> {administrator.phone}</div>
            <div className="icon-row"><MailFilled /> <span className="label">{administrator.email}</span></div>
        </Card>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
});

export default connect(
    mapStateToProps,
    {
        remove: administratorsRemove.trigger,
        resetRemove: administratorsRemove.reset,
        sendWelcome: administratorsSendWelcome.trigger,
    },
)(AdministratorItem);
