import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import {
    ProgramsState,
    search as searchPrograms,
} from '../../../store/actions/programs';
import { SelectProps } from 'antd/lib/select';
import { Program } from '../../../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ProgramListPayload } from '../../../store/api/programs';
import { i18n } from '../../../helpers/i18n';

interface ProgramFilterSelectProps {
    onChange?: (value: any) => void;
    multi?: boolean;
    programs: ProgramsState;
    search: typeof searchPrograms.trigger;
    size?: SizeType;
    value ?: Program[];
    setValue ?: any;
    initialValue ?: Program[];
    filters ?: ProgramListPayload;
}

const ProgramFilterSelect: FC<ProgramFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, programs, search, multi, size, filters} = props;
    const [ value, setValue ] = useState<any>();
    const [ initialValue, setInitialValue ] = useState<Program[]>([]);

    useEffect(() => {
        setValue([]);
        setInitialValue([]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value && props.value.length > 0 && typeof props.value[0] !== 'string') {
            setValue(props.value.map((a) => a.id));
            setInitialValue(props.value);
            if (onChange) { onChange(props.value.map((a) => a.id || a)); }
        }
    }, [props.value, onChange]);

    const onFocus: SelectProps<Program['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 15,
            ...filters,
        });
    };

    const onSearch: SelectProps<Program['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 15,
            ...filters,
            throttling: 250,
        });
    };

    const onChangeSelect: SelectProps<Program['id']>['onChange'] = (newValue) => {
        setValue(newValue);
        if (onChange) {onChange(newValue); }
    };

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            size={size}
            ref={ref}
            showSearch
            value={value}
            placeholder="Recherchez un programme"
            notFoundContent={programs.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
        >
            {
                [
                    ...initialValue, // Display initial value
                    ...programs.search.data.items.filter((a) => !initialValue.map((b) => b.id).includes(a.id)), // Search items, excluding initial value
                ].map((program) => (
                <Select.Option value={program.id} key={program.id}>{i18n(program.name)}</Select.Option>
            ))}
        </Select>
    );

});

ProgramFilterSelect.defaultProps = {
    multi: false,
    size: 'small',
};

const mapStateToProps = (state: MainReducerState) => ({
    programs: state.programs,
});

export default connect(
    mapStateToProps,
    {
        search: searchPrograms.trigger,
    },
    null,
    { forwardRef: true },
)(ProgramFilterSelect);
