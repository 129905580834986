import React, { FC } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { RouteChildrenProps } from 'react-router-dom';

import {
    ProgramsState,
    details as programDetails,
    remove as programsRemove,
} from '../../store/actions/programs';
import ProgramPage from '../programs/ProgramPage';

interface MatchParams {
    organizationId ?: string;
    programId: string;
}

interface ProgramProps extends RouteChildrenProps<MatchParams> {
    programs: ProgramsState;
    getDetails: typeof programDetails.trigger;
    resetDetails: typeof programDetails.reset;
    remove: typeof programsRemove.trigger;
}

const ProgramDetails: FC<ProgramProps> = ({
    match,
    programs,
    getDetails,
    resetDetails,
    remove,
}) => {
    return (
        <ProgramPage
            organizationId={match?.params.organizationId}
            programId={match?.params.programId}
            programs={programs}
            getDetails={getDetails}
            resetDetails={resetDetails}
            remove={remove}
        />
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    programs: state.programs,
});

export default connect(
    mapStateToProps,
    {
        getDetails: programDetails.trigger,
        resetDetails: programDetails.reset,
        remove: programsRemove.trigger,
    },
)(ProgramDetails);
