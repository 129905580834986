import React, { FC, useEffect, useState, Fragment } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

import { ProgramTaskSurveyQuestion, ProgramTaskSurvey, SurveyAnswerType, SurveyAnswerIntelligence } from '../store/api/apiTypes';

import SurveyIntelligenceSelect from '../components/SurveyIntelligenceSelect';

interface IntelligenceSurveyDrawerProps extends RouteProps {
    isVisible: boolean;
    initialValue?: ProgramTaskSurvey;
    onClose: () => void;
    onSuccess?: (values: ProgramTaskSurvey) => void;
}

const IntelligenceSurveyDrawer: FC<IntelligenceSurveyDrawerProps> = ({
    isVisible,
    initialValue,
    onClose,
    onSuccess,
}) => {

    const [, setValue] = useState<Array<Partial<ProgramTaskSurveyQuestion>>>();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) {
            if (initialValue) {
                if (initialValue?.questions) {
                    const questions = initialValue?.questions?.map((question) => ({
                        ...question,
                        type: SurveyAnswerType.list,
                    }));
                    form.setFieldsValue({
                        questions,
                    });
                    setValue(questions);
                } else {
                    form.setFieldsValue({
                        questions: [{
                            type: SurveyAnswerType.list,
                        }],
                    });
                    setValue([{
                        type: SurveyAnswerType.list,
                    }]);
                }
            }
        } else {
            form.resetFields();
            form.setFieldsValue({
                questions: [{
                    type: SurveyAnswerType.list,
                    answers: [
                        {},
                    ],
                }],
            });
        }
    }, [isVisible, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        onSuccess?.(values as ProgramTaskSurvey);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSelectChange = (fieldName: Array<string | number>, value: SurveyAnswerIntelligence) => {
        form.setFields([{
            name: fieldName,
            value,
        }]);
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="survey-drawer"
            title="Test 8 formes d'intelligence - Questionnaire"
            width={600}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Form
                form={form}
                onFinish={onFormFinish}
                layout="vertical"
                hideRequiredMark
            >
                <Form.List name="questions">
                    {(fields, { add, remove }) => {
                        return (
                        <>
                            {fields.map((field, index) => (
                                <Fragment key={field.key}>
                                    {index > 0 && <br />}
                                    <div className="questions-list">
                                        <div className="form-title-with-delete">
                                            <h3>Question n°{index + 1}</h3>
                                            {index > 0 && (
                                                <Button
                                                    className="remove-item"
                                                    type="ghost"
                                                    shape="circle"
                                                    icon={<DeleteFilled />}
                                                    // tslint:disable-next-line: jsx-no-lambda
                                                    onClick={() => { remove(index); }}
                                                />
                                            )}
                                        </div>
                                        <Form.Item
                                            label="Question"
                                            name={[field.name, 'label', 'en']}
                                            rules={[requiredRule]}
                                        >
                                            <Input placeholder="Saisir une question" size="large" />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'type']} className="hidden-input">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={[field.name, 'answers', 0, 'label', 'en']} className="hidden-input">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Forme d'intelligence"
                                            name={[field.name, 'answers', 0, 'values', 0]}
                                            rules={[requiredRule]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <SurveyIntelligenceSelect
                                                placeholder="Choisir la forme d'intelligence"
                                                onChange={onSelectChange.bind(null, ['questions', field.name, 'answers', 0, 'label', 'en'])}
                                                size="large"
                                            />
                                        </Form.Item>
                                    </div>
                                </Fragment>
                            ))}

                            <br/>

                            <Button
                                className="add-new"
                                // tslint:disable-next-line: jsx-no-lambda
                                onClick={() => {
                                    add({
                                        type: SurveyAnswerType.list,
                                        answers: [
                                            {},
                                        ],
                                    });
                                }}
                            >
                                <PlusOutlined /> Ajouter une question
                            </Button>
                        </>
                    );
                }}
                </Form.List>

                <br />
                <Form.Item className="cta-submit">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                    >
                        Enregistrer le questionnaire
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    );

};

export default IntelligenceSurveyDrawer;
