import React, { FC, useEffect, useState, Fragment } from 'react';
import { RouteProps } from 'react-router-dom';
import { Button, Drawer, Form, Input, Divider, Row, Col } from 'antd';
import { FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

import { SurveyAnswerType, ProgramTaskSurvey } from '../store/api/apiTypes';

import SurveyTricamSelect from '../components/SurveyTricamSelect';

const getAddQuestionFromSurveyIndex = (surveyIndex: number) => {
    if (surveyIndex === 3) {
        return {
            label: { en: '' },
            values: ['', '', ''],
        };
    } else {
        return getDefaultQuestionsFromSurveyIndex(surveyIndex)?.[0];
    }
};
const getDefaultQuestionsFromSurveyIndex = (surveyIndex: number) => {
    switch (surveyIndex) {
        case 0:
            return [{
                type: SurveyAnswerType.list,
                answers: [
                    {},
                    {},
                ],
            }];

        case 1:
        case 2:
            return [{
                type: SurveyAnswerType.boolean,
                answers: [
                    {
                        label: {
                            en: 'true',
                        },
                    },
                ],
            }, {
                type: SurveyAnswerType.boolean,
                answers: [
                    {
                        label: {
                            en: 'true',
                        },
                    },
                ],
            }];

        case 3:
            return [{
                type: SurveyAnswerType.multiValue,
                answers: [
                    {},
                    {},
                ],
            }];

        default: return undefined;
    }
};

interface TricamSurveyDrawerProps extends RouteProps {
    isVisible: boolean;
    initialValue?: ProgramTaskSurvey;
    onClose: () => void;
    onSuccess?: (values: ProgramTaskSurvey) => void;
    surveyIndex: number;
}

const TricamSurveyDrawer: FC<TricamSurveyDrawerProps> = ({
    isVisible,
    initialValue,
    onClose,
    onSuccess,
    surveyIndex,
}) => {

    const [ , setValue] = useState<ProgramTaskSurvey>();
    const [form] = Form.useForm();

    const render1stSurveyAnswers = (fieldName: number) => {
        return (
            [0, 1].map((i) => ((
                <div className="answers-list" style={i === 1 ? { marginTop: '1.5rem' } : undefined} key={i}>
                    <Row gutter={24}>
                        <Col xs={16}>
                            <Form.Item
                                label={`Proposition n°${i + 1}`}
                                name={[fieldName, 'answers', i, 'label', 'en']}
                                rules={[requiredRule]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="Saisir la réponse" size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={8}>
                            <Form.Item
                                label="Lettre associée"
                                name={[fieldName, 'answers', i, 'values', 0]}
                                rules={[requiredRule]}
                                style={{ marginBottom: 0 }}
                            >
                                <SurveyTricamSelect size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            )))
        );
    };

    const render2ndAnd3rdSurveyAnswers = (fieldName: number) => {
        return (
            <>
                <Form.Item
                    label="Lettre associée à la réponse OUI"
                    name={[fieldName, 'answers', 0, 'values', 0]}
                    rules={[requiredRule]}
                    style={{ marginBottom: 0 }}
                >
                    <SurveyTricamSelect size="large" />
                </Form.Item>
                <Form.Item name={[fieldName, 'answers', 0, 'label', 'en']} className="hidden-input" initialValue="true">
                    <Input />
                </Form.Item>
            </>
        );
    };

    const render4thSurveyAnswers = (fieldParent: Array<string | number>, fieldName: number) => {
        return (
            <Row gutter={24}>
                {[0, 1, 2].map((i) => ((
                    <Col xs={8} key={i}>
                        <Form.Item
                            shouldUpdate
                        >
                            {() => (
                                <Form.Item
                                    label={`Lettre n°${i + 1}`}
                                    name={[fieldName, 'values', i]}
                                    rules={[requiredRule]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <SurveyTricamSelect
                                        size="large"
                                        disabledValues={
                                            [0, 1, 2]
                                                .filter((ind) => ind !== i) // get other indexes
                                                .map((ind) => form.getFieldValue([...fieldParent, fieldName, 'values', ind])) // get form value of other indexes
                                                .filter(Boolean) // remove undefined values
                                        }
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                )))}
            </Row>
        );
    };

    useEffect(() => {
        if (isVisible) {
            if (initialValue) {
                form.setFieldsValue(initialValue);
                setValue(initialValue);
            } else {
                const defaultValues = {
                    title: undefined,
                    description: undefined,
                    questions: getDefaultQuestionsFromSurveyIndex(surveyIndex) || [],
                };
                form.setFieldsValue(defaultValues);
                setValue(defaultValues as ProgramTaskSurvey);
            }
        } else {
            form.resetFields();
            form.setFieldsValue({
                title: undefined,
                description: undefined,
                questions: getDefaultQuestionsFromSurveyIndex(surveyIndex),
            });
        }
    }, [isVisible, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        onSuccess?.(values as ProgramTaskSurvey);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="survey-drawer"
            title={`Test TRICAM - Questionnaire N°${surveyIndex + 1}`}
            width={600}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Form
                form={form}
                onFinish={onFormFinish}
                layout="vertical"
                hideRequiredMark
            >
                <Form.Item
                    label="Titre du questionnaire"
                    name={['title', 'en']}
                >
                    <Input size="large" placeholder="Saisir un titre" />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name={['description', 'en']}
                >
                    <Input size="large" placeholder="Saisir une description" />
                </Form.Item>

                <Divider />

                {surveyIndex === 3 && (
                    <Form.Item name={['questions', 0, 'type']} className="hidden-input">
                        <Input />
                    </Form.Item>
                )}

                <Form.List name={surveyIndex === 3 ? ['questions', 0, 'answers'] : 'questions'}>
                    {(fields, { add, remove }) => {
                        return (
                        <>
                            {fields.map((field, index) => (
                                <Fragment key={field.key}>
                                    {index > 0 && <br />}
                                    <div className="questions-list">
                                        <div className="form-title-with-delete">
                                            <h3>{surveyIndex === 3 ? 'Métier' : 'Question'} n°{index + 1}</h3>
                                            {index > 0 && (
                                                <Button
                                                    className="remove-item"
                                                    type="ghost"
                                                    shape="circle"
                                                    icon={<DeleteFilled />}
                                                    // tslint:disable-next-line: jsx-no-lambda
                                                    onClick={() => { remove(index); }}
                                                />
                                            )}
                                        </div>
                                        <Form.Item
                                            label={surveyIndex === 3 ? 'Métier' : 'Question'}
                                            name={surveyIndex === 3 ? [field.name, 'label', 'en'] : [field.name, 'label', 'en']}
                                            rules={[requiredRule]}
                                        >
                                            <Input placeholder={surveyIndex === 3 ? 'Saisir un métier' : 'Saisir une question'} size="large" />
                                        </Form.Item>

                                        {surveyIndex !== 3 && (
                                            <Form.Item name={[field.name, 'type']} className="hidden-input">
                                                <Input />
                                            </Form.Item>
                                        )}

                                        {surveyIndex === 0 && render1stSurveyAnswers(field.name)}
                                        {[1, 2].includes(surveyIndex) && render2ndAnd3rdSurveyAnswers(field.name)}
                                        {surveyIndex === 3 && render4thSurveyAnswers(['questions', 0, 'answers'], field.name)}
                                    </div>
                                </Fragment>
                            ))}

                            <br/>

                            <Button
                                className="add-new"
                                // tslint:disable-next-line: jsx-no-lambda
                                onClick={() => { add(getAddQuestionFromSurveyIndex(surveyIndex)); }}
                            >
                                <PlusOutlined /> Ajouter {surveyIndex === 3 ? 'un métier' : 'une question'}
                            </Button>
                        </>
                    );
                }}
                </Form.List>

                <br />
                <Form.Item className="cta-submit">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                    >
                        Enregistrer le questionnaire
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    );

};

export default TricamSurveyDrawer;
