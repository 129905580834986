import { LibraryProgram } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';

export interface LibrarySaveAsPayload {
    name: string;
    code: string;
}

export interface LibraryProgramDetailsPayload {
    id: string;
}

export interface LibraryProgramUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type LibraryProgramListPayload = SearchPaginationQuery;

export const details = (payload: LibraryProgramDetailsPayload) => api.get(`/programs/${payload.id}`);

export const list = (payload: LibraryProgramListPayload = {}) => {
    return api.get(`/library/programs`, {params: cleanPayload(payload)});
};

export const create = (payload: LibraryProgram) => {
    payload.saveAsTemplate = true;
    return api.post(`/programs`, payload);
};

export const update = (payload: LibraryProgram) => {
    const id: string = payload.id;
    delete payload.id;

    return api.put(`/programs/${id}`, payload);
};

export const remove = (payload: LibraryProgram) => {
    return api.delete(`/programs/${payload.id}`);
};
