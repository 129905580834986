import { useEffect, useRef, Ref } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentBreakpointName } from 'react-socks';

export const usePrevious = <T extends {}>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useShareForwardedRef = <T extends {}>(forwardedRef: Ref<T>) => {
    // final ref that will share value with forward ref. this is the one we will attach to components
    const innerRef = useRef<T>(null);

    useEffect(() => {
        // after every render - try to share current ref value with forwarded ref
        if (!forwardedRef) {
            return;
        }
        if (typeof forwardedRef === 'function') {
            forwardedRef(innerRef.current);
            return;
        } else {
            // @ts-ignore
            // by default forwardedRef.current is readonly. Let's ignore it
            forwardedRef.current = innerRef.current;
        }
    });

    return innerRef;
};

export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

// Tablet is considered mobile here, customize as needed
export const useIsMobile = () => {
    const breakpoint = useCurrentBreakpointName();

    return breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' || breakpoint === 'lg';
};

/**
 * Reorder items in the same list
 */
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
