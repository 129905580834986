import { RcFile } from 'antd/lib/upload';

import { MessageTrigger, MessageTemplate, Program, NotificationCampaign } from './apiTypes';
import api, { cleanPayload } from './_client';
import { SearchPaginationQuery } from '.';

export interface NotificationDetailsPayload {
    id: string;
}

export interface NotificationUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export interface NotificationCreatePayload {
    program?: Program['id'];
}

export type NotificationListPayload = SearchPaginationQuery & {
    program?: Program['id'];
};

export const details = (payload: NotificationDetailsPayload) => api.get(`/notifications/${payload.id}`);

export const list = (payload: NotificationListPayload = {}) => {
    const listRouteUri = (!payload?.program || payload?.program === 'none') ? '/notifications' : `/programs/${payload.program}/notifications`;
    return api.get(listRouteUri, {params: cleanPayload(payload)});
};

export const create = (payload: NotificationCreatePayload) => {
    return api.post(`/programs/${payload.program}/notifications`, payload);
};

export const update = (payload: MessageTrigger) => {
    delete payload.id;

    if (payload.template) {
        const template: MessageTemplate = {...payload.template};
        delete payload.template;
        api.put(`/notifications/templates/${template.slug}`, template);
    }

    return api.put(`/notifications/${payload.slug}`, payload);
};

export const remove = (payload: MessageTrigger) => {
    return api.delete(`/notifications/${payload.slug}`);
};

export const createCampaign = (payload: NotificationCampaign) => {
    return api.post('/notifications/campaigns', payload);
};
