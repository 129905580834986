import { QuestionSection } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface QuestionSectionDetailsPayload {
    id: string;
}

export type QuestionSectionListPayload = SearchPaginationQuery;

export const details = (payload: QuestionSectionDetailsPayload) => api.get(`/questionSections/${payload.id}`);

export const list = (payload: QuestionSectionListPayload = {}) => {
    return api.get(`/questionSections`, {params: cleanPayload(payload)});
};

export const create = (payload: QuestionSection) => {
    return api.post(`/questionSections`, payload);
};

export const update = (payload: QuestionSection) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/questionSections/${id}`, payload);
};

export const remove = (payload: QuestionSection) => {
    return api.delete(`/questionSections/${payload.id}`);
};
