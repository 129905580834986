import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import CookieBanner from './components/CookieBanner';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import Privacy from './pages/privacy';
import { getRawRoute, RoutePathName } from './routes';
import UsersList from './pages/users';
import OrganizationsList from './pages/organizations';
import Organization from './pages/organizations/Organization';
import WebsitePagesList from './pages/website/pages/WebsitePagesList';
import WebsiteFAQ from './pages/website/faq/WebsiteFAQ';
import Program from './pages/programs/Program';
import LibraryDocumentsList from './pages/library/documents/LibraryDocumentsList';
import LibraryProgramsList from './pages/library/programs/LibraryProgramsList';
import LibraryProgramTasksList from './pages/library/tasks/LibraryProgramTasksList';

import './assets/styles/fonts.less';
import './assets/styles/App.less';
import ForgottenPassword from './pages/forgottenPassword';
import ResetPassword from './pages/resetPassword';
import LibraryNotificationsList from './pages/library/notifications/LibraryNotificationsList';

const App: FC = () => (
    <Switch>
        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
        <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />

        <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
            <CookieBanner />
            <MainLayout>
                <ErrorBoundary>
                    <Switch>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.home)}
                        >
                            <Home />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.users)}
                        >
                            <UsersList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.organizations)}
                        >
                            <OrganizationsList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.organization)}
                            component={Organization}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.organization_program)}
                            component={Program}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.library_documents)}
                            component={LibraryDocumentsList}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.library_programs)}
                            component={LibraryProgramsList}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.library_program)}
                            component={Program}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.library_actions)}
                            component={LibraryProgramTasksList}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.library_notifications)}
                            component={LibraryNotificationsList}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.website_pages)}
                            component={WebsitePagesList}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.website_faq)}
                            component={WebsiteFAQ}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.privacy)}
                        >
                            <Privacy />
                        </Route>

                        <Route path="*">
                            <ErrorPage />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </MainLayout>
        </ProtectedRoute>
    </Switch>
);

export default App;
