import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Spin, notification, Tabs, Drawer } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { Question, SupportedLanguage, LanguagesList, QuestionSection } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    QuestionsState,
    details as questionDetails,
    update as questionUpdate,
    create as questionCreate,
} from '../../../store/actions/questions';
import Flag from '../../../components/Flag';
import TextArea from 'antd/lib/input/TextArea';

const { TabPane } = Tabs;
const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface QuestionDrawerProps extends RouteProps {
    id?: Question['id'];
    sectionId?: QuestionSection['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    questions: QuestionsState;
    getDetails: typeof questionDetails.trigger;
    detailsReset: typeof questionDetails.reset;
    update: typeof questionUpdate.trigger;
    updateReset: typeof questionUpdate.reset;
    create: typeof questionCreate.trigger;
    createReset: typeof questionCreate.reset;
}

const QuestionDrawer: FC<QuestionDrawerProps> = ({
    id,
    sectionId,
    isVisible,
    onClose,
    onSuccess,

    questions,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
}) => {

    const question: Question | undefined = questions.details.data;
    const [form] = Form.useForm();
    const [ tabLang, setTabLang ] = useState<SupportedLanguage>(SupportedLanguage.fr);

    useEffect(() => {
        setTabLang(SupportedLanguage.fr);
        detailsReset();
        form.resetFields();

        if (isVisible && id) {
            getDetails({id});
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (questions.details.data) {
            form.setFieldsValue(questions.details.data);
        }
    }, [questions.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (questions.update.success || questions.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (questions.update.error || questions.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [questions.update.success, questions.create.success, questions.update.error, questions.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {

            if (sectionId) { values.section = sectionId; }

            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const tabChange = (val: string) => {
        setTabLang((val as SupportedLanguage));
    };

    return (
        <Drawer
            className="question-modal"
            title={(!id) ? 'Créer une question' : 'Editer la question'}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
            destroyOnClose={true}
        >
            <Spin spinning={questions.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                        { LanguagesList.map((language: SupportedLanguage) => (
                            <TabPane tab={<Flag language={language} />} key={language}>
                                <Form.Item
                                    label="Question"
                                    name={['title', language]}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <Input size="large" placeholder="Saisir une question" />
                                </Form.Item>

                                <Form.Item
                                    label="Réponse"
                                    name={['answer', language]}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <TextArea placeholder="Saisir une réponse" />
                                </Form.Item>
                            </TabPane>
                        ))}
                    </Tabs>

                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        block
                        htmlType="submit"
                        loading={questions.create.loading || questions.update.loading}
                    >
                        {question ? 'Enregistrer les modifications' : 'Créer la question'}
                    </Button>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    questions: state.questions,
});

export default connect(
    mapStateToProps,
    {
        getDetails: questionDetails.trigger,
        detailsReset: questionDetails.reset,
        create: questionCreate.trigger,
        createReset: questionCreate.reset,
        update: questionUpdate.trigger,
        updateReset: questionUpdate.reset,
    },
)(QuestionDrawer);
