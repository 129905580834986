import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface UserGroupDetailsPayload {
    id: string;
}

export type UserGroupListPayload = SearchPaginationQuery;

export const details = (payload: UserGroupDetailsPayload) => api.get(`/users/groups/${payload.id}`);

export const list = (payload: UserGroupListPayload = {}) => api.get(`/users/groups`, {params: cleanPayload(payload)});
