import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Spin, notification, Tabs, message } from 'antd';
import { MainReducerState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { WebsitePage, SupportedLanguage, LanguagesList } from '../../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    WebsitePagesState,
    details as websitePageDetails,
    update as websitePageUpdate,
} from '../../../store/actions/websitePages';

import ReactQuill from 'react-quill';
import Flag from '../../../components/Flag';
import { constants } from '../../../helpers';

const { TabPane } = Tabs;

const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

interface WebsitePageDrawerProps extends RouteProps {
    id?: WebsitePage['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    websitePages: WebsitePagesState;
    getDetails: typeof websitePageDetails.trigger;
    detailsReset: typeof websitePageDetails.reset;
    update: typeof websitePageUpdate.trigger;
    updateReset: typeof websitePageUpdate.reset;
}

const WebsitePageDrawer: FC<WebsitePageDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    websitePages,
    getDetails,
    detailsReset,
    update,
    updateReset,
}) => {

    const websitePage: WebsitePage | undefined = websitePages.details.data;
    const [form] = Form.useForm();
    const [ tabLang, setTabLang ] = useState<SupportedLanguage>(SupportedLanguage.fr);

    useEffect(() => {
        setTabLang(SupportedLanguage.fr);
        detailsReset();
        form.resetFields();

        if (isVisible && id) {
            getDetails({slug: id});
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (websitePages.details.data) {
            form.setFieldsValue(websitePages.details.data);
        }
    }, [websitePages.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (websitePages.update.success || websitePages.create.success) {

            form.resetFields();
            updateReset();

            if (onSuccess) {
                onSuccess();
            }

            message.success('La page a été ' + ((websitePages.create.success) ? 'créée' : 'modifiée') + ' avec succès');
        }

        if (websitePages.update.error || websitePages.create.error) {
            updateReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [websitePages.update.success, websitePages.create.success, websitePages.update.error, websitePages.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const tabChange = (val: string) => {
        setTabLang((val as SupportedLanguage));
    };

    return (
        <Drawer
            className="websitePage-drawer"
            title={(!id) ? 'Créer une page' : 'Modifier la page'}
            width={680}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={websitePages.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                        { LanguagesList.map((language: SupportedLanguage) => (
                            <TabPane tab={<Flag language={language} />} key={'content-' + language}>
                                <Form.Item
                                    label="Titre"
                                    name={['title', language]}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <Input size="large" placeholder="Saisir un titre" />
                                </Form.Item>

                                <Form.Item
                                    label="Contenu de la page"
                                    name={['content', language]}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                    initialValue=""
                                >
                                    <ReactQuill
                                        placeholder=""
                                        modules={constants.QUILL_TOOLBAR}
                                        theme="snow"
                                    />
                                </Form.Item>
                            </TabPane>
                        ))}
                    </Tabs>

                    <br />
                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={websitePages.create.loading || websitePages.update.loading}
                        >
                            {websitePage ? 'Enregistrer les modifications' : 'Créer la page'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    websitePages: state.websitePages,
    administrators: state.administrators,
});

export default connect(
    mapStateToProps,
    {
        getDetails: websitePageDetails.trigger,
        detailsReset: websitePageDetails.reset,
        update: websitePageUpdate.trigger,
        updateReset: websitePageUpdate.reset,
    },
)(WebsitePageDrawer);
