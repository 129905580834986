import React, { FC } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../../store/reducers';
import { Button, Popconfirm } from 'antd';
import { Question } from '../../../store/api/apiTypes';
import {
    remove as questionRemove, QuestionsState,
} from '../../../store/actions/questions';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { i18n } from '../../../helpers/i18n';

interface QuestionItemProps {
    question: Question;
    handle: any;

    onEdit: (question: Question) => void;

    questions: QuestionsState;
    remove: typeof questionRemove.trigger;
}

const QuestionItem: FC<QuestionItemProps> = ({
    question,
    handle,

    onEdit,

    questions,
    remove,
}) => {

    // ---------------------------------------
    // Edit / delete task

    const edit = () => {
        onEdit(question);
    };

    const removeQuestion = () => {
        remove(question);
    };

    // ---------------------------------------
    // Render

    return (
        <div className="program-week-task-item">
            <div className="cell-handle">{handle}</div>
            <div className="cell-name">{i18n(question.title)}</div>
            <div className="cell-actions">
                <Button
                    shape="circle"
                    type="ghost"
                    icon={<EditFilled />}
                    onClick={edit}
                    size="small"
                />

                <Popconfirm
                    title="Confirmez-vous la suppression ?"
                    onConfirm={removeQuestion}
                    okText="Confirmer"
                    cancelText="Annuler"
                    placement="topRight"
                >
                    <Button
                        shape="circle"
                        type="ghost"
                        icon={<DeleteFilled />}
                        size="small"
                    />
                </Popconfirm>
            </div>
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    questions: state.questions,
});

export default connect(
    mapStateToProps,
    {
        remove: questionRemove.trigger,
    },
)(QuestionItem);
