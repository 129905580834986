import React, { FC, useState, forwardRef, useEffect, useCallback } from 'react';
import { Button, Typography, Input, Form } from 'antd';
import { PlusOutlined, EditFilled } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';

import { ProgramTaskSurvey, SurveySubTaskType, ProgramTaskSurveyQuestion, Translation } from '../store/api/apiTypes';

import { classNames } from '../helpers';
import BasicSurveyDrawer from './BasicSurveyDrawer';
import TricamSurveyDrawer from './TricamSurveyDrawer';
import ColorsSurveyDrawer from './ColorsSurveyDrawer';
import IntelligenceSurveyDrawer from './IntelligenceSurveyDrawer';

const getButtonText = (surveyIndex: number, surveySubType?: SurveySubTaskType) => {
    switch (surveySubType) {
        case SurveySubTaskType.tricam:
            return `Créer le questionnaire n°${surveyIndex + 1}`;

        case SurveySubTaskType.colors:
            return 'Ajouter des mots';

        default: return 'Créer le questionnaire';
    }
};

const getEditButtonText = (surveyIndex: number, questions: ProgramTaskSurveyQuestion[], surveySubType?: SurveySubTaskType) => {
    switch (surveySubType) {
        case SurveySubTaskType.tricam:
            return (
                <>
                    {`Questionnaire n°${surveyIndex + 1}`}
                    <Typography.Text style={{ marginLeft: '1rem' }} className="text-grey-parme">
                        {surveyIndex === 3 ?
                            `${questions?.[0]?.answers?.length} métier${questions?.[0]?.answers?.length > 1 ? 's' : ''}` :
                            `${questions.length} question${questions.length > 1 ? 's' : ''}`
                        }
                    </Typography.Text>
                </>
            );

        case SurveySubTaskType.colors:
            return `${questions?.[0]?.answers?.length} mot${questions?.[0]?.answers?.length > 1 ? 's' : ''} ajouté${questions?.[0]?.answers?.length > 1 ? 's' : ''}`;

        case SurveySubTaskType.intelligence:
            return `${questions.length} question${questions.length > 1 ? 's' : ''} ajouté${questions.length > 1 ? 's' : ''}`;

        default: return `${questions.length} question${questions.length > 1 ? 's' : ''}`;
    }
};

interface ProgramSubTaskSurveysProps {
    onChange?: (value: ProgramTaskSurvey[]) => void;
    value?: ProgramTaskSurvey[];
    initialValue ?: ProgramTaskSurvey[];
    setValue ?: any;
    surveySubType?: SurveySubTaskType;
}

const ProgramSubTaskSurveys: FC<ProgramSubTaskSurveysProps> = forwardRef((props, ref: any) => {

    const { onChange, surveySubType } = props;
    const [ value, setValue ] = useState<ProgramTaskSurvey[]>();
    const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);
    const [ , setInitialValue ] = useState<ProgramTaskSurvey[]>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState<boolean>(false);
    const [firstSurveyDescription, setFirstSurveyDescription] = useState((props.initialValue?.[0]?.description as Translation)?.en);

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
            setInitialValue(props.initialValue);
            onChange?.(props.initialValue);
            setFirstSurveyDescription((props.initialValue?.[0]?.description as Translation)?.en);
        } else {
            setValue(undefined);
            setInitialValue(undefined);
            setFirstSurveyDescription(undefined);
        }
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value) {
            onChange?.(props.value);
        }

        setValue(props.value);
        setInitialValue(props.value);
        setFirstSurveyDescription((props.value?.[0]?.description as Translation)?.en);
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClickEdit = useCallback((surveyIndex: number) => {
        setCurrentSurveyIndex(surveyIndex);
        setIsDrawerVisible(true);
    }, [setCurrentSurveyIndex, setIsDrawerVisible]);

    const onSurveyChange = (survey: ProgramTaskSurvey) => {
        const newValue = [...(value || [])];

        newValue[currentSurveyIndex] = survey;

        setIsDrawerVisible(false);
        setValue(newValue);
        onChange?.(newValue);
    };

    const onChangeFirstSurveyDescription: InputProps['onChange'] = (e) => {
        const newValue = [...(value || [])];

        newValue[0] = {
            ...newValue[0],
            description: { en: e.target.value},
        };

        setFirstSurveyDescription(e.target.value);

        setValue(newValue);
        onChange?.(newValue);
    };

    const renderButton = useCallback((surveyIndex: number) =>
        value?.[surveyIndex]?.questions?.length ? (
            <Button
                type="text"
                className={classNames('add-new', 'edit')}
                onClick={onClickEdit.bind(null, surveyIndex)}
                key={surveyIndex}
                size={surveySubType !== SurveySubTaskType.basic ? 'small' : 'large'}
            >
                <div>{getEditButtonText(surveyIndex, value?.[surveyIndex]?.questions, surveySubType)}</div>
                <div className="edit">
                    <EditFilled />
                    Éditer
                </div>
            </Button>
        ) : (
            <Button
                type="text"
                className={classNames('add-new')}
                onClick={onClickEdit.bind(null, surveyIndex)}
                key={surveyIndex}
                size={surveySubType !== SurveySubTaskType.basic ? 'small' : 'large'}
            >
                <PlusOutlined />
                {getButtonText(surveyIndex, surveySubType)}
            </Button>
        ),
    [value, onClickEdit, surveySubType]);

    return (
        <div className={classNames(!!surveySubType && 'survey-field')}>
            {(surveySubType === SurveySubTaskType.basic || surveySubType === SurveySubTaskType.validation) && (
                <>
                    {renderButton(0)}

                    <BasicSurveyDrawer
                        isVisible={isDrawerVisible}
                        onClose={setIsDrawerVisible.bind(null, false)}
                        onSuccess={onSurveyChange}
                        initialValue={value?.[0]}
                    />
                </>
            )}
            {surveySubType === SurveySubTaskType.tricam && (
                <>
                    {[0, 1, 2, 3].map(renderButton)}

                    <TricamSurveyDrawer
                        isVisible={isDrawerVisible}
                        onClose={setIsDrawerVisible.bind(null, false)}
                        onSuccess={onSurveyChange}
                        initialValue={value?.[currentSurveyIndex]}
                        surveyIndex={currentSurveyIndex}
                    />
                </>
            )}
            {surveySubType === SurveySubTaskType.colors && (
                <>
                    <Form.Item
                        label="Description"
                    >
                        <Input size="large" placeholder="Saisir une description" value={firstSurveyDescription} onChange={onChangeFirstSurveyDescription} />
                    </Form.Item>

                    {renderButton(0)}

                    <ColorsSurveyDrawer
                        isVisible={isDrawerVisible}
                        onClose={setIsDrawerVisible.bind(null, false)}
                        onSuccess={onSurveyChange}
                        initialValue={value?.[currentSurveyIndex]}
                    />
                </>
            )}
            {surveySubType === SurveySubTaskType.intelligence && (
                <>
                    <Form.Item
                        label="Description"
                    >
                        <Input size="large" placeholder="Saisir une description" value={firstSurveyDescription} onChange={onChangeFirstSurveyDescription} />
                    </Form.Item>

                    {renderButton(0)}

                    <IntelligenceSurveyDrawer
                        isVisible={isDrawerVisible}
                        onClose={setIsDrawerVisible.bind(null, false)}
                        onSuccess={onSurveyChange}
                        initialValue={value?.[currentSurveyIndex]}
                    />
                </>
            )}
        </div>
    );

});

ProgramSubTaskSurveys.displayName = 'ProgramSubTaskSurveys';

export default ProgramSubTaskSurveys;
