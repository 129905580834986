import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Spin, notification, Row, Col, Select, Divider, message } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { Organization, User, RoleSlug, BusinessType, CompanySize } from '../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import {
    OrganizationsState,
    details as organizationDetails,
    update as organizationUpdate,
    create as organizationCreate,
} from '../../store/actions/organizations';

import {
    create as administratorCreate, AdministratorsState,
} from '../../store/actions/administrators';

import Title from 'antd/lib/typography/Title';
import { translateBusinessType, translateCompanySize } from '../../helpers/i18n';

interface OrganizationDrawerProps extends RouteProps {
    id?: Organization['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    organizations: OrganizationsState;
    administrators: AdministratorsState;
    getDetails: typeof organizationDetails.trigger;
    detailsReset: typeof organizationDetails.reset;
    update: typeof organizationUpdate.trigger;
    updateReset: typeof organizationUpdate.reset;
    create: typeof organizationCreate.trigger;
    createReset: typeof organizationCreate.reset;
    createAdmin: typeof administratorCreate.trigger;
}

const OrganizationDrawer: FC<OrganizationDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    organizations,
    administrators,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
    createAdmin,
}) => {

    const organization: Organization | undefined = organizations.details.data;
    const [administrator, setAdministrator] = useState<User | undefined>();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
            setAdministrator(undefined);
        } else if (isVisible && !id) {
            detailsReset();
            form.resetFields();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organizations.details.data) {
            form.setFieldsValue(organizations.details.data);
        }
    }, [organizations.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((organizations.update.success || organizations.create.success) && isVisible) {

            if (organizations.create.success && administrator && organizations.create.data?.id) {
                administrator.organization = organizations.create.data.id;
                administrator.roleSlug = RoleSlug.owner;
                createAdmin(administrator);
            }

            form.resetFields();
            updateReset();
            createReset();

            if (onSuccess) {
                onSuccess();
            }

            message.success('Le client a été ' + ((organizations.create.success) ? 'créé' : 'modifié') + ' avec succès');
        }

        if (organizations.update.error || organizations.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: 'Une erreur est survenue lors de la sauvegarde',
                placement: 'bottomRight',
            });
        }
    }, [organizations.update.success, organizations.create.success, organizations.update.error, organizations.create.error, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {

            if (values.admin) {
                const userRole = administrators.roles.data.items.find((role) => role.slug === RoleSlug.owner);
                if (userRole) {
                    values.role = userRole.id;
                    setAdministrator(values.admin);
                }
            }

            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const requiredRule = { required: true, message: 'Ce champ est obligatoire' };

    return (
        <Drawer
            className="organization-drawer"
            title={(!id) ? 'Créer un client' : 'Editer un client'}
            width={680}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={organizations.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >

                    <Form.Item
                        label="Nom de la société"
                        name="name"
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir le nom de la société" />
                    </Form.Item>

                    <Form.Item
                        label="Nom du groupe"
                        name="group"
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir le nom du groupe" />
                    </Form.Item>

                    <Form.Item
                        label="Raison sociale"
                        name="companyName"
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir la raison sociale" />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col xs={12}>
                            <Form.Item
                                label="SIRET"
                                name="serialNumber"
                                rules={[requiredRule]}
                            >
                                <Input size="large" placeholder="Saisir le SIRET" />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="N°TVA intracommunautaire"
                                name="VATSerialNumber"
                                rules={[requiredRule]}
                            >
                                <Input size="large" placeholder="Saisir le numéro de TVA" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={12}>
                            <Form.Item
                                label="Taille de l'entreprise"
                                name="companySize"
                                rules={[requiredRule]}
                            >
                                <Select size="large" placeholder="Sélectionner une taille">
                                    {Object.keys(CompanySize).map((key: any) => (
                                        <Select.Option key={'size-' + key} value={key}>{translateCompanySize((CompanySize as any)[key])}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Activité principale"
                                name="businessType"
                                rules={[requiredRule]}
                            >
                                <Select size="large" placeholder="Sélectionner une activité" dropdownClassName="option-multiline">
                                    {Object.keys(BusinessType).map((key: any) => (
                                        <Select.Option key={'business-' + key} value={key}>{translateBusinessType((BusinessType as any)[key])}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Adresse du site"
                        name={['address', 'street']}
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir l'adresse du site" />
                    </Form.Item>

                    <Form.Item
                        label="Adresse de facturation"
                        name={['billingAddress', 'street']}
                        rules={[requiredRule]}
                    >
                        <Input size="large" placeholder="Saisir l'adresse de facturation" />
                    </Form.Item>

                    {!id && (
                        <>
                            <Divider />
                            <Title level={4}>Administrateur</Title>
                            <br/>
                            <Form.Item
                                label="Titre"
                                name={['admin', 'civility']}
                                rules={[requiredRule]}
                            >
                                <Select size="large" placeholder="Sélectionner un titre">
                                    <Select.Option value="mrs">Mme</Select.Option>
                                    <Select.Option value="mr">Mr</Select.Option>
                                </Select>
                            </Form.Item>

                            <Row gutter={24}>
                                <Col xs={12}>
                                    <Form.Item
                                        label="Nom"
                                        name={['admin', 'lastName']}
                                        rules={[requiredRule]}
                                    >
                                        <Input size="large" placeholder="Saisir le nom" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item
                                        label="Prénom"
                                        name={['admin', 'firstName']}
                                        rules={[requiredRule]}
                                    >
                                        <Input size="large" placeholder="Saisir le prénom" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                label="Fonction"
                                name={['admin', 'job', 'position']}
                                rules={[requiredRule]}
                            >
                                <Input size="large" placeholder="Saisir une fonction" />
                            </Form.Item>

                            <Row gutter={24}>
                                <Col xs={12}>
                                    <Form.Item
                                        label="Téléphone"
                                        name={['admin', 'phone']}
                                        rules={[requiredRule]}
                                    >
                                        <Input size="large" placeholder="Saisir le téléphone" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item
                                        label="E-mail"
                                        name={['admin', 'email']}
                                        rules={[
                                            requiredRule,
                                            {type: 'email', message: 'Vérifiez le format de l\'email'},
                                        ]}
                                    >
                                        <Input size="large" placeholder="Saisir l'e-mail" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    <br />
                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={organizations.create.loading || organizations.update.loading}
                        >
                            {organization ? 'Enregistrer les modifications' : 'Créer le client'}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    organizations: state.organizations,
    administrators: state.administrators,
});

export default connect(
    mapStateToProps,
    {
        getDetails: organizationDetails.trigger,
        detailsReset: organizationDetails.reset,
        create: organizationCreate.trigger,
        createReset: organizationCreate.reset,
        update: organizationUpdate.trigger,
        updateReset: organizationUpdate.reset,
        createAdmin: administratorCreate.trigger,
    },
)(OrganizationDrawer);
